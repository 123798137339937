import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSetAtom } from 'jotai';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import { RemarketingVehicleSort } from '../../../api/RemarketingService/common.js';
import { RemarketingVehicleStatus } from '../../../api/util/RemarketingVehicle.js';
import SummaryCard from '../../common-ui/components/SummaryCard.js';
import { ErrorState, PageContainer } from '../../common-ui/index.js';
import { useDealer, useDebounce } from '../../hooks/index.js';
import { sx } from '../../util/index.js';
import DealerSelector from '../components/DealerSelector.js';
import Section from '../components/Section.js';
import RemarketingVehicleTable, {
  DefaultSort,
  Sort,
} from '../components/VehicleTable/RemarketingVehicleTable.js';
import { useCountRemarketingVehiclesForUser } from '../hooks/queries/useCountRemarketingVehiclesForUser.js';
import { useListRemarketingVehiclesForUser } from '../hooks/queries/useListRemarketingVehiclesForUser.js';
import { remarketingFiltersAtom } from '../state/atoms.js';

const styles = sx({
  scrollObserver: {
    position: 'absolute',
    bottom: 0,
    paddingTop: '100vh', // distance from the bottom of the list to start fetching more data
    zIndex: -1,
  },
});

const RemarketingPage: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isDisabled } = useDealer();
  const [tileSelected, setTileSelected] = useState<RemarketingVehicleStatus>(
    RemarketingVehicleStatus.ACTIVE,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<Sort>(DefaultSort);
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const setQueryFilters = useSetAtom(remarketingFiltersAtom);

  const { data: counts, isLoading: loadingCounts } =
    useCountRemarketingVehiclesForUser();

  const {
    data,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useListRemarketingVehiclesForUser({
    order: sort.order,
    searchTerm: debouncedSearchTerm,
    sort: sort.sort,
    status: tileSelected,
  });

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const handleInputChange = (value: string) => {
    setSearchTerm(value);
    setSearchParams((state) => {
      const newState = new URLSearchParams(state);
      newState.set('q', value);
      return newState;
    });
  };

  const handleSortOptionChange = useCallback((options: Sort) => {
    setSort(options);
    setSearchParams((state) => {
      const newState = new URLSearchParams(state);
      newState.set('o', options.order);
      newState.set('s', options.sort);

      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTileChange = useCallback(
    (tile: RemarketingVehicleStatus) => {
      setTileSelected(tile as RemarketingVehicleStatus);
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        newState.set('t', tile);

        return newState;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage, inView]);

  useEffect(() => {
    const q = searchParams.get('q');
    const o = searchParams.get('o');
    const s = searchParams.get('s');
    const t = searchParams.get('t');

    const tile = Object.values(RemarketingVehicleStatus).includes(
      t as RemarketingVehicleStatus,
    )
      ? (t as RemarketingVehicleStatus)
      : RemarketingVehicleStatus.ACTIVE;

    setTileSelected(tile);

    setSort({
      order:
        o && ['asc', 'desc'].includes(o)
          ? (searchParams.get('o') as 'asc' | 'desc')
          : DefaultSort.order,
      sort:
        s &&
        Object.values(RemarketingVehicleSort).includes(
          s as RemarketingVehicleSort,
        )
          ? (s as RemarketingVehicleSort)
          : DefaultSort.sort,
    });

    setSearchTerm(q ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setQueryFilters((state) => ({ ...state, searchParams: searchParams }));
  }, [searchParams, setQueryFilters]);

  const vehicles = data?.pages.flatMap((p) => p.items);
  const totalResults = data?.pages[0]?.count;

  return (
    <PageContainer>
      <Section
        title={
          <Stack spacing={1}>
            <DealerSelector />
            <Typography variant="h4">
              {t('pages.remarketingPage.title')}
            </Typography>
          </Stack>
        }
      >
        {isDisabled ? (
          <Stack>
            <ErrorState
              alertTitle={t('dealerDisabledTitle')}
              error={t('dealerDisabledMessage')}
            />
          </Stack>
        ) : (
          <>
            <div>
              <Grid container spacing={{ xs: 0, sm: 3 }}>
                {Object.keys(RemarketingVehicleStatus)
                  .filter((x) =>
                    [
                      RemarketingVehicleStatus.ACTIVE,
                      RemarketingVehicleStatus.CLOSED,
                      RemarketingVehicleStatus.VISIBLE,
                    ].includes(x as RemarketingVehicleStatus),
                  )
                  .map((tile) => {
                    const selected = tileSelected === tile;
                    const count =
                      counts?.[tile as RemarketingVehicleStatus] ?? 0;
                    return (
                      // show three on normal screens then stack on mobile
                      <Grid item key={tile} marginBottom={2} sm={4} xs={12}>
                        <SummaryCard
                          label={t(`remarketingVehicleStatus.user.${tile}`)}
                          linkText={t('view')}
                          loading={loadingCounts}
                          onClick={() => {
                            handleTileChange(tile as RemarketingVehicleStatus);
                          }}
                          selected={selected}
                          value={count}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
            <Stack spacing={2}>
              <Box my={2} position="relative">
                <RemarketingVehicleTable
                  count={totalResults ?? 0}
                  error={isError}
                  inputSearchValue={searchTerm}
                  loading={isLoading}
                  onSearchTermChange={handleInputChange}
                  onSortChange={handleSortOptionChange}
                  sortOptions={sort}
                  vehicles={vehicles || []}
                />
                <Box ref={ref} sx={styles.scrollObserver} />
              </Box>
            </Stack>
          </>
        )}
      </Section>
    </PageContainer>
  );
};

export default RemarketingPage;
