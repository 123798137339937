import { useTranslation } from 'react-i18next';
import { HttpServiceTransportError } from '../../../api/util/HttpServiceTransportError.js';
import { LookupErrorCode } from '../../../core/LookupService/common.js';
import { ErrorState } from '../../common-ui/index.js';

export const VehicleErrorDisplay = ({ error }: { error: unknown }) => {
  const { t } = useTranslation();

  if (HttpServiceTransportError.is(error, LookupErrorCode.MissingVinData)) {
    return (
      <ErrorState
        alertTitle={t(`LookupErrorCodeTitle.${error.code}`)}
        error={t(`LookupErrorCode.${error.code}`)}
      />
    );
  }

  return <ErrorState />;
};
