import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { VehicleFeatures } from '../../../../../api/util/Vehicle.js';
import {
  AstonMartinFlare,
  AstonMartinSans,
} from '../../../../common-ui/fonts/fonts.js';
import { calculateBalancedDistribution } from '../../../../util/calculateBalancedDistribution.js';
import { dashIfUndefined } from '../../../../util/dashIfUndefined.js';
import {
  formatAcceleration,
  formatConsumptionText,
  formatMaxSpeed,
  formatPrice,
  formatTorque,
} from '../../../../util/formatters.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';
import OptionsHeader from './OptionsHeader.js';

const MAX_FEATURE_ITEMS = 50;

export interface OptionsLandscapePdfProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
}
Font.register(AstonMartinSans);
Font.register(AstonMartinFlare);

const styles = StyleSheet.create({
  page: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 15,
    fontFamily: 'AstonMartinSans',
  },
  body: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  divider: {
    width: '100%',
    marginVertical: 8,
    borderBottom: '2px solid #000',
  },
  detailContainer: {
    flexDirection: 'row',
  },
  title: {
    color: '#58595b',
    paddingBottom: 4,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    fontSize: 9,
  },
  detailItem: {
    flexDirection: 'row',
    paddingVertical: 2,
    flexWrap: 'wrap',
    fontSize: 8,
  },
  label: {
    width: '30%',
  },
  value: {
    flex: 1,
    textAlign: 'right',
    paddingHorizontal: 4,
  },
  footer: {
    width: '50%',
    fontSize: 6,
  },
  tableHeader: {
    backgroundColor: '#000',
    color: '#FFF',
    flexDirection: 'row',
    padding: 2,
    fontSize: 12,
    fontWeight: 'bold',
    width: '100%',
  },
  row: {
    flexGrow: 1,
  },
  rowSpacing: {
    marginHorizontal: 4,
  },
  firstPage: {
    width: '50%',
  },
  secondPage: {
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  standardOptionsColumn: {
    width: '40%',
  },
  costOptionsColumn: {
    flexGrow: 1,
  },
  table: {
    marginVertical: 8,
  },
  paddingRight: {
    paddingRight: 40,
  },
  currencyLabel: {
    flex: 1,
  },
  currencyValue: {
    width: '30%',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  priceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 2,
    fontSize: 10,
  },
  featuresColumn: {
    flex: 1,
    flexDirection: 'column',
  },
  flexColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  features: {
    flexDirection: 'row',
  },
  totalRow: {
    backgroundColor: '#000',
    color: '#FFF',
    marginVertical: 2,
    padding: 2,
    fontSize: 12,
    fontWeight: 'bold',
  },
  footerContainer: {
    flexDirection: 'column',
  },
  flex: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  groupedFeaturesColumn: {
    flexDirection: 'column',
    marginVertical: 8,
  },
  featureColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  featureGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 8,
    justifyContent: 'space-between',
  },
  feature: {
    paddingVertical: 1,
    fontSize: 8,
    fontWeight: 'light',
    flexWrap: 'wrap',
    width: '50%',
  },
});

const DetailItem = ({
  label,
  value = '',
}: {
  label: string;
  value?: string;
}) => {
  return (
    <View style={styles.detailItem}>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{dashIfUndefined(value)}</Text>
    </View>
  );
};

const Features = ({
  features,
  othersLabel,
}: {
  features?: VehicleFeatures;
  othersLabel?: string;
}): JSX.Element => {
  if (!features) {
    return <></>;
  }

  return (
    <View style={styles.featureColumn}>
      {Object.entries(features).map(([key, value]) => (
        <View style={styles.featureGroup}>
          <Text style={styles.tableHeader}>{key}</Text>
          {value?.map((item) => (
            <Text
              style={{
                ...styles.feature,
                fontWeight:
                  othersLabel === item ? 'bold' : styles.feature.fontWeight,
              }}
            >
              {item}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

export const OptionsLandscapePdf: FunctionComponent<
  OptionsLandscapePdfProps
> = ({ vehicle, pdfConfig }): JSX.Element => {
  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <OptionsHeader
          brand={AvailableBrands.AstonMartin}
          logo="https://res.cloudinary.com/motortrak/h_50,c_scale/aston-martin/global/aston-logo-black.jpg"
          modelYear={vehicle.vehicle.modelYear?.toString() || ''}
          name={vehicle.vehicle.model.name || ''}
        />
        <View style={styles.body}>
          <View style={[styles.firstPage, styles.rowSpacing]}>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text>Technical Specification:</Text>
              </View>
              <View style={styles.detailContainer}>
                <View style={styles.row}>
                  <DetailItem
                    label="Engine:"
                    value={vehicle.vehicle.engine?.description}
                  />
                  <DetailItem
                    label="Transmission:"
                    value={vehicle.vehicle.transmission?.name}
                  />
                  <DetailItem
                    label="Power:"
                    value={vehicle.vehicle.engine?.bhp?.toString()}
                  />
                  <DetailItem
                    label="Torque:"
                    value={formatTorque(
                      vehicle.vehicle.engine?.torqueNm,
                      vehicle.vehicle.engine?.torqueLbft,
                    )}
                  />
                  <DetailItem
                    label="Acceleration:"
                    value={formatAcceleration(
                      vehicle.vehicle.techSpecs?.acceleration060Mph,
                      vehicle.vehicle.techSpecs?.acceleration0100Kph,
                    )}
                  />
                  <DetailItem
                    label="Max Speed:"
                    value={formatMaxSpeed(
                      vehicle.vehicle.techSpecs?.maxSpeedMph,
                      vehicle.vehicle.techSpecs?.maxSpeedKph,
                      { style: 'MPH(KPH)' },
                    )}
                  />
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text>Colour & Trim:</Text>
              </View>
              <View style={styles.detailContainer}>
                <View style={styles.row}>
                  <DetailItem
                    label="Exterior"
                    value={vehicle.vehicle.appearanceOptions?.exteriorColour}
                  />
                  <DetailItem
                    label="Upper Interior:"
                    value={vehicle.vehicle.appearanceOptions?.interiorColour}
                  />
                  <DetailItem
                    label="Lower Interior:"
                    value={vehicle.vehicle.appearanceOptions?.seatColour}
                  />
                  <DetailItem
                    label="Carpet:"
                    value={vehicle.vehicle.appearanceOptions?.carpetColour}
                  />
                  <DetailItem
                    label="Headliner:"
                    value={vehicle.vehicle.appearanceOptions?.headliningColour}
                  />
                </View>
              </View>
            </View>
            <View style={styles.divider} />
            <View style={styles.priceRow}>
              <Text>Starting Price:</Text>
              <Text>
                {`${vehicle.currency?.code} ${formatPrice(
                  vehicle.price?.retail,
                )}`}
              </Text>
            </View>
            <View style={styles.priceRow}>
              <Text>Options Total:</Text>
              <Text>-</Text>
            </View>
            <View style={[styles.priceRow, styles.totalRow]}>
              <Text>Total Price:</Text>
              <Text>
                {`${vehicle.currency?.code} ${formatPrice(
                  vehicle.price?.retail,
                )}`}
              </Text>
            </View>
          </View>
          <View style={styles.secondPage}>
            <View style={styles.table}>
              <View style={styles.flex}>
                <View style={styles.featuresColumn}>
                  <Features
                    features={calculateBalancedDistribution(
                      vehicle.vehicle.features || {},
                      MAX_FEATURE_ITEMS,
                      pdfConfig.othersLabel,
                    )}
                    othersLabel={pdfConfig.othersLabel}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.footerContainer}>
          <Text style={styles.footer}>
            {formatConsumptionText(
              vehicle.vehicle,
              AvailableBrands.AstonMartin,
            )}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default OptionsLandscapePdf;
