import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import {
  AstonMartinFlare,
  AstonMartinSans,
} from '../../../../common-ui/fonts/fonts.js';
import { formatDate } from '../../../../util/dates.js';
import {
  formatAcceleration,
  formatPrice,
} from '../../../../util/formatters.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';

export interface CompactPortraitPdfProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
}
Font.register(AstonMartinSans);
Font.register(AstonMartinFlare);

const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'AstonMartinSans',
  },
  header: {
    paddingHorizontal: 40,
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerImage: {
    height: 40,
  },
  divider: {
    display: 'flex',
    width: '100%',
    paddingBottom: 20,
    borderBottom: '6px solid #000',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 20,
  },
  detailItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 4,
    flexWrap: 'wrap',
  },
  label: {
    width: '40%',
    fontSize: 14,
    fontWeight: 'light',
  },
  value: {
    paddingHorizontal: 8,
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
  },
  name: {
    paddingTop: 20,
    fontSize: 42,
    fontWeight: 'light',
    textAlign: 'center',
  },
  price: {
    fontSize: 42,
    textAlign: 'center',
    paddingTop: 4,
  },
  brand: {
    textAlign: 'center',
  },
  footer: {
    paddingTop: 20,
    fontSize: 8,
    fontWeight: 'light',
  },
});

const DetailItem = ({
  label,
  value = '-',
}: {
  label: string;
  value?: string;
}) => {
  return (
    <View style={styles.detailItem}>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};

export const CompactPortraitPdf: FunctionComponent<CompactPortraitPdfProps> = ({
  vehicle,
}): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <Image
            src="https://res.cloudinary.com/motortrak/h_100,c_scale/aston-martin/global/aston-logo-black.jpg"
            style={styles.headerImage}
          />
        </View>
        <View style={styles.divider} />
        <Text style={styles.name}>{vehicle.vehicle.model.name}</Text>
        <View style={styles.detailContainer}>
          <DetailItem
            label="Registration Date"
            value={
              vehicle.vehicle.registrationDate
                ? formatDate(vehicle.vehicle.registrationDate)
                : undefined
            }
          />
          <DetailItem
            label="Mileage"
            value={`${vehicle.odometer?.value} ${vehicle.odometer?.units}`}
          />
          <DetailItem
            label="Exterior Colour"
            value={vehicle.vehicle.appearanceOptions?.exteriorColour || '-'}
          />
          <DetailItem
            label="Interior Colour"
            value={vehicle.vehicle.appearanceOptions?.interiorColour || '-'}
          />
          <DetailItem
            label="Power"
            value={`${vehicle.vehicle.engine?.bhp || ' - '} BHP`}
          />
          <DetailItem
            label="Torque"
            value={`${vehicle.vehicle.engine?.torqueNm || ' - '} NM`}
          />
          <DetailItem
            label="Acceleration"
            value={formatAcceleration(
              vehicle.vehicle.techSpecs?.acceleration0100Kph,
              vehicle.vehicle.techSpecs?.acceleration060Mph,
            )}
          />
          <DetailItem
            label="Maximum Speed"
            value={`${vehicle.vehicle.techSpecs?.maxSpeedMph || ' - '} MPH / ${
              vehicle.vehicle.techSpecs?.maxSpeedKph || ' - '
            } KM/H`}
          />
          <DetailItem
            label="Transmission"
            value={vehicle.vehicle.transmission?.name}
          />
        </View>
        <Text style={styles.price}>
          {`${vehicle.currency?.code} ${formatPrice(
            vehicle.price?.retail,
            undefined,
            { maximumFractionDigits: 0 },
          )}`}
        </Text>
        <View style={styles.divider} />
        <Text style={styles.footer}>
          While every effort has been taken to ensure accuracy of the above
          information, some inaccuracies may occur. It is important that you do
          not rely on this information but check with your Aston Martin Dealer
          about any items that may affect your decision to purchase this
          vehicle. Technical figures relate to a typical example of this model
          when new. They will vary according to the age, condition, fitted
          equipment and mileage of the vehicle.
        </Text>
      </Page>
    </Document>
  );
};

export default CompactPortraitPdf;
