import { ListingLiveStatus } from '../../api/util/index.js';

export const VehicleListingLiveStatus: Record<ListingLiveStatus, string> = {
  [ListingLiveStatus.DEMONSTRATOR]: 'Demonstrator vehicle',
  [ListingLiveStatus.FACTORY_RECALL]: 'Factory recall',
  [ListingLiveStatus.INVALID_VIN]: 'Invalid VIN',
  [ListingLiveStatus.LIVE]: 'Live',
  [ListingLiveStatus.MANUALLY_HIDDEN]: 'Manually hidden',
  [ListingLiveStatus.MAXIMUM_AGE]: 'Vehicle fails age / max mileage criteria',
  [ListingLiveStatus.MAXIMUM_MILEAGE]:
    'Vehicle fails age / max mileage criteria',
  [ListingLiveStatus.MINIMUM_IMAGES]: 'Not enough images',
  [ListingLiveStatus.MINIMUM_MILES]: 'Odometer below minimum required',
  [ListingLiveStatus.MINIMUM_REG_DATE]:
    'Registration date below minimum required',
  [ListingLiveStatus.MISSING_WLTP]: 'Incomplete WLTP information',
  [ListingLiveStatus.NEW_REGYEAR]: 'New vehicle',
  [ListingLiveStatus.NOT_CERTIFIED]: 'Not franchise certified',
  [ListingLiveStatus.NO_MODEL_YEAR]: 'No model year',
  [ListingLiveStatus.NO_PRICE]: 'No retail price',
  [ListingLiveStatus.NO_REGDATE]: 'No registration date',
  [ListingLiveStatus.NO_RULE]: 'Error',
  [ListingLiveStatus.NO_USED_VEHICLES]: 'Vehicle not marked as certified',
  [ListingLiveStatus.PROCESSING]: 'Processing',
  [ListingLiveStatus.PROHIBITED_MODEL]: 'Prohibited model',
  [ListingLiveStatus.RESTRICTED_MODEL_MAX_LISTING]:
    'Restricted model (Limit reached)',
  [ListingLiveStatus.RESTRICTED_MODEL_ONE_LISTING]:
    'Prohibited model (one listing allowed)”',
  [ListingLiveStatus.MAXIMUM_PUBLICATION_DAYS]:
    'Prohibited model (+45 Days since added)”',
  [ListingLiveStatus.REQUIRED_IMAGES]: 'Required images not present',
  [ListingLiveStatus.SOLD]: 'Sold',
  [ListingLiveStatus.SOLD_SUBJECT_TO_FINANCE]: 'Sold subject to finance',
  [ListingLiveStatus.NOT_CERTIFIED_OR_DEMO]:
    'Vehicle not marked as certified or demonstration',
  [ListingLiveStatus.UNDER_CONVERSION]: 'Under conversion',
  [ListingLiveStatus.UNDER_OFFER]: 'Under offer',
  [ListingLiveStatus.UNDER_REVIEW]: 'Under review',
  [ListingLiveStatus.VEHICLE_REMOVED]: 'Vehicle removed from IMS',
};
