import { enumValue, maybe, object, string } from '@fmtk/decoders';
import { ShotstackVideoResolution } from '../../../core/ShotstackService/ShotstackRequest.js';
import { MediaScope } from '../../../util/MediaScope.js';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface SendToVideoGenerationRequest {
  brand: AvailableBrands;
  mediaScope: MediaScope;
  reference: string;
  resolution?: ShotstackVideoResolution;
}

export const decodeSendToVideoGenerationRequest =
  object<SendToVideoGenerationRequest>({
    brand: enumValue(AvailableBrands),
    mediaScope: enumValue(MediaScope),
    reference: string,
    resolution: maybe(enumValue(ShotstackVideoResolution)),
  });
