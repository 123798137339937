import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { VehicleFeatures } from '../../../../../api/util/Vehicle.js';
import {
  AstonMartinFlare,
  AstonMartinSans,
} from '../../../../common-ui/fonts/fonts.js';
import { calculateBalancedDistribution } from '../../../../util/calculateBalancedDistribution.js';
import { formatDate } from '../../../../util/dates.js';
import {
  formatAcceleration,
  formatMaxSpeed,
  formatPrice,
} from '../../../../util/formatters.js';
import { VehicleHandDrive } from '../../../../util/index.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';
import HeaderLogo from './HeaderLogo.js';

const MAX_FEATURE_ITEMS = 35;

export interface CompactLandscapeProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
}

Font.register(AstonMartinSans);
Font.register(AstonMartinFlare);

const styles = StyleSheet.create({
  page: {
    fontFamily: 'AstonMartinSans',
    paddingVertical: 40,
    paddingHorizontal: 30,
  },
  header: {
    flexDirection: 'row',
    height: 50,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    letterSpacing: 0.5,
  },
  headerImage: {
    height: 30,
    margin: 10,
  },
  vehicleName: {
    fontWeight: 'light',
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
    marginVertical: 16,
    borderBottom: '6px solid #000',
  },
  section: {
    fontSize: 10,
    marginBottom: 16,
  },
  title: {
    color: '#58595b',
    paddingBottom: 4,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    fontSize: 9,
    width: '100%',
    marginTop: 16,
  },
  featureGroup: {
    marginTop: 8,
  },
  label: {
    width: '30%',
    fontSize: 8,
    fontWeight: 'bold',
  },
  value: {
    paddingHorizontal: 4,
    fontSize: 8,
    fontWeight: 'light',
    flex: 1,
  },
  sectionItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 1,
    flexWrap: 'wrap',
  },
  sectionContainer: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  detailColumn: {
    width: '40%',
    justifyContent: 'space-between',
  },
  featuresColumn: {
    flex: 1,
  },
  flexColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  features: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 4,
    justifyContent: 'space-between',
  },
  feature: {
    width: '50%',
    paddingVertical: 2,
    paddingRight: 2,
    fontSize: 8,
    fontWeight: 'light',
  },
  priceSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
  },
  price: {
    fontSize: 28,
    color: '#58595b',
    fontWeight: 'bold',
  },
  footer: {
    paddingTop: 10,
    fontSize: 7,
    fontWeight: 'light',
  },
});

interface SectionItemProps {
  label: string;
  value?: string;
}

const SectionItem = ({ label, value = '-' }: SectionItemProps): JSX.Element => {
  return (
    <View style={styles.sectionItem}>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};

interface SectionProps {
  items: SectionItemProps[];
  title: string;
}

const Section = ({ items, title }: SectionProps): JSX.Element => {
  return (
    <View style={styles.section}>
      <Text style={styles.title}>{title}</Text>
      {items.map((item) => (
        <SectionItem key={item.label} label={item.label} value={item.value} />
      ))}
    </View>
  );
};

const Features = ({
  features,
  othersLabel,
}: {
  features?: VehicleFeatures;
  othersLabel?: string;
}): JSX.Element => {
  return (
    <View style={styles.flexColumn}>
      <View style={styles.features}>
        {features &&
          Object.entries(features).map(([key, value]) => (
            <>
              <Text style={[styles.title, styles.featureGroup]}>{key}</Text>
              {value?.map((item) => (
                <Text
                  style={{
                    ...styles.feature,
                    fontWeight:
                      othersLabel === item ? 'bold' : styles.feature.fontWeight,
                  }}
                >
                  {item}
                </Text>
              ))}
            </>
          ))}
      </View>
    </View>
  );
};

const PriceSection = ({ price }: { price: string }): JSX.Element => {
  return (
    <View style={styles.priceSection}>
      <Image
        src="https://res.cloudinary.com/motortrak/h_100,c_scale/aston-martin/global/timeless-srp-desktop.png"
        style={styles.headerImage}
      />
      <Text style={styles.price}>{price}</Text>
    </View>
  );
};

export const CompactLandscape: FunctionComponent<CompactLandscapeProps> = ({
  vehicle,
  pdfConfig,
}): JSX.Element => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>
        <View style={styles.header}>
          <HeaderLogo
            brand={AvailableBrands.AstonMartin}
            logo="https://res.cloudinary.com/motortrak/h_50,c_scale/aston-martin/global/aston-logo-black.jpg"
          />
          <Text style={styles.vehicleName}>{vehicle.vehicle.model.name}</Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.sectionContainer}>
          <View style={styles.detailColumn}>
            <Section
              items={[
                {
                  label: 'Registration Date',
                  value: vehicle.vehicle.registrationDate
                    ? formatDate(vehicle.vehicle.registrationDate)
                    : undefined,
                },
                {
                  label: 'Model Year',
                  value: vehicle.vehicle.modelYear?.toString(),
                },
                {
                  label: 'Odometer',
                  value: `${vehicle.odometer?.value} ${vehicle.odometer?.units}`,
                },
                { label: 'Chassis Number' },
              ]}
              title="Key Details"
            />
            <Section
              items={[
                {
                  label: 'Transmission',
                  value: vehicle.vehicle.transmission?.name,
                },
                {
                  label: 'Power',
                  value: `${vehicle.vehicle.engine?.bhp} BHP` || '',
                },
                {
                  label: 'Torque',
                  value: `${
                    vehicle.vehicle.engine?.torqueNm || ' - '
                  } NM @ 5000 RPM`,
                },
                {
                  label: 'Acceleration',
                  value: formatAcceleration(
                    vehicle.vehicle.techSpecs?.acceleration060Mph,
                    vehicle.vehicle.techSpecs?.acceleration0100Kph,
                  ),
                },
                {
                  label: 'Maximum Speed',
                  value: formatMaxSpeed(
                    vehicle.vehicle.techSpecs?.maxSpeedMph,
                    vehicle.vehicle.techSpecs?.maxSpeedKph,
                    { style: 'MPH/KMH' },
                  ),
                },
                {
                  label: 'Hand Of Drive',
                  value: vehicle.vehicle.handDrive
                    ? VehicleHandDrive[vehicle.vehicle.handDrive]
                    : undefined,
                },
              ]}
              title="Technical Specification"
            />
            <Section
              items={[
                {
                  label: 'Exterior Colour',
                  value:
                    vehicle.vehicle.appearanceOptions?.exteriorColour || '-',
                },
                {
                  label: 'Interior Colour',
                  value:
                    vehicle.vehicle.appearanceOptions?.interiorColour || '-',
                },
                {
                  label: 'Headlining',
                  value:
                    vehicle.vehicle.appearanceOptions?.headliningColour || '-',
                },
                {
                  label: 'Carpet',
                  value: vehicle.vehicle.appearanceOptions?.carpetColour || '-',
                },
                {
                  label: 'Seat Colour',
                  value: vehicle.vehicle.appearanceOptions?.seatColour || '-',
                },
              ]}
              title="Colour and Trim"
            />
          </View>
          <View style={styles.featuresColumn}>
            <Features
              features={calculateBalancedDistribution(
                vehicle.vehicle.features || {},
                MAX_FEATURE_ITEMS,
                pdfConfig.othersLabel,
              )}
              othersLabel={pdfConfig.othersLabel}
            />
          </View>
        </View>
        <PriceSection
          price={`${vehicle.currency?.code} ${formatPrice(
            vehicle.price?.retail,
            undefined,
            { maximumFractionDigits: 0 },
          )}`}
        />
        <Text style={styles.footer}>
          While every effort has been taken to ensure accuracy of the above
          information, some inaccuracies may occur. It is important that you do
          not rely on this information but check with your Aston Martin Dealer
          about any items that may affect your decision to purchase this
          vehicle. Technical figures relate to a typical example of this model
          when new. They will vary according to the age, condition, fitted
          equipment and mileage of the vehicle.
        </Text>
      </Page>
    </Document>
  );
};

export default CompactLandscape;
