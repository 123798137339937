// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { VehicleListing } from '../api/util/Vehicle.js';
import {
  MediaTarget,
  VehicleMedia,
  VehicleMediaStatus,
  VehicleMediaType,
} from '../api/util/VehicleMedia.js';
import { useCloudinary } from '../apps/hooks/useCloudinary.js';
import { BrandConfig } from '../apps/util/BrandsConfig.js';
import { getRelevantMediaFromVehicleMediaFromConfig } from '../core/TransitionsService/util/vehicleMediaHelper.js';

export type ProcessMediaOptions = {
  prioritizeState?: VehicleMediaStatus.Approved | VehicleMediaStatus.Pending;
};

export type VehicleMediaStatusGrouped = {
  [status in VehicleMediaStatus]: VehicleMedia[];
};

export type RelevantImage<
  M extends VehicleMedia<Date | number> = VehicleMedia,
> = {
  hasApproved: boolean;
  hasError: boolean;
  hasPending: boolean;
  hasProcessing: boolean;
  hasRejected: boolean;
  isPlaceholder?: boolean;
  media?: M;
  mediaUrl?: string;
};

// Function to check if vehicleMedia has a specific status and optional types
export const hasStatus = (
  vehicleMedia: VehicleMedia[],
  status: VehicleMediaStatus,
  types?: VehicleMediaType[],
  position?: number,
): boolean => {
  return vehicleMedia.some(
    (media) =>
      (!types || types.includes(media.type)) &&
      media.status === status &&
      (!position || media.position === position),
  );
};

// Function to group vehicleMedia by status and get the count for each group
export const groupCountsByStatus = (
  vehicleMedia: VehicleMedia[],
  desiredStatuses?: VehicleMediaStatus[],
): Map<VehicleMediaStatus, number> => {
  const statusCounts: Map<VehicleMediaStatus, number> = new Map();

  // Initialize statusCounts with all desiredStatuses (if provided)
  (desiredStatuses || Object.values(VehicleMediaStatus)).forEach((status) => {
    statusCounts.set(status, 0);
  });

  vehicleMedia.forEach((media) => {
    const { status } = media;
    if (!desiredStatuses || desiredStatuses.includes(status)) {
      const count = statusCounts.get(status) || 0;
      statusCounts.set(status, count + 1);
    }
  });

  return statusCounts;
};

export const groupByStatus = (
  vehicleMedia: VehicleMedia[],
  desiredStatuses?: VehicleMediaStatus[],
): VehicleMediaStatusGrouped => {
  return vehicleMedia.reduce((result, media) => {
    const { status } = media;

    if (!desiredStatuses || desiredStatuses.includes(status)) {
      if (!result[status]) {
        result[status] = [];
      }
      result[status].push(media);
    }

    return result;
  }, {} as { [status in VehicleMediaStatus]: VehicleMedia[] });
};

export const hasEnoughImages = (
  vehicleMedia: VehicleMedia[],
  brandConfig?: BrandConfig,
): boolean => {
  if (!brandConfig) {
    return false;
  }

  const minPrimaryImages =
    _.maxBy(brandConfig?.vehicleMedia?.primaryImages, 'position')?.position ||
    0;
  const minSecondaryImages =
    brandConfig?.vehicleMedia?.minimumSecondaryShots || 0;

  const validPrimaryImages = vehicleMedia.filter(
    (x) =>
      x.status === VehicleMediaStatus.Approved &&
      x.position <= minPrimaryImages &&
      [VehicleMediaType.Image, VehicleMediaType.Legacy].includes(x.type),
  ).length;
  const validSecondaryImages = vehicleMedia.filter(
    (x) =>
      x.status === VehicleMediaStatus.Approved &&
      x.position > minPrimaryImages &&
      [VehicleMediaType.Image, VehicleMediaType.Legacy].includes(x.type),
  ).length;

  return (
    validPrimaryImages >= minPrimaryImages &&
    validSecondaryImages >= minSecondaryImages
  );
};

export const amountOfApprovedImages = (
  vehicleMedia?: VehicleMedia[],
): number => {
  if (!vehicleMedia) {
    return 0;
  }

  const approvedImages = vehicleMedia.filter(
    (x) =>
      x.status === VehicleMediaStatus.Approved &&
      [VehicleMediaType.Image, VehicleMediaType.Legacy].includes(x.type),
  ).length;

  return approvedImages;
};

export const getRelevantMediaFromVehicleMedia = <
  M extends VehicleMedia = VehicleMedia,
>({
  vehicleMedia,
  options,
}: {
  vehicleMedia: M[];
  options?: {
    displayProcessing?: boolean;
    mediaStatus?: VehicleMediaStatus[];
    mediaType?: VehicleMediaType[];
    position?: number;
    prioritizeState?: VehicleMediaStatus[];
    transformation?: string;
    ghostImage?: string;
  };
}): undefined | RelevantImage<M> => {
  const { config } = useCloudinary();
  return getRelevantMediaFromVehicleMediaFromConfig({
    vehicleMedia,
    config,
    options,
  });
};

// This function generates an array of URLs for vehicle photos based on provided parameters.
// It takes a vehicle listing object and an optional transformation string.
export const vehiclePhotoUrls = (
  vehicleMedia?: VehicleMedia[],
  transformation?: string,
): string[] => {
  if (!vehicleMedia) {
    return [];
  }

  const maxPosition = _.maxBy(vehicleMedia, 'position')?.position || 0;

  const vehicleMediaUrl: string[] = [];

  for (
    let currentPosition = 1;
    currentPosition <= maxPosition;
    currentPosition++
  ) {
    const relevantMedia = getRelevantMediaFromVehicleMedia({
      vehicleMedia,
      options: {
        position: currentPosition,
        mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
        mediaStatus: [VehicleMediaStatus.Approved],
        transformation: transformation || 't_base_vehicle_transformation',
      },
    });

    if (!relevantMedia || !relevantMedia.mediaUrl) {
      continue;
    }

    vehicleMediaUrl.push(relevantMedia.mediaUrl);
  }

  return vehicleMediaUrl;
};

// This function parses a filename to determine the position
export const parseMediaFilename = (
  filename: string,
): { position: number; overwrite: boolean } | undefined => {
  const match = filename.match(/^(.*-)?[pP](\d+)([yY]?)\.?.*$/);
  if (!match) {
    return;
  }

  const position = parseInt(match[2]);
  const overwrite = match[3].toLowerCase() === 'y';

  return { position, overwrite };
};

export const updateMediaStatus = (
  listing: VehicleListing,
  targets: MediaTarget[],
  newState: VehicleMediaStatus,
): VehicleListing => {
  return {
    ...listing,
    vehicleMedia: listing.vehicleMedia?.map((media) => {
      return {
        ...media,
        status: targets.some((t) => t.id === media.cloudinaryId)
          ? newState
          : media.status,
      };
    }) as VehicleMedia[],
  };
};
