import { Cancel, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { UploadApiResponse } from 'cloudinary';
import {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Vehicle } from '../../../../api/util/Vehicle.js';
import {
  ImageTags,
  MediaOrigins,
  VehicleMedia,
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import AccordionExpandIcon from '../../../common-ui/components/AccordionExpandIcon.js';
import UploadImageWidget from '../../../common-ui/components/UploadWidget/UploadWidget.js';
import ImageDetails from '../../../common-ui/components/VehicleImage/ImageDetails.js';
import VehicleImage from '../../../common-ui/components/VehicleImage/VehicleImage.js';
import { ConfirmModal, useMessageStore } from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCloudinary } from '../../../hooks/useCloudinary.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import { useMobile } from '../../../hooks/useMobile.js';
import { sx } from '../../../util/sx.js';
import AccordionHeader from './AccordionHeader.js';

const styles = sx({
  accordionSummary: {
    padding: 0,
  },
  accordionContent: {
    px: 0,
  },
  accordionContainer: {
    border: 1,
    borderWidth: '1px 0',
    borderColor: '#E7EAEE',
    my: 3,
  },
  vehicleImageContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 1,
    justifyContent: 'flex-start',
  },
});

const ShotContainer = styled('div')`
  margin: 5px;
  display: flex;
`;

interface VinImageAccordionProps {
  accordionProps?: Omit<AccordionProps, 'children'>;
  defaultExpanded?: boolean;
  hideSaveButton?: boolean;
  loading?: boolean;
  onChange?: (vinMedia: VehicleMedia | undefined) => void;
  onMediaSave?: (vehicleMedia: VehicleMedia[]) => void;
  readonly?: boolean;
  vehicle?: Vehicle;
  vehicleMedia?: VehicleMedia[];
  vinMd5Hash?: string;
}

const VinImageAccordion: FunctionComponent<VinImageAccordionProps> = ({
  accordionProps,
  defaultExpanded,
  hideSaveButton,
  loading,
  onChange,
  onMediaSave,
  readonly,
  vehicle,
  vehicleMedia,
  vinMd5Hash,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [vinImage, setVinImage] = useState<VehicleMedia | undefined>();
  const [draftVehicleMedia, setDraftVehicleMedia] = useState<VehicleMedia[]>(
    [],
  );
  const isMobile = useMobile();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { brandConfig } = useBrand();
  const { t } = useTranslation();
  const { getCloudinaryUrl } = useCloudinary();
  const { showMessage } = useMessageStore();
  const user = useCurrentUser();
  const [openImageDetails, setOpenImageDetails] = useState<boolean>();
  const [resetConfirm, setResetConfirm] = useState(false);

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const handleRemove = useCallback(() => {
    setVinImage(() => {
      setIsDirty(true);

      const currentVehicleMedia = [...draftVehicleMedia];

      const vinImageIndex = currentVehicleMedia.findIndex(
        (media) => media.type === VehicleMediaType.Vin,
      );

      if (vinImageIndex > -1) {
        currentVehicleMedia.splice(vinImageIndex, 1);
      }

      setDraftVehicleMedia(currentVehicleMedia);

      onChange && onChange(undefined);

      return undefined;
    });
  }, [draftVehicleMedia, onChange]);

  const handleSave = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setIsDirty(false);

      const currentVehicleMedia = [...draftVehicleMedia];

      const vinImageIndex = currentVehicleMedia.findIndex(
        (media) => media.type === VehicleMediaType.Vin,
      );

      if (vinImageIndex > -1) {
        currentVehicleMedia[vinImageIndex].status = VehicleMediaStatus.Approved;
      }

      onMediaSave && onMediaSave(currentVehicleMedia);
    },
    [draftVehicleMedia, onMediaSave],
  );

  const loadFromVehicleMedia = useCallback(() => {
    setVinImage(() => {
      if (!vehicleMedia) {
        return;
      }

      const vinImage = vehicleMedia.find(
        (media) => media.type === VehicleMediaType.Vin,
      );

      setDraftVehicleMedia(vehicleMedia);

      return vinImage;
    });
  }, [vehicleMedia]);

  const handleResetConfirm = useCallback(() => {
    setIsDirty(false);
    loadFromVehicleMedia();
    toggleResetConfirm();
  }, [loadFromVehicleMedia]);

  const handleFileChange = useCallback(
    (cloudinaryDetails: UploadApiResponse) => {
      setIsDirty(true);
      setVinImage(() => {
        const currentVehicleMedia = [...draftVehicleMedia];

        const vinImageIndex = currentVehicleMedia.findIndex(
          (media) => media.type === VehicleMediaType.Vin,
        );

        const newVinImage: VehicleMedia = {
          position: 1,
          type: VehicleMediaType.Vin,
          cloudinaryId: cloudinaryDetails.public_id,
          etag: cloudinaryDetails.etag,
          legacyUrl: getCloudinaryUrl(
            cloudinaryDetails.public_id,
            't_base_vehicle_transformation',
          ),
          status: VehicleMediaStatus.Draft,
          seo: `${vehicle?.brand}/${vehicle?.model.name}/${vehicle?.modelYear}/${vehicle?.vin}/VIN_IMAGE/`,
          signature: cloudinaryDetails.signature,
          uploadDate: new Date(),
          uploaderEmail: user.value?.email,
          origin: MediaOrigins.Cloudinary,
          key: cloudinaryDetails.public_id,
        };

        if (vinImageIndex > -1) {
          currentVehicleMedia[vinImageIndex] = newVinImage;
        } else {
          currentVehicleMedia.push(newVinImage);
        }

        setDraftVehicleMedia(currentVehicleMedia);
        onChange && onChange(newVinImage);

        return newVinImage;
      });
    },
    [
      draftVehicleMedia,
      getCloudinaryUrl,
      onChange,
      user.value?.email,
      vehicle?.brand,
      vehicle?.model.name,
      vehicle?.modelYear,
      vehicle?.vin,
    ],
  );

  useEffect(() => {
    setExpanded(defaultExpanded || false);
  }, [defaultExpanded]);

  useEffect(() => {
    loadFromVehicleMedia();
  }, [loadFromVehicleMedia]);

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      {...accordionProps}
    >
      <AccordionSummary
        expandIcon={<AccordionExpandIcon />}
        sx={styles.accordionSummary}
      >
        <AccordionHeader
          isComplete={!!vinImage}
          title={t('pages.vehiclePage.media.vinImage')}
        />
        {!readonly && (
          <AccordionActions sx={{ marginLeft: 'auto' }}>
            {!isMobile ? (
              <Stack direction="row" flex={1}>
                {!hideSaveButton && (isDirty || loading) && (
                  <>
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleResetConfirm();
                      }}
                      size="small"
                      variant="outlined"
                    >
                      {t('cancel')}
                    </Button>
                    <LoadingButton
                      loading={loading}
                      loadingPosition="start"
                      onClick={handleSave}
                      size="small"
                      startIcon={<Save />}
                      sx={{
                        marginLeft: 2,
                      }}
                      variant="contained"
                    >
                      {t('save')}
                    </LoadingButton>
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={{ marginLeft: 1, marginRight: 1 }}
                    />
                  </>
                )}
              </Stack>
            ) : (
              <Stack direction="row" flex={1}>
                {!hideSaveButton && isDirty && (
                  <>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDirty(false);
                        loadFromVehicleMedia();
                      }}
                    >
                      <Cancel />
                    </IconButton>
                    <IconButton color="primary" onClick={handleSave}>
                      <Save />
                    </IconButton>
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={{ marginLeft: 1, marginRight: 1 }}
                    />
                  </>
                )}
              </Stack>
            )}
            {!readonly && (
              <UploadImageWidget
                multiple
                onError={(error) => {
                  showMessage({
                    text: error.message,
                    severity: 'error',
                  });
                }}
                onSuccess={handleFileChange}
                seo={{
                  brand: vehicle?.brand,
                  modelName: vehicle?.model.name,
                  modelYear: vehicle?.modelYear,
                  vinMd5Hash,
                  registrationYear: vehicle?.registrationYear,
                }}
                tags={[ImageTags.Draft]}
              />
            )}
          </AccordionActions>
        )}
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionContent}>
        <Container maxWidth="xl">
          <Stack marginBottom={3} marginTop={3}>
            <Typography color="#4B5768" variant="subtitle1">
              {t('pages.vehiclePage.media.vinImageSubtitle')}
            </Typography>
            <Typography color="#5d6f8e" variant="body2">
              {t('pages.vehiclePage.media.vinImageDescription')}
            </Typography>
          </Stack>
          <ShotContainer>
            <Box boxShadow={2}>
              <VehicleImage
                hideLabelStatus={
                  vinImage?.status === VehicleMediaStatus.Approved
                }
                onFullViewButtonClick={() => {
                  setOpenImageDetails(true);
                }}
                onRemoveClick={handleRemove}
                seo={{
                  brand: vehicle?.brand,
                  modelName: vehicle?.model.name,
                  modelYear: vehicle?.modelYear,
                  vinMd5Hash,
                  registrationYear: vehicle?.registrationYear,
                }}
                src={vinImage?.legacyUrl || brandConfig?.noVehicleImage}
                status={vinImage?.status}
              />
            </Box>
          </ShotContainer>
        </Container>
      </AccordionDetails>
      {vinImage && (
        <ImageDetails
          onClose={() => {
            setOpenImageDetails(false);
          }}
          open={openImageDetails}
          translationBase="pages.vehiclePage.media"
          vehicleMedia={[vinImage]}
          vehicleMediaType={[VehicleMediaType.Vin]}
          vinMd5Hash={vinMd5Hash}
        />
      )}
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={handleResetConfirm}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Accordion>
  );
};

export default VinImageAccordion;
