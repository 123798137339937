import {
  Analytics,
  AutoMode,
  Dashboard,
  Inventory,
  ManageHistory,
  People,
} from '@mui/icons-material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Dealer } from '../../../api/util/Dealer.js';
import { getAvailableBrandFromId } from '../../../api/util/getAvailableBrandFromId.js';
import { DealerTypes, UserPermissions } from '../../../api/util/index.js';
import { NavListType } from '../../common-ui/index.js';
import { useApiClient } from '../../hooks/useApiClient.js';
import { useAppConfig } from '../../hooks/useAppConfig.js';
import { useAsyncState } from '../../hooks/useAsyncState.js';
import { useCurrentUser } from '../../hooks/useCurrentUser.js';
import { useDealer } from '../../hooks/useDealer.js';

const useNavConfig = (): { navConfig: NavListType[] } => {
  const { inventoryManagementUrl } = useAppConfig();
  const { brand, dealer } = useParams();
  const api = useApiClient();
  const { value: user } = useCurrentUser();
  const { t } = useTranslation();
  const { currentDealer, isLoading: loadingDealer } = useDealer();

  const [dealers, setDealers] = useAsyncState<Dealer[]>();
  const loadDefault = useCallback(async () => {
    if (!user || dealer) {
      return [];
    }

    const availableBrand = getAvailableBrandFromId(Number(brand));
    const dealers = (user.policy.Dealer as string[]) || [];
    const regions = user.policy.Region || [];

    const { data } = await api.dealer.listDealers({
      brand: availableBrand,
      dealerIds: dealers.map((str) => Number(str)),
      regionIds: regions as string[],
      dealerTypes: [DealerTypes.Franchised],
    });

    return data;
  }, [api.dealer, brand, dealer, user]);

  useEffect(() => {
    if (dealer) {
      return;
    }
    setDealers(loadDefault);
  }, [dealer, brand, setDealers, loadDefault]);

  const { loading, value } = dealers;

  const selectedDealer = useMemo(() => {
    const defaultDealerId = dealer || (value && value[0] && value[0].id);
    return value?.find((d) => d.id === defaultDealerId) || currentDealer;
  }, [currentDealer, dealer, value]);

  return {
    navConfig: [
      {
        label: 'nav.global',
        links: [
          {
            title: 'nav.dashboard',
            path: `${brand}/global/dashboard`,
            icon: <Dashboard />,
            requiredPolicy: UserPermissions.GlobalReporting as UserPermissions,
          },
          {
            title: 'nav.analytics',
            path: `${brand}/global/locator-analytics`,
            icon: <Analytics />,
            requiredPolicy: UserPermissions.GlobalReporting as UserPermissions,
            brandBasedConditional: (config) =>
              !!config && !!config.locatorAnalyticsUrl,
          },
        ],
      },
      {
        label: 'nav.dealer',
        links: [
          {
            title: `nav.dealerDashboard`,
            path: `${brand}/dealers/${selectedDealer?.id}/dashboard`,
            icon: <Dashboard />,
            requiredPolicy: UserPermissions.DealerReporting as UserPermissions,
            isLoading: loading || loadingDealer,
          },
          {
            title: `nav.users`,
            path: `${brand}/dealers/${selectedDealer?.id}/users`,
            icon: <People />,
            requiredPolicy: UserPermissions.Admin as UserPermissions,
            isLoading: loading || loadingDealer,
          },
          {
            title: t('remarketing', { defaultValue: 'Remarketing' }),
            path: `${brand}/dealers/${selectedDealer?.id}/remarketing`,
            icon: <AutoMode />,
            requiredPolicy:
              UserPermissions.RemarketingDealer as UserPermissions,
            isLoading: loading || loadingDealer,
            brandBasedConditional: (config) =>
              !!config &&
              !!selectedDealer?.region.id &&
              !!config.remarketing?.regions.includes(
                selectedDealer.region.id.toString(),
              ),
          },
          {
            title: 'nav.inventoryManagement',
            path: inventoryManagementUrl,
            isExternal: true,
            icon: <Inventory />,
            requiredPolicy: UserPermissions.DealerReporting as UserPermissions,
            brandBasedConditional: (config) =>
              !!config && !!config.showStaffAreaLink,
          },
        ],
      },
      {
        label: 'nav.remarketing',
        links: [
          {
            title: 'nav.management',
            path: `${brand}/remarketing/management`,
            icon: <ManageHistory />,
            requiredPolicy: UserPermissions.RemarketingAdmin as UserPermissions,
            brandBasedConditional: (config) =>
              //If the brand has at least one region configured for remarketing then it has the feature enabled
              !!config && (config.remarketing?.regions || []).length > 0,
          },
        ],
      },
    ],
  };
};

export default useNavConfig;
