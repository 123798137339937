import {
  chain,
  maybe,
  number,
  object,
  string,
  text,
  UndefinedFields,
} from '@fmtk/decoders';
import { PartialBy } from '../../../util/PartialBy.js';
import { Dealer, DealerGroup, decodeDealerProps } from '../../util/Dealer.js';
import { isUrl } from '../../util/isUrl.js';

export type PartialDealerGroup = PartialBy<DealerGroup, 'id'>;

export const decodePartialDealerGroup = object<PartialDealerGroup>({
  id: maybe(number),
  name: text,
});

export type UpsertDealerRequest = Omit<Dealer, 'id' | 'dealerGroup'> & {
  id?: number;
  dealerGroup?: PartialBy<DealerGroup, 'id'>;
};

export const decodeUpsertDealerRequest = object<UpsertDealerRequest>(
  {
    ...decodeDealerProps,
    website: maybe(chain(string, isUrl)),
    id: maybe(number),
    dealerGroup: maybe(decodePartialDealerGroup),
  },
  {
    undefinedFields: UndefinedFields.Explicit,
  },
);
