import { Checkbox } from '@mui/material';
import { uniqueId } from 'lodash-es';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useConst } from '../../../hooks/useConst.js';
import { sx } from '../../../util/sx.js';
import { Cell, CellProps, useForm } from '../../index.js';

const styles = sx({
  checkbox: {
    padding: 0,
  },
});

type CellCheckboxInputProps = Pick<
  CellProps,
  'name' | 'label' | 'readonly' | 'formattedValue' | 'required' | 'isLoading'
> & {
  onChange?: (checked: boolean) => void;
};

export const CellCheckboxInput: FunctionComponent<CellCheckboxInputProps> = ({
  name,
  label,
  readonly,
  onChange,
  formattedValue,
  required,
  isLoading,
}) => {
  const [{ values }, formBind, { translations }] = useForm();
  const ref = useConst(() => uniqueId('id'));
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      formBind.setValue(name, event.target.checked);
      onChange && onChange(event.target.checked);
    },
    [formBind, name, onChange],
  );

  return (
    <Cell
      editOnly
      formattedValue={formattedValue}
      htmlFor={ref}
      isLoading={isLoading}
      label={
        translations
          ? t(`${translations}.labels.${name}`, {
              defaultValue: label,
            })
          : label
      }
      name={name}
      readonly={readonly}
      required={required}
    >
      <Checkbox
        checked={Boolean(values[name])}
        disableRipple
        onChange={handleChange}
        size="small"
        sx={styles.checkbox}
      />
    </Cell>
  );
};

export default CellCheckboxInput;
