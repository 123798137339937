import {
  array,
  enumValue,
  maybe,
  number,
  object,
  string,
  unknown,
} from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import {
  VehicleListingLog,
  VehicleListingLogType,
} from '../../util/VehicleListingLog.js';

export interface TransformedChange {
  action: string;
  field: string;
  lhs?: unknown;
  rhs?: unknown;
}

export const decodeTransformedChange = object<TransformedChange>({
  action: string,
  field: string,
  lhs: maybe(unknown),
  rhs: maybe(unknown),
});

export type VehicleListingLogItem = Pick<
  VehicleListingLog,
  | 'brand'
  | 'id'
  | 'logType'
  | 'timestamp'
  | 'userId'
  | 'vin'
  | 'vehicleListingId'
> & { changes: TransformedChange[] };

export const decodeVehicleListingLogItem = object<VehicleListingLogItem>({
  brand: enumValue(AvailableBrands),
  changes: array(decodeTransformedChange),
  id: string,
  logType: enumValue(VehicleListingLogType),
  timestamp: number,
  userId: maybe(string),
  vehicleListingId: string,
  vin: string,
});

export interface ListVehicleListingLogsResponse {
  count: number;
  content: VehicleListingLogItem[];
}

export const decodeListVehicleListingLogsResponse =
  object<ListVehicleListingLogsResponse>({
    count: number,
    content: array(decodeVehicleListingLogItem),
  });
