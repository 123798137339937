import { boolean, enumValue, maybe, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetVehicleListingByIdRequest {
  id: string;
  brand: AvailableBrands;
  archived?: boolean;
}

export const decodeGetVehicleListingByIdRequest =
  object<GetVehicleListingByIdRequest>({
    id: string,
    brand: enumValue(AvailableBrands),
    archived: maybe(boolean),
  });
