import {
  array,
  boolean,
  choose,
  Decoder,
  enumValue,
  is,
  maybe,
  number,
  object,
  ObjectDecoder,
  PropDecoders,
  string,
  text,
  unknown,
} from '@fmtk/decoders';

export enum VehicleMediaStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Archived = 'ARCHIVED',
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Draft = 'DRAFT',
}

export enum VehicleMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  SphericalImage = 'SPHERICAL_IMAGE',
  WalkAround = 'WALK_AROUND',
  YouTube = 'YOUTUBE',
  Legacy = 'LEGACY',
  Vin = 'VIN',
  VdpLinkQr = 'VDP_LINK_QR',
}

export enum ImageTags {
  Draft = 'draft',
  Ims2 = 'ims2',
  Remarketing = 'remarketing',
}

export enum MediaOrigins {
  Cloudinary = 'CLOUDINARY',
  S3 = 'S3',
}

/**
 * Represents the base media information for a vehicle.
 *
 * @template DateType - The type for the upload date, which can be a Date, number, or string.
 */
export type VehicleMediaBase<DateType extends Date | number | string = Date> = {
  /**
   * A description of the media.
   */
  description?: string;

  /**
   * Position of the media in the gallery or sequence.
   */
  position: number;

  /**
   * The date the media was uploaded.
   */
  uploadDate?: DateType;

  /**
   * Email of the user who uploaded the media.
   */
  uploaderEmail?: string;

  /**
   * The type/category of the vehicle media.
   */
  type: VehicleMediaType;

  /**
   * Optional YouTube URL if the media is a video hosted on YouTube.
   */
  youTubeUrl?: string;

  /**
   * The Cloudinary ID of the media when the media is in cloudinary
   */
  cloudinaryId?: string;

  /**
   * A unique identifier for the media, intended to replace `cloudinaryId`.
   */
  // TODO: replace cloudinaryId with key
  key?: string;

  /**
   * Location / service that hosts the media.
   */
  origin?: MediaOrigins;

  /**
   * SEO metadata for the media.
   */
  seo?: string;

  /**
   * A signature for the media, possibly used for verification or security.
   */
  signature?: string;

  /**
   * The legacy URL of the media.
   */
  legacyUrl?: string;

  /**
   * The eTag associated with the media.
   */
  etag?: string;

  /**
   * A list of transformations that will always be applied to this media
   */
  transformations?: string[];

  /**
   * Indicates whether the background replacement was applied to the media.
   */
  bgReplacement?: boolean;
};

export type VehicleMediaApproved<
  DateType extends Date | number | string = Date,
> = VehicleMediaBase<DateType> & {
  reviewDate?: DateType;
  reviewerEmail?: string;
  status: VehicleMediaStatus.Approved;
};

export type VehicleMediaPending<
  DateType extends Date | number | string = Date,
> = VehicleMediaBase<DateType> & {
  status: VehicleMediaStatus.Pending;
};

export type VehicleMediaDraft<DateType extends Date | number | string = Date> =
  VehicleMediaBase<DateType> & {
    status: VehicleMediaStatus.Draft;
  };

export type VehicleMediaError<DateType extends Date | number | string = Date> =
  VehicleMediaBase<DateType> & {
    status: VehicleMediaStatus.Error;
    error?: unknown;
  };

export type VehicleMediaArchived<
  DateType extends Date | number | string = Date,
> = VehicleMediaBase<DateType> & {
  status: VehicleMediaStatus.Archived;
  error?: unknown;
  rejectionReason?: string;
  reviewDate?: DateType;
  reviewerEmail?: string;
  previousStatus: VehicleMediaStatus;
};

export type VehicleMediaProcessing<
  DateType extends Date | number | string = Date,
> = VehicleMediaBase<DateType> & {
  status: VehicleMediaStatus.Processing;
};

export type VehicleMediaRejected<
  DateType extends Date | number | string = Date,
> = VehicleMediaBase<DateType> & {
  rejectionReason: string;
  reviewDate?: DateType;
  reviewerEmail?: string;
  status: VehicleMediaStatus.Rejected;
  isAiSuggestion?: boolean;
};

export interface MediaTarget {
  position: number;
  id: string;
}

export const decodeMediaTarget = object<MediaTarget>({
  id: text,
  position: number,
});

export const vehicleMediaBaseDecoders = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): PropDecoders<VehicleMediaBase<D>> => ({
  description: maybe(string),
  position: number,
  uploadDate: maybe(dateDecoder),
  uploaderEmail: maybe(string),
  type: enumValue(VehicleMediaType),
  youTubeUrl: maybe(string),
  cloudinaryId: maybe(string),
  seo: maybe(string),
  signature: maybe(string),
  legacyUrl: maybe(string),
  etag: maybe(string),
  transformations: maybe(array(string)),
  bgReplacement: maybe(boolean),
  key: maybe(string),
  origin: maybe(enumValue(MediaOrigins)),
});

export const decodeVehicleMediaPending = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleMediaPending<D>> =>
  object<VehicleMediaPending<D>>({
    ...vehicleMediaBaseDecoders(dateDecoder),
    status: is(VehicleMediaStatus.Pending),
  });

export const decodeVehicleMediaDraft = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleMediaDraft<D>> =>
  object<VehicleMediaDraft<D>>({
    ...vehicleMediaBaseDecoders(dateDecoder),
    status: is(VehicleMediaStatus.Draft),
  });

export const decodeVehicleMediaApproved = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleMediaApproved<D>> =>
  object<VehicleMediaApproved<D>>({
    ...vehicleMediaBaseDecoders(dateDecoder),
    reviewDate: maybe(dateDecoder),
    reviewerEmail: maybe(string),
    status: is(VehicleMediaStatus.Approved),
  });

export const decodeVehicleMediaError = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleMediaError<D>> =>
  object<VehicleMediaError<D>>({
    ...vehicleMediaBaseDecoders(dateDecoder),
    status: is(VehicleMediaStatus.Error),
    error: maybe(unknown),
  });

export const decodeVehicleMediaProcessing = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleMediaProcessing<D>> =>
  object<VehicleMediaProcessing<D>>({
    ...vehicleMediaBaseDecoders(dateDecoder),
    status: is(VehicleMediaStatus.Processing),
  });

export const decodeVehicleMediaArchived = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleMediaArchived<D>> =>
  object<VehicleMediaArchived<D>>({
    ...vehicleMediaBaseDecoders(dateDecoder),
    status: is(VehicleMediaStatus.Archived),
    error: maybe(unknown),
    rejectionReason: maybe(string),
    reviewDate: maybe(dateDecoder),
    reviewerEmail: maybe(string),
    previousStatus: enumValue(VehicleMediaStatus),
  });

export const decodeVehicleMediaRejected = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleMediaRejected<D>> =>
  object<VehicleMediaRejected<D>>({
    ...vehicleMediaBaseDecoders(dateDecoder),
    rejectionReason: string,
    reviewDate: maybe(dateDecoder),
    reviewerEmail: maybe(string),
    status: is(VehicleMediaStatus.Rejected),
    isAiSuggestion: maybe(boolean),
  });

export type VehicleMedia<DateType extends Date | number | string = Date> =
  | VehicleMediaApproved<DateType>
  | VehicleMediaPending<DateType>
  | VehicleMediaRejected<DateType>
  | VehicleMediaArchived<DateType>
  | VehicleMediaProcessing<DateType>
  | VehicleMediaError<DateType>
  | VehicleMediaDraft<DateType>;

export const decodeVehicleMedia = <D extends Date | number | string>(
  dateDecoder: Decoder<D>,
): Decoder<VehicleMedia<D>> => {
  return choose(
    decodeVehicleMediaApproved(dateDecoder),
    decodeVehicleMediaPending(dateDecoder),
    decodeVehicleMediaRejected(dateDecoder),
    decodeVehicleMediaArchived(dateDecoder),
    decodeVehicleMediaError(dateDecoder),
    decodeVehicleMediaProcessing(dateDecoder),
    decodeVehicleMediaDraft(dateDecoder),
  );
};

export interface MediaSeo {
  brand?: string;
  modelName?: string;
  modelYear?: number;
  registrationYear?: number;
  vin?: string;
  vinMd5Hash?: string;
}
