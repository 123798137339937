import {
  Box,
  Container,
  Stack,
  Tab,
  Tabs,
  tabsClasses,
  Typography,
} from '@mui/material';
import { useSetAtom } from 'jotai';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ArchiveVehicleListingProcessStatus } from '../../../api/util/ArchiveVehicleListingProcess.js';
import { HttpServiceTransportError } from '../../../api/util/HttpServiceTransportError.js';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../api/util/VehicleMedia.js';
import { VehicleListingErrorCode } from '../../../api/VehicleListingService/common.js';
import { ListTaxonomyOptionsResponse } from '../../../api/VehicleService/listTaxonomyOptions/ListTaxonomyOptionsResponse.js';
import { getRelevantMediaFromVehicleMedia } from '../../../util/vehicleMediaHelper.js';
import DetailsSkeleton from '../../common-ui/components/DetailsSkeleton.js';
import { ErrorState, useMessageStore } from '../../common-ui/index.js';
import { useApiClient, useAsyncState, useMobile } from '../../hooks/index.js';
import { useBrand } from '../../hooks/useBrand.js';
import { isStatusError } from '../../util/checkIfStatusIsError.js';
import { formatOdometer, formatPrice } from '../../util/formatters.js';
import Header from '../components/Vehicle/Header.js';
import HeaderSkeleton from '../components/Vehicle/HeaderSkeleton.js';
import ListingComments from '../components/Vehicle/ListingComments.js';
import ListingFeatures from '../components/Vehicle/ListingFeatures.js';
import VehicleDetails from '../components/Vehicle/VehicleDetails.js';
import VehicleMediaSection from '../components/Vehicle/VehicleMedia.js';
import { useGetArchiveVehicleListingProcess } from '../hooks/queries/useGetArchiveVehicleListingProcess.js';
import { useVehicleListing } from '../hooks/queries/useVehicleListing.js';
import { taxonomyOptionAtom } from '../state/atoms.js';

enum TabOptions {
  Details = 'Details',
  Comments = 'Comments',
  Features = 'Features',
  Media = 'Media',
}

export interface VehiclePageProps {
  archivedMode?: boolean;
}

const VehiclePage: FunctionComponent<VehiclePageProps> = ({
  archivedMode,
}): JSX.Element => {
  const { vehicleId, tab } = useParams();
  const { brandConfig, isEditor, isViewer, currentBrand } = useBrand();
  const mobile = useMobile();
  const api = useApiClient();
  const {
    data: vehicleData,
    isLoading,
    error,
    refetch: refetchVehicleListing,
  } = useVehicleListing(
    vehicleId && currentBrand
      ? {
          id: vehicleId,
          archived: archivedMode,
          brand: currentBrand,
        }
      : undefined,
  );

  const [readonly, setReadOnly] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabOptions>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [optionsState, setOptions] =
    useAsyncState<ListTaxonomyOptionsResponse>();
  const setTaxonomyOptions = useSetAtom(taxonomyOptionAtom);
  const { showMessage } = useMessageStore();
  const { data: archiveProcess } = useGetArchiveVehicleListingProcess({
    vehicleListingId: vehicleId!,
    enabled: !!vehicleId,
  });

  useEffect(() => {
    const current = Object.values(TabOptions).find(
      (x: string) => x.toLowerCase() === tab?.toLowerCase(),
    );

    if (!current) {
      navigate(`./${TabOptions.Details}`, { relative: 'path' });
    }

    setCurrentTab(current);
  }, [navigate, tab]);

  const formattedData = useMemo(() => {
    const vehicleListing = vehicleData;
    if (!vehicleListing) {
      return;
    }

    const { vehicleMedia } = vehicleListing;

    const media = getRelevantMediaFromVehicleMedia({
      vehicleMedia: vehicleMedia || [],
      options: {
        mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
        mediaStatus: [VehicleMediaStatus.Approved, VehicleMediaStatus.Pending],
        transformation: 't_vehicle_thumbnail',
      },
    });

    return {
      image: media?.mediaUrl || brandConfig?.noVehicleImage,
      title: `${
        vehicleListing.vehicle.modelYear ||
        (vehicleListing.vehicle.registrationYear !== 9999 &&
          vehicleListing.vehicle.registrationYear) ||
        ''
      } ${vehicleListing.vehicle.model.name || ''}`,
      subTitle: `${vehicleListing.vehicle.engine?.description || ''} ${
        vehicleListing.vehicle.transmission?.name || ''
      } ${formatOdometer(
        vehicleListing.odometer?.value,
        vehicleListing.odometer?.units,
      )} `,
      retailPrice: formatPrice(
        vehicleListing.price?.retail,
        vehicleListing.currency?.code,
      ),
      otherPrices: [
        vehicleListing.price?.trade
          ? formatPrice(
              vehicleListing.price.trade,
              vehicleListing.currency?.code,
            )
          : undefined,
        vehicleListing.price?.lessTaxes
          ? formatPrice(
              vehicleListing.price.lessTaxes,
              vehicleListing.currency?.code,
            )
          : undefined,
        vehicleListing.price?.retailPlusTax
          ? formatPrice(
              vehicleListing.price.retailPlusTax,
              vehicleListing.currency?.code,
            )
          : undefined,
        vehicleListing.price?.standInValue
          ? formatPrice(
              vehicleListing.price.standInValue,
              vehicleListing.currency?.code,
            )
          : undefined,
        vehicleListing.price?.basePrice
          ? formatPrice(
              vehicleListing.price.basePrice,
              vehicleListing.currency?.code,
            )
          : undefined,
      ].filter(Boolean) as string[],
    };
  }, [brandConfig, vehicleData]);

  const handleChange = (_: React.SyntheticEvent, newValue: TabOptions) => {
    navigate(`../${newValue}`, { relative: 'path' });
    setCurrentTab(newValue);
  };

  const handleArchive = () => {
    setReadOnly(true);
  };

  useEffect(() => {
    if (!currentBrand) {
      return;
    }
    setOptions(async () => {
      const response = await api.vehicle.listTaxonomyOptions({
        brand: currentBrand,
      });
      setTaxonomyOptions(response);
      return response;
    });
  }, [api, currentBrand, setOptions, setTaxonomyOptions]);

  useEffect(() => {
    setReadOnly((isViewer && !isEditor) || !!archiveProcess || !!archivedMode);
  }, [archiveProcess, archivedMode, isEditor, isViewer]);

  useEffect(() => {
    if (
      archiveProcess?.status === ArchiveVehicleListingProcessStatus.Creating
    ) {
      showMessage({
        severity: 'warning',
        text: t('pages.vehiclePage.header.archiveWarning'),
        dismissible: true,
        duration: 3000,
      });
    }
  }, [archiveProcess, showMessage, t]);

  if (!isEditor && !isViewer) {
    return <ErrorState />;
  }

  if (isLoading || optionsState.loading) {
    return (
      <Stack gap={10}>
        <HeaderSkeleton />
        <DetailsSkeleton />
      </Stack>
    );
  }

  if (!!error) {
    return (
      <Stack alignItems="center" height="100vh" justifyContent="center">
        <ErrorState
          error={
            HttpServiceTransportError.is(
              error,
              VehicleListingErrorCode.NotFound,
            )
              ? t(`VehicleListingErrorCode.${error.code}`)
              : undefined
          }
        />
      </Stack>
    );
  }

  return (
    <div>
      {vehicleData && vehicleData.liveStatus && formattedData && vehicleId && (
        <div>
          <Stack
            alignItems="center"
            bgcolor="background.paper"
            pt={{
              xs: 2,
              lg: 8,
            }}
            spacing={2}
          >
            <Container maxWidth="xl">
              <Header
                archivedMode={archivedMode}
                image={formattedData.image}
                isError={isStatusError(vehicleData.liveStatus)}
                isLive={vehicleData.isLive}
                onArchive={handleArchive}
                otherPrices={formattedData.otherPrices}
                readonly={readonly}
                reason={vehicleData.liveStatus}
                retailPrice={formattedData.retailPrice}
                subtitle={formattedData?.subTitle}
                title={formattedData?.title}
                vehicleId={vehicleId}
              />
              {/* Tabs */}
              <Box mt={5}>
                <Tabs
                  allowScrollButtonsMobile
                  onChange={handleChange}
                  scrollButtons
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                  value={currentTab}
                  variant={mobile ? 'scrollable' : undefined}
                >
                  <Tab
                    id="details-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('details')}
                      </Typography>
                    }
                    value={TabOptions.Details}
                  />
                  <Tab
                    id="comments-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('comments')}
                      </Typography>
                    }
                    value={TabOptions.Comments}
                  />
                  <Tab
                    id="features-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('features')}
                      </Typography>
                    }
                    value={TabOptions.Features}
                  />
                  <Tab
                    id="media-tab"
                    label={
                      <Typography variant="subtitle1">{t('media')}</Typography>
                    }
                    value={TabOptions.Media}
                  />
                </Tabs>
              </Box>
            </Container>
          </Stack>
          <Container maxWidth="xl">
            <Box mt={3}>
              {currentTab === TabOptions.Details && (
                <VehicleDetails
                  archivedMode={archivedMode || !!archiveProcess}
                  readonly={readonly}
                  vehicleListing={vehicleData}
                />
              )}
            </Box>
            <Box mt={3}>
              {currentTab === TabOptions.Comments && (
                <ListingComments
                  readonly={readonly}
                  vehicleListing={vehicleData}
                />
              )}
            </Box>
            <Box mt={3}>
              {currentTab === TabOptions.Features && (
                <ListingFeatures
                  readonly={readonly}
                  vehicleListing={vehicleData}
                />
              )}
            </Box>
            <Box mt={3}>
              {currentTab === TabOptions.Media && (
                <VehicleMediaSection
                  onSave={() => void refetchVehicleListing()}
                  options={{ expandedGroup: [VehicleMediaType.Image] }}
                  readonly={readonly}
                  vehicleListing={vehicleData}
                />
              )}
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
};

export default VehiclePage;
