import { Refresh, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  tabsClasses,
} from '@mui/material';
import { useSetAtom } from 'jotai';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { VehicleListingSort } from '../../../api/VehicleListingService/common.js';
import { SyndicationType } from '../../../api/util/SyndicationType.js';
import { IndexName } from '../../../core/SearchIndexService/indexes.js';
import { ErrorState, PageContainer } from '../../common-ui/index.js';
import { useBrand, useDealer, useMobile } from '../../hooks/index.js';
import { useSyndicationTypeOptions } from '../../hooks/useSyndicationTypeOptions.js';
import { sx } from '../../util/sx.js';
import FilterContainer, {
  SelectedFilters,
} from '../components/DealerDashboard/FilterContainer.js';
import SortPanel, { Sort } from '../components/DealerDashboard/SortPanel.js';
import DealerSelector from '../components/DealerSelector.js';
import Section from '../components/Section.js';
import VehicleTable from '../components/VehicleTable/VehicleTable.js';
import { useCountVehicleListing } from '../hooks/queries/useCountVehicleListing.js';
import { useSearchVehicleListings } from '../hooks/queries/useSearchVehicleListings.js';
import { vehicleListingFiltersAtom } from '../state/atoms.js';

type LiveFilterOption = 'all' | 'live' | 'nonLive';

const liveFilters: Record<LiveFilterOption, boolean | undefined> = {
  all: undefined,
  live: true,
  nonLive: false,
};

const styles = sx({
  searchIcon: {
    mr: 1,
    color: '#44546F',
    height: 22,
    width: 22,
  },
  progress: {
    mr: 1,
  },
  scrollObserver: {
    position: 'absolute',
    bottom: 0,
    paddingTop: '100vh', // distance from the bottom of the list to start fetching more data
    zIndex: -1,
  },
  liveFilter: {
    px: 3,
    py: 1,
    borderRadius: 0.5,
    background: (theme) => theme.palette.grey[100],
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  liveFilterActive: {
    background: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.common.white,
    '&:hover': {
      background: (theme) => theme.palette.primary.main,
    },
  },
  syndicationFilter: {
    p: 3,
    borderRadius: 0.5,
    background: (theme) => theme.palette.grey[100],
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  syndicationFilterActive: {
    boxShadow: (theme) => `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
  },
});

const isSyndicationType = (value: string): value is SyndicationType => {
  return Object.values(SyndicationType).includes(value as SyndicationType);
};

export const DefaultSort: Sort = {
  order: 'desc',
  sort: VehicleListingSort.createdDate,
};

export const ArchivedDefaultSort: Sort = {
  order: 'desc',
  sort: VehicleListingSort.timestamp,
};

export interface DashboardDealerPageProps {
  archivedMode?: boolean;
}

enum TabOptions {
  Inventory = 'Inventory',
  Archive = 'Archive',
}

const DashboardDealerPage: FunctionComponent<DashboardDealerPageProps> = ({
  archivedMode,
}): JSX.Element => {
  const { currentDealer, isDisabled } = useDealer();
  const { isEditor } = useBrand();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    isLive: undefined,
    syndicationTypes: new Set(),
  });
  const defaultSort = archivedMode ? ArchivedDefaultSort : DefaultSort;
  const [sortOption, setSortOptions] = useState(defaultSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryFilters = useSetAtom(vehicleListingFiltersAtom);
  const isMobile = useMobile();
  const [currentTab, setCurrentTab] = useState<TabOptions>(
    TabOptions.Inventory,
  );

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useSearchVehicleListings({
    isLive: selectedFilters?.isLive,
    syndicationTypes: [...(selectedFilters?.syndicationTypes || [])],
    searchTerm: searchValue,
    dealerId: currentDealer?.id ? Number(currentDealer?.id) : 0,
    sort: sortOption.sort,
    order: sortOption.order,
    indexName: archivedMode
      ? IndexName.ArchivedVehicleListings
      : IndexName.VehicleListings,
  });

  const {
    data: counts,
    isLoading: loadingCounts,
    isError: errorCounts,
    refetch: refetchCounts,
  } = useCountVehicleListing({
    enabled: !!currentDealer,
    dealerId: currentDealer?.id,
    indexName: archivedMode
      ? IndexName.ArchivedVehicleListings
      : IndexName.VehicleListings,
    isLive: selectedFilters?.isLive,
  });

  const vehicles = data?.pages.flatMap((page) => page.items);
  const count = data?.pages[0].count || 0;
  const brandSyndicationTypes = useSyndicationTypeOptions();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const resetFilters = () => {
    setSortOptions(defaultSort);
    setSearchValue('');
  };

  const handleChange = (_: React.SyntheticEvent, newValue: TabOptions) => {
    setCurrentTab(newValue);
    let path = '../';

    if (newValue === TabOptions.Archive) {
      path = './archived';
    }

    navigate(path, {
      relative: 'path',
    });

    resetFilters();
  };

  const clearSearchValue = useCallback(() => {
    setSearchValue('');
    setSearchParams((state) => {
      const newState = new URLSearchParams(state);
      newState.delete('q');
      return newState;
    });
  }, [setSearchParams]);

  const addVehicle = () => {
    navigate(archivedMode ? '../vehicles/add' : 'vehicles/add');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setSearchParams((state) => {
      const newState = new URLSearchParams(state);
      newState.set('q', event.target.value);
      return newState;
    });
  };

  const selectLiveFilter = (isLive: boolean | undefined) => {
    const newFilters = {
      ...selectedFilters,
      isLive,
    };
    handleFiltersChange(newFilters);
  };

  const handleSyndicationTypeChange = (filter: SyndicationType) => {
    const newSet = new Set(selectedFilters.syndicationTypes);
    if (newSet.has(filter)) {
      newSet.delete(filter);
    } else {
      newSet.add(filter);
    }
    const newFilters = { ...selectedFilters, syndicationTypes: newSet };
    handleFiltersChange(newFilters);
  };

  const handleFiltersChange = useCallback(
    (filters: SelectedFilters) => {
      setSelectedFilters(filters);
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        newState.delete('il');
        newState.delete('st');
        if (filters.isLive !== undefined) {
          newState.append('il', String(filters.isLive));
        }
        filters.syndicationTypes.forEach((v) => {
          newState.append('st', v);
        });

        return newState;
      });
    },
    [setSearchParams],
  );

  const handleSortOptionChange = useCallback(
    (options: Sort) => {
      setSortOptions(options);
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        newState.set('o', options.order);
        newState.set('s', options.sort);

        return newState;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (archivedMode) {
      setCurrentTab(TabOptions.Archive);
      setSortOptions(ArchivedDefaultSort);
    } else {
      setCurrentTab(TabOptions.Inventory);
      setSortOptions(DefaultSort);
    }
  }, [archivedMode]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage, inView]);

  useEffect(() => {
    setQueryFilters((state) => ({ ...state, searchParams: searchParams }));
  }, [searchParams, setQueryFilters]);

  useEffect(() => {
    const q = searchParams.get('q');
    const o = searchParams.get('o');
    const s = searchParams.get('s');
    const il = searchParams.get('il');
    const isLive = il ? il === 'true' : undefined;

    const st = new Set(
      (searchParams.getAll('st') || []).filter(isSyndicationType),
    );

    setSearchValue(q ?? '');
    setSortOptions({
      order:
        o && ['asc', 'desc'].includes(o)
          ? (searchParams.get('o') as 'asc' | 'desc')
          : defaultSort.order,
      sort:
        s && Object.values(VehicleListingSort).includes(s as VehicleListingSort)
          ? (s as VehicleListingSort)
          : defaultSort.sort,
    });
    setSelectedFilters({ isLive, syndicationTypes: st });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Section
        spacing={2}
        title={
          <Stack direction="row" justifyContent="space-between">
            <DealerSelector />
            {isEditor && (
              <div>
                <Button
                  disabled={isLoading || isDisabled}
                  onClick={addVehicle}
                  size="small"
                  variant="contained"
                >
                  {t('addVehicle')}
                </Button>
              </div>
            )}
          </Stack>
        }
      >
        {isDisabled ? (
          <Stack>
            <ErrorState
              alertTitle={t('dealerDisabledTitle')}
              error={t('dealerDisabledMessage')}
            />
          </Stack>
        ) : (
          <>
            <Stack spacing={2}>
              {!isMobile && (
                <>
                  <Stack direction="row" spacing={2}>
                    {Object.entries(liveFilters).map(([filter, value]) => {
                      const option = filter as LiveFilterOption;
                      const isActive = selectedFilters.isLive === value;
                      return (
                        <Stack
                          flex={1}
                          key={`live-filter-${option}`}
                          onClick={() => selectLiveFilter(value)}
                          sx={[
                            styles.liveFilter,
                            isActive && styles.liveFilterActive,
                          ]}
                        >
                          <Typography
                            color={isActive ? 'inherit' : 'grey.600'}
                            fontSize="small"
                            fontWeight="bold"
                          >
                            {t(option)}
                          </Typography>
                          <Stack
                            alignItems="baseline"
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Typography fontSize="small" variant="h5">
                              {option === 'all'
                                ? (counts?.dealer.isLiveCounts?.live ?? 0) +
                                  (counts?.dealer.isLiveCounts?.nonLive ?? 0)
                                : option === 'live'
                                ? counts?.dealer.isLiveCounts?.live ?? 0
                                : counts?.dealer.isLiveCounts?.nonLive ?? 0}
                            </Typography>
                            {!isActive && (
                              <Link
                                fontSize="small"
                                role="button"
                                underline="always"
                              >
                                {t('view')}
                              </Link>
                            )}
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    {brandSyndicationTypes.map(({ text, value }) => {
                      const isActive = selectedFilters.syndicationTypes.has(
                        value as SyndicationType,
                      );
                      return (
                        <Stack
                          flex={1}
                          key={`syndication-filter-${value}`}
                          onClick={() =>
                            handleSyndicationTypeChange(
                              value as SyndicationType,
                            )
                          }
                          spacing={1}
                          sx={[
                            styles.syndicationFilter,
                            isActive && styles.syndicationFilterActive,
                          ]}
                        >
                          <Typography
                            color="grey.600"
                            fontSize="small"
                            fontWeight="bold"
                          >{`${text} (${
                            (counts?.dealer.syndicationTypeCounts || {})[
                              value as SyndicationType
                            ] || 0
                          })`}</Typography>
                          <Stack
                            alignItems="baseline"
                            direction="row"
                            justifyContent="space-between"
                          >
                            {' '}
                            <Typography variant="h5">
                              {(counts?.liveStatus.syndicationTypeCounts || {})[
                                value as SyndicationType
                              ] || 0}
                            </Typography>
                            <Link
                              fontSize="small"
                              role="button"
                              underline="always"
                            >
                              {isActive ? t('clear') : t('view')}
                            </Link>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </>
              )}
              <div>
                <Tabs
                  onChange={handleChange}
                  scrollButtons
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                  value={currentTab}
                  variant={isMobile ? 'scrollable' : undefined}
                >
                  <Tab
                    id="live-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('inventory')}
                      </Typography>
                    }
                    value={TabOptions.Inventory}
                  />
                  <Tab
                    id="archive-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('archived')}
                      </Typography>
                    }
                    value={TabOptions.Archive}
                  />
                </Tabs>
              </div>
              <Stack
                alignItems="center"
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                justifyContent="space-between"
                spacing={2}
              >
                <TextField
                  InputProps={{
                    autoFocus: true,
                    startAdornment: <Search sx={styles.searchIcon} />,
                    endAdornment: searchValue && (
                      <Box display="flex">
                        <Link
                          color="primary"
                          fontSize="small"
                          onClick={clearSearchValue}
                          role="button"
                          underline="always"
                        >
                          {t('clear')}
                        </Link>
                      </Box>
                    ),
                    autoComplete: 'off',
                  }}
                  fullWidth
                  id="search-input"
                  onChange={handleInputChange}
                  placeholder={t('pages.dealerDashboard.searchPlaceholder')}
                  size="small"
                  value={searchValue}
                />
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography fontWeight="fontWeightMedium" variant="subtitle2">
                    {t(
                      isMobile
                        ? 'pages.dealerDashboard.mobileSummaryCounts'
                        : 'pages.dealerDashboard.summaryCounts',
                      {
                        filtered: count,
                        total: counts?.dealer.totalCounts,
                      },
                    )}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      void refetch();
                      void refetchCounts();
                    }}
                    sx={{
                      padding: 0,
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </Stack>

                <Stack direction="row" pr={1} spacing={2}>
                  <SortPanel
                    archiveMode={archivedMode}
                    onChange={handleSortOptionChange}
                    value={sortOption}
                  />
                  {!archivedMode && isMobile && (
                    <FilterContainer
                      counts={counts}
                      onChange={handleFiltersChange}
                      value={selectedFilters}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Box my={2} position="relative">
              <VehicleTable
                archiveMode={archivedMode}
                error={isError || errorCounts}
                loading={isLoading || loadingCounts}
                vehicles={vehicles || []}
              />
              <Box ref={ref} sx={styles.scrollObserver} />
            </Box>
          </>
        )}
      </Section>
    </PageContainer>
  );
};
export default DashboardDealerPage;
