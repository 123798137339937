import { Cancel, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { UploadApiResponse } from 'cloudinary';
import _, { uniqueId } from 'lodash';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { SimpleImage } from '../../../../api/MediaService/reorderMedia/ReorderMediaRequest.js';
import { Vehicle } from '../../../../api/util/Vehicle.js';
import {
  ImageTags,
  MediaOrigins,
  VehicleMedia,
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import {
  getRelevantMediaFromVehicleMedia,
  hasEnoughImages,
} from '../../../../util/vehicleMediaHelper.js';
import AccordionExpandIcon from '../../../common-ui/components/AccordionExpandIcon.js';
import UploadImageWidget from '../../../common-ui/components/UploadWidget/UploadWidget.js';
import ImageDetails from '../../../common-ui/components/VehicleImage/ImageDetails.js';
import { ConfirmModal, useMessageStore } from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCloudinary } from '../../../hooks/useCloudinary.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import { useMobile } from '../../../hooks/useMobile.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { sx } from '../../../util/sx.js';
import ReorderImages from '../ReorderImages/ReorderImages.js';
import AccordionHeader from './AccordionHeader.js';
import DownloadImagesButton from './DownloadImagesButton.js';
import ImageCarousel, { CarouselMedia } from './ImageCarousel.js';

const styles = sx({
  accordionSummary: {
    padding: 0,
  },
  accordionContent: {
    px: 0,
  },
  accordionContainer: {
    border: 1,
    borderWidth: '1px 0',
    borderColor: '#E7EAEE',
    my: 3,
  },
  vehicleImageContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 1,
    justifyContent: 'flex-start',
  },
});

interface ImageAccordionProps {
  vehicle?: Vehicle;
  readonly?: boolean;
  defaultExpanded?: boolean;
  hideSaveButton?: boolean;
  onChange?: (simpleImages?: SimpleImage[]) => void;
  onDeleteMedia?: (media?: VehicleMedia[]) => void;
  vehicleMedia?: VehicleMedia[];
  vinMd5Hash?: string;
  onMediaSave?: () => void;
  loading?: boolean;
  accordionProps?: Omit<AccordionProps, 'children'>;
  required?: boolean;
  imageTags?: ImageTags[];
  carousel?: boolean;
  onCancel?: () => void;
}

const ImageAccordion: FunctionComponent<ImageAccordionProps> = ({
  vehicle,
  readonly,
  defaultExpanded,
  hideSaveButton,
  onChange,
  vehicleMedia,
  vinMd5Hash,
  loading,
  onMediaSave,
  accordionProps,
  required,
  imageTags,
  carousel,
  onCancel,
  onDeleteMedia,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [missingImages, setMissingImages] = useState<boolean>(false);
  const isMobile = useMobile();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { t } = useTranslation();
  const { brandConfig, isLoading } = useBrand();
  const [maxPrimaryPosition, setMaxPrimaryPosition] = useState<number>(0);
  const [mediaToDisplay, setMediaToDisplay] = useState<SimpleImage[]>([]);
  const { showMessage } = useMessageStore();
  const { getCloudinaryUrl } = useCloudinary();
  const user = useCurrentUser();
  const [selectedMedia, setSelectedMedia] = useState<VehicleMedia[]>();
  const [openImageDetails, setOpenImageDetails] = useState<boolean>();
  const [selectedPosition, setSelectedPosition] = useState<
    number | undefined
  >();
  const [resetConfirm, setResetConfirm] = useState(false);

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const tags = useMemo(() => {
    return [ImageTags.Draft, ...(imageTags || [])];
  }, [imageTags]);

  const approvedImages = useMemo(() => {
    return (
      filterFalsey(
        mediaToDisplay.map((m) =>
          m.status === VehicleMediaStatus.Approved && m.media?.cloudinaryId
            ? m.media.cloudinaryId
            : undefined,
        ),
      ) || []
    );
  }, [mediaToDisplay]);

  const loadMediaToDisplay = useCallback(
    (media?: VehicleMedia[]) => {
      const data = media || vehicleMedia || [];

      if (!brandConfig) {
        return;
      }

      // Calculate the first position of secondary shots based on requires images'
      const amountOfPrimaryImagesRequired =
        _.maxBy(brandConfig?.vehicleMedia?.primaryImages, 'position')
          ?.position || 0;

      const maxPositionLoaded = _.maxBy(data, 'position')?.position || 0;

      const images: SimpleImage[] = [];

      for (
        let currentPosition = 1;
        currentPosition <= maxPositionLoaded ||
        currentPosition <= amountOfPrimaryImagesRequired;
        currentPosition++
      ) {
        const ghostImage = brandConfig?.vehicleMedia?.primaryImages?.find(
          (x) => x.position === currentPosition,
        );

        const relevantMedia = getRelevantMediaFromVehicleMedia({
          vehicleMedia: data,
          options: {
            displayProcessing: true,
            ghostImage: ghostImage?.placeHolderUrl,
            position: currentPosition,
            mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
            mediaStatus: [
              VehicleMediaStatus.Approved,
              VehicleMediaStatus.Error,
              VehicleMediaStatus.Pending,
              VehicleMediaStatus.Processing,
              VehicleMediaStatus.Rejected,
              VehicleMediaStatus.Draft,
            ],
            prioritizeState: [
              VehicleMediaStatus.Approved,
              VehicleMediaStatus.Pending,
              VehicleMediaStatus.Rejected,
            ],
            transformation: 't_vehicle_thumbnail',
          },
        });

        if (!relevantMedia) {
          continue;
        }

        images.push({
          id: uniqueId(),
          isPlaceholder: relevantMedia.isPlaceholder,
          src: relevantMedia.mediaUrl,
          label: ghostImage?.label,
          status: relevantMedia?.media?.status,
          hasRejected: relevantMedia?.hasRejected,
          hasPending: relevantMedia?.hasPending,
          hasApproved: relevantMedia?.hasApproved,
          hasError: relevantMedia?.hasError,
          hasProcessing: relevantMedia?.hasProcessing,
          media: relevantMedia.media,
        });
      }

      const missingImages = !hasEnoughImages(data, brandConfig);

      setMissingImages(missingImages);
      setMaxPrimaryPosition(amountOfPrimaryImagesRequired);
      setMediaToDisplay(images);
    },
    [brandConfig, vehicleMedia],
  );

  const handleFileChange = useCallback(
    (cloudinaryDetails: UploadApiResponse, position?: number) => {
      setIsDirty(true);
      setMediaToDisplay((state) => {
        const newState = [...state];

        if (!position) {
          newState.push({
            id: uniqueId(),
            src: getCloudinaryUrl(
              cloudinaryDetails.public_id,
              't_vehicle_thumbnail',
            ),
            status: VehicleMediaStatus.Draft,
            media: {
              position: newState.length + 1,
              type: VehicleMediaType.Image,
              cloudinaryId: cloudinaryDetails.public_id,
              etag: cloudinaryDetails.etag,
              legacyUrl: getCloudinaryUrl(
                cloudinaryDetails.public_id,
                't_base_vehicle_transformation',
              ),
              status: VehicleMediaStatus.Draft,
              seo: `${vehicle?.brand}/${vehicle?.model.name}/${vehicle?.modelYear}/${vehicle?.vin}/`,
              signature: cloudinaryDetails.signature,
              uploadDate: new Date(),
              uploaderEmail: user.value?.email,
              origin: MediaOrigins.Cloudinary,
              key: cloudinaryDetails.public_id,
            },
          });
        } else {
          newState[position - 1] = {
            id: uniqueId(),
            src: getCloudinaryUrl(
              cloudinaryDetails.public_id,
              't_vehicle_thumbnail',
            ),
            status: VehicleMediaStatus.Draft,
            label: newState[position - 1].label,
            media: {
              position: position,
              type: VehicleMediaType.Image,
              cloudinaryId: cloudinaryDetails.public_id,
              etag: cloudinaryDetails.etag,
              legacyUrl: getCloudinaryUrl(
                cloudinaryDetails.public_id,
                't_base_vehicle_transformation',
              ),
              status: VehicleMediaStatus.Draft,
              seo: `${vehicle?.brand}/${vehicle?.model.name}/${vehicle?.modelYear}/${vehicle?.vin}/`,
              signature: cloudinaryDetails.signature,
              uploadDate: new Date(),
              uploaderEmail: user.value?.email,
              origin: MediaOrigins.Cloudinary,
              key: cloudinaryDetails.public_id,
            },
          };
        }

        onChange && onChange(newState);

        return newState;
      });
    },
    [
      getCloudinaryUrl,
      onChange,
      user.value?.email,
      vehicle?.brand,
      vehicle?.model.name,
      vehicle?.modelYear,
      vehicle?.vin,
    ],
  );

  const handleResetConfirm = useCallback(() => {
    setIsDirty(false);
    loadMediaToDisplay();
    toggleResetConfirm();
    onCancel && onCancel();
  }, [loadMediaToDisplay, onCancel]);

  useEffect(() => {
    setExpanded(defaultExpanded || false);
  }, [defaultExpanded]);

  useEffect(() => {
    loadMediaToDisplay(vehicleMedia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleMedia]);

  const carouselMedia = useMemo<CarouselMedia[]>(() => {
    return filterFalsey(
      mediaToDisplay.map((m) => {
        const src = getCloudinaryUrl(m.media?.cloudinaryId, 'q_auto');
        if (!src) {
          return undefined;
        }
        return {
          description: m.media?.description || 'Vehicle media',

          id: m.id,
          src,
        };
      }),
    );
  }, [mediaToDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!brandConfig?.vehicleMedia || isLoading) {
    return <></>;
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      {...accordionProps}
    >
      <AccordionSummary
        expandIcon={<AccordionExpandIcon />}
        sx={styles.accordionSummary}
      >
        <AccordionHeader
          isComplete={!missingImages}
          isRequired={required ? missingImages && !hideSaveButton : false}
          title={t('photos')}
        />
        <AccordionActions sx={{ marginLeft: 'auto' }}>
          {approvedImages.length > 0 && (
            <DownloadImagesButton
              cloudinaryIds={approvedImages}
              fileName={`${vehicle?.modelYear || vehicle?.registrationYear} ${
                vehicle?.model.name || ''
              }`}
            />
          )}
          {!readonly && (
            <>
              {!isMobile ? (
                <Stack direction="row" flex={1}>
                  {!hideSaveButton && (isDirty || loading) && (
                    <>
                      <Button
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleResetConfirm();
                        }}
                        size="small"
                        sx={{
                          marginLeft: 2,
                        }}
                        variant="outlined"
                      >
                        {t('cancel')}
                      </Button>
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDirty(false);
                          onMediaSave && onMediaSave();
                        }}
                        size="small"
                        startIcon={<Save />}
                        sx={{
                          marginLeft: 2,
                        }}
                        variant="contained"
                      >
                        {t('save')}
                      </LoadingButton>
                      <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ marginLeft: 1, marginRight: 1 }}
                      />
                    </>
                  )}
                </Stack>
              ) : (
                <Stack direction="row" flex={1}>
                  {!hideSaveButton && isDirty && (
                    <>
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDirty(false);
                          loadMediaToDisplay();
                        }}
                      >
                        <Cancel />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDirty(false);
                          onMediaSave && onMediaSave();
                        }}
                      >
                        <Save />
                      </IconButton>
                      <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ marginLeft: 1, marginRight: 1 }}
                      />
                    </>
                  )}
                </Stack>
              )}
              <UploadImageWidget
                multiple
                onError={(error) => {
                  showMessage({
                    text: error.message,
                    severity: 'error',
                  });
                }}
                onSuccess={handleFileChange}
                seo={{
                  brand: vehicle?.brand || 'brand',
                  modelName: vehicle?.model.name,
                  modelYear: vehicle?.modelYear,
                  vin: vehicle?.vin,
                  vinMd5Hash,
                  registrationYear: vehicle?.registrationYear,
                }}
                tags={tags}
              />
            </>
          )}
        </AccordionActions>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionContent}>
        <ReorderImages
          maxPrimaryPosition={maxPrimaryPosition}
          onChange={(newMedia) => {
            setMediaToDisplay(newMedia);
            setIsDirty(true);
            onChange && onChange(newMedia);
          }}
          onExpandImage={(position) => {
            setSelectedPosition(position - 1); //Position starts at 1;
            let media: VehicleMedia[] = [];
            if (
              mediaToDisplay[position - 1].status === VehicleMediaStatus.Draft
            ) {
              media = filterFalsey([mediaToDisplay[position - 1].media]);
            } else {
              media =
                vehicleMedia?.filter(
                  (x) =>
                    x.position === position &&
                    [VehicleMediaType.Image, VehicleMediaType.Legacy].includes(
                      x.type,
                    ),
                ) || [];
            }
            setSelectedMedia(media);
            setOpenImageDetails(true);
          }}
          onFileChange={handleFileChange}
          readonly={readonly}
          seo={{
            brand: vehicle?.brand || 'brand',
            modelName: vehicle?.model.name,
            modelYear: vehicle?.modelYear,
            vin: vehicle?.vin,
            vinMd5Hash,
            registrationYear: vehicle?.registrationYear,
          }}
          simplifiedMedia={mediaToDisplay || []}
        />
      </AccordionDetails>
      {selectedMedia &&
        (carousel ? (
          <ImageCarousel
            media={carouselMedia}
            onClose={() => {
              setSelectedMedia(undefined);
              setSelectedPosition(undefined);
            }}
            open
            selectedItem={selectedPosition}
          />
        ) : (
          <ImageDetails
            onClose={() => {
              setOpenImageDetails(false);
              setSelectedMedia(undefined);
            }}
            onDelete={
              !readonly
                ? (media) => {
                    const newMedia = vehicleMedia?.filter(
                      (x) => x.cloudinaryId !== media.cloudinaryId,
                    );

                    onDeleteMedia && onDeleteMedia(newMedia);
                  }
                : undefined
            }
            open={openImageDetails}
            translationBase="pages.vehiclePage.media"
            vehicleMedia={selectedMedia!}
            vinMd5Hash={vinMd5Hash}
          />
        ))}
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={handleResetConfirm}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Accordion>
  );
};

export default ImageAccordion;
