import {
  Decoder,
  ExpectedNumber,
  chain,
  map,
  regexp,
  string,
} from '@fmtk/decoders';

interface NormaliseMoneyOptions {
  allowDecimals?: boolean;
}

export const normaliseMoney = (
  options: NormaliseMoneyOptions = { allowDecimals: false },
): Decoder<number, unknown> =>
  chain(
    string,
    regexp(
      options.allowDecimals ? /^\d+(\.\d\d)?$/ : /^\d+$/,
      'expected number',
      ExpectedNumber,
    ),
    map(parseFloat),
  );
