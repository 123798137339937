import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { VehicleFeatures } from '../../../../../api/util/Vehicle.js';
import {
  AstonMartinFlare,
  AstonMartinSans,
} from '../../../../common-ui/fonts/fonts.js';
import {
  formatAcceleration,
  formatConsumptionText,
  formatMaxSpeed,
  formatPrice,
} from '../../../../util/formatters.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';
import OptionsHeader from './OptionsHeader.js';

export interface OptionsPortraitPdfProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
}
Font.register(AstonMartinSans);
Font.register(AstonMartinFlare);

const styles = StyleSheet.create({
  page: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'AstonMartinSans',
  },
  header: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
  headerImage: {
    height: 20,
    width: 85,
  },
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  modelYear: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  divider: {
    width: '100%',
    marginVertical: 8,
    borderBottom: '2px solid #000',
  },
  lightDivider: {
    borderBottom: '1px solid #000',
  },
  detailContainer: {
    flexDirection: 'row',
  },
  detailItem: {
    flexDirection: 'row',
    paddingVertical: 1,
    flexWrap: 'wrap',
    fontSize: 8,
  },
  featureGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 8,
    justifyContent: 'space-between',
  },
  features: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  feature: {
    paddingVertical: 1,
    flexWrap: 'wrap',
    fontSize: 8,
    width: '50%',
  },
  label: {
    width: '30%',
  },
  value: {
    flex: 1,
    textAlign: 'right',
    paddingHorizontal: 4,
  },
  price: {
    fontSize: 42,
    textAlign: 'center',
    paddingTop: 4,
  },
  brand: {
    textAlign: 'center',
    fontSize: 16,
  },
  footer: {
    fontSize: 6,
  },
  logo: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modelName: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  tableHeader: {
    backgroundColor: '#000',
    color: '#FFF',
    flexDirection: 'row',
    padding: 2,
    fontSize: 12,
    fontWeight: 'bold',
    width: '100%',
  },
  column: {
    width: '50%',
  },
  body: {
    flexDirection: 'column',
  },
  table: {
    marginVertical: 8,
  },
  paddingRight: {
    paddingRight: 20,
  },
  currencyLabel: {
    flex: 1,
  },
  currencyValue: {
    width: '30%',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  priceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 2,
    fontSize: 10,
  },
  totalRow: {
    backgroundColor: '#000',
    color: '#FFF',
    marginVertical: 2,
    padding: 2,
    fontSize: 12,
    fontWeight: 'bold',
  },
});

interface DataItemProps {
  label?: string;
  value?: string;
}

const DetailItem = ({ label, value = '-' }: DataItemProps) => {
  return (
    <View style={styles.detailItem}>
      {label && <Text style={styles.label}>{label}</Text>}
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};

const Features = ({
  features,
  othersLabel,
}: {
  features?: VehicleFeatures;
  othersLabel?: string;
}): JSX.Element => {
  if (!features) {
    return <></>;
  }

  return (
    <View style={styles.features}>
      {Object.entries(features).map(([key, value]) => (
        <View style={styles.featureGroup}>
          <Text style={styles.tableHeader}>{key}</Text>
          {value?.map((item) => (
            <Text
              style={{
                ...styles.feature,
                fontWeight:
                  othersLabel === item ? 'bold' : styles.tableHeader.fontWeight,
              }}
            >
              {item}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

export const OptionsPortraitPdf: FunctionComponent<OptionsPortraitPdfProps> = ({
  vehicle,
}): JSX.Element => {
  const techSpecs: DataItemProps[] = [
    {
      label: 'Engine',
      value: vehicle.vehicle.engine?.cylinders?.toString() || ' - ',
    },
    {
      label: 'Transmission',
      value: vehicle.vehicle.transmission?.name,
    },
    {
      label: 'Power',
      value: vehicle.vehicle.engine?.bhp || '',
    },
    {
      label: 'Torque',
      value: `${vehicle.vehicle.engine?.torqueNm || ' - '} NM @ 5000 RPM`,
    },
    {
      label: 'Acceleration',
      value: formatAcceleration(
        vehicle.vehicle.techSpecs?.acceleration060Mph,
        vehicle.vehicle.techSpecs?.acceleration0100Kph,
      ),
    },
    {
      label: 'Maximum Speed',
      value: formatMaxSpeed(
        vehicle.vehicle.techSpecs?.maxSpeedMph,
        vehicle.vehicle.techSpecs?.maxSpeedKph,
        { style: 'MPH/KMH' },
      ),
    },
  ];

  const colourTrim: DataItemProps[] = [
    {
      label: 'Exterior Colour',
      value: vehicle.vehicle.appearanceOptions?.exteriorColour || '-',
    },
    {
      label: 'Interior Colour',
      value: vehicle.vehicle.appearanceOptions?.interiorColour || '-',
    },
    {
      label: 'Headlining',
      value: vehicle.vehicle.appearanceOptions?.headliningColour || '-',
    },
    {
      label: 'Carpet',
      value: vehicle.vehicle.appearanceOptions?.carpetColour || '-',
    },
    {
      label: 'Seat Colour',
      value: vehicle.vehicle.appearanceOptions?.seatColour || '-',
    },
  ];

  return (
    <Document>
      <Page style={styles.page}>
        <OptionsHeader
          brand={AvailableBrands.AstonMartin}
          logo="https://res.cloudinary.com/motortrak/h_50,c_scale/aston-martin/global/aston-logo-black.jpg"
          modelYear={String(vehicle.vehicle.modelYear)}
          name={vehicle.vehicle.model.name || ''}
        />
        <View style={styles.body}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.column}>Technical Specification:</Text>
              <Text style={styles.column}>Colour & Trim:</Text>
            </View>
            <View style={styles.detailContainer}>
              <View style={[styles.column, styles.paddingRight]}>
                {techSpecs.map((spec, i) => (
                  <DetailItem key={i} label={spec.label} value={spec.value} />
                ))}
              </View>
              <View style={styles.column}>
                {colourTrim.map((spec, i) => (
                  <DetailItem key={i} label={spec.label} value={spec.value} />
                ))}
              </View>
            </View>
          </View>
          <View style={styles.table}>
            {vehicle.vehicle.features && (
              <Features features={vehicle.vehicle.features} />
            )}
            <View style={styles.divider} />
            <View style={styles.priceRow}>
              <Text>Starting Price:</Text>
              <Text>
                {`${vehicle.currency?.code} ${formatPrice(
                  vehicle.price?.retail,
                )}`}
              </Text>
            </View>
            <View style={styles.priceRow}>
              <Text>Options Total:</Text>
              <Text>-</Text>
            </View>
            <View style={[styles.priceRow, styles.totalRow]}>
              <Text>Total Price:</Text>
              <Text>
                {`${vehicle.currency?.code} ${formatPrice(
                  vehicle.price?.retail,
                )}`}
              </Text>
            </View>
            <View style={[styles.divider, styles.lightDivider]} />
            <View>
              <Text style={styles.footer}>
                {formatConsumptionText(
                  vehicle.vehicle,
                  AvailableBrands.AstonMartin,
                )}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OptionsPortraitPdf;
