import { VideoTemplate } from './VideoTemplate.js';

export enum ShotstackVideoResolution {
  SD = 'sd',
  HD = 'hd',
  FHD = '1080',
  UHD = '4k',
  Preview = 'preview',
}

export interface ShotstackOutput {
  format: 'mp4';
  resolution: ShotstackVideoResolution;
  destinations: [
    { provider: 'shotstack'; exclude: true },
    {
      provider: 's3';
      options: {
        region: 'eu-west-1';
        bucket: string;
        prefix: string;
      };
    },
  ];
}

export interface ShotstackRequest {
  callback: string;
  id: VideoTemplate;
  merge: {
    find: string;
    replace: string;
  }[];
}
