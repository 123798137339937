import { isError } from 'lodash';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UserPermissions } from '../../api/util/UserPermissions.js';
import { convertToString } from '../../core/DbService/util/convertToString.js';
import {
  WsChannelEvent,
  WsChannelType,
  getWsChannelName,
  isRemarketingAdminEvent,
  isRemarketingUserEvent,
  isSupportUserEvent,
  isVehicleListingEvent,
  isWsUserEvent,
} from '../../core/PusherService/WsChannel.js';
import {
  Channel,
  ChannelAuthorizationHandler,
  PresenceChannel,
  Pusher,
  UserAuthenticationHandler,
} from '../../shims/pusher-client.node.js';
import { useApiClient } from './useApiClient.js';
import { useBrand } from './useBrand.js';
import { useContextWithError } from './useContextWithError.js';
import { useCurrentUser } from './useCurrentUser.js';
import { useDealer } from './useDealer.js';
import { usePusher } from './usePusher.js';

export enum WsConnectionState {
  Initialized = 'initialized',
  Connecting = 'connecting',
  Connected = 'connected',
  Unavailable = 'unavailable',
  Failed = 'failed',
  Disconnected = 'disconnected',
}

export interface WsChannelClient {
  pusher: Pusher;
  connectionStatus: WsConnectionState;
  dealerReportingChannel?: Channel;
  presenceChannel?: PresenceChannel;
  remarketingAdminChannel?: Channel;
  remarketingUserChannel?: Channel;
  supportUserChannel?: Channel;
}

const wsChannelClientContext = React.createContext<WsChannelClient | undefined>(
  undefined,
);

export function WsChannelClientProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [presenceChannel, setPresenceChannel] = useState<PresenceChannel>();
  const { hasPermission, isSupport } = useCurrentUser();
  const [remarketingAdminChannel, setRemarketingAdminChannel] =
    useState<Channel>();
  const [remarketingUserChannel, setRemarketingUserChannel] =
    useState<Channel>();
  const [supportUserChannel, setSupportUserChannel] = useState<Channel>();
  const [dealerReportingChannel, setDealerReportingChannel] =
    useState<Channel>();

  const api = useApiClient();
  const brand = useBrand();
  const dealer = useDealer();
  const customAuthenticationHandler: UserAuthenticationHandler = useCallback(
    (params, callback) => {
      const res = api.realTime.userAuth({
        socketId: params.socketId,
      });

      res.then(
        (res) => {
          callback(null, {
            auth: res.auth,
            user_data: res.userData,
          });
        },
        (error) => {
          if (!isError(error)) {
            console.error(error);
            return;
          }

          callback(error, null);
        },
      );
    },
    [api],
  );

  const customChannelAuthorizationHandler: ChannelAuthorizationHandler =
    useCallback(
      (params, callback) => {
        if (!brand.currentBrand) {
          return;
        }
        const res = api.realTime.channelAuth({
          socketId: params.socketId,
          channelName: params.channelName,
          brand: brand.currentBrand,
          dealerId: convertToString(dealer.currentDealer?.id) ?? undefined,
        });

        res.then(
          (res) => {
            callback(null, {
              auth: res.auth,
              channel_data: res.channelData,
              shared_secret: res.sharedSecret,
            });
          },
          (error) => {
            if (!isError(error)) {
              console.error(error);
              return;
            }

            callback(error, null);
          },
        );
      },
      [brand.currentBrand, dealer.currentDealer],
    );

  const pusher = usePusher({
    userAuthenticationHandler: customAuthenticationHandler,
    channelAuthorizationHandler: customChannelAuthorizationHandler,
  });

  const hasRemarketingAdminAccess =
    hasPermission(
      UserPermissions.RemarketingAdminBrands as UserPermissions,
      brand.currentBrand,
    ) ||
    hasPermission(
      UserPermissions.RemarketingReadOnlyBrands as UserPermissions,
      brand.currentBrand,
    );

  const hasRemarketingUserAccess = dealer.currentDealer?.id
    ? hasPermission(
        UserPermissions.RemarketingDealer as UserPermissions,
        convertToString(dealer.currentDealer?.id),
      )
    : false;

  const hasDealerReportingAccess = dealer.currentDealer?.id
    ? hasPermission(
        UserPermissions.DealerReporting as UserPermissions,
        convertToString(dealer.currentDealer.id),
      )
    : false;

  useEffect(() => {
    if (!brand.currentBrand) {
      return;
    }

    pusher.config.channelAuthorizer = customChannelAuthorizationHandler;

    pusher.signin();

    // subscribe to the presence channel for the brand
    const presenceChannelName = getWsChannelName(
      WsChannelType.Presence,
      brand.currentBrand,
    );

    const presenceChannel = pusher.subscribe(
      presenceChannelName,
    ) as PresenceChannel;

    setPresenceChannel(presenceChannel);

    if (isSupport || hasRemarketingAdminAccess) {
      const remarketingAdminChannelName = getWsChannelName(
        WsChannelType.RemarketingAdmin,
        brand.currentBrand,
      );

      const remarketingAdminChannel = pusher.subscribe(
        remarketingAdminChannelName,
      );

      setRemarketingAdminChannel(remarketingAdminChannel);
    }

    if (isSupport || hasRemarketingUserAccess) {
      const remarketingUserChannelName = getWsChannelName(
        WsChannelType.RemarketingUser,
        brand.currentBrand,
      );

      const remarketingUserChannel = pusher.subscribe(
        remarketingUserChannelName,
      );

      setRemarketingUserChannel(remarketingUserChannel);
    }

    if (isSupport || hasDealerReportingAccess) {
      const dealerReportingChannelName = getWsChannelName(
        WsChannelType.VehicleListing,
        brand.currentBrand,
      );

      const dealerReportingChannel = pusher.subscribe(
        dealerReportingChannelName,
      );

      setDealerReportingChannel(dealerReportingChannel);
    }

    if (isSupport) {
      const supportUserChannel = pusher.subscribe(WsChannelType.SupportUser);
      setSupportUserChannel(supportUserChannel);
    }

    presenceChannel.bind('pusher:subscription_error', (error: any) => {
      console.error(error);
    });

    return () => {
      presenceChannel.unsubscribe();
      if (remarketingAdminChannel) {
        remarketingAdminChannel.unsubscribe();
      }
      if (remarketingUserChannel) {
        remarketingUserChannel.unsubscribe();
      }
      if (dealerReportingChannel) {
        dealerReportingChannel.unsubscribe();
      }
      if (supportUserChannel) {
        supportUserChannel.unsubscribe();
      }
    };
  }, [
    brand.currentBrand,
    dealer.currentDealer,
    pusher,
    hasRemarketingAdminAccess,
    hasRemarketingUserAccess,
    hasDealerReportingAccess,
  ]);

  return React.createElement(wsChannelClientContext.Provider, {
    children,
    value: {
      pusher,
      presenceChannel,
      remarketingAdminChannel,
      remarketingUserChannel,
      dealerReportingChannel,
      supportUserChannel,
      connectionStatus: pusher.connection.state as WsConnectionState,
    },
  });
}

export function useWebSocketClient(): WsChannelClient {
  const context = useContextWithError(
    wsChannelClientContext,
    'WsChannelClient',
  );

  return context;
}

export function useWsChannelEvent<T>(
  event: WsChannelEvent,
  channelType?: WsChannelType,
): T | undefined {
  const context = useWebSocketClient();
  const [state, setState] = useState<T>();

  useEffect(() => {
    const handler = (data: T): void => {
      setState(data);
    };

    const subscriptions: Array<() => void> = [];

    const bindAndStore = (channel?: Channel): void => {
      if (channel) {
        channel.bind(event, handler);
        subscriptions.push(() => {
          channel.unbind(event, handler);
        });
      }
    };

    if (isWsUserEvent(event)) {
      context.pusher.user.bind(event, handler);
      return () => {
        context.pusher.user.unbind(event, handler);
      };
    }

    if (
      isRemarketingAdminEvent(event) &&
      (!channelType || channelType === WsChannelType.RemarketingAdmin)
    ) {
      bindAndStore(context.remarketingAdminChannel);
    }

    if (
      isRemarketingUserEvent(event) &&
      (!channelType || channelType === WsChannelType.RemarketingUser)
    ) {
      bindAndStore(context.remarketingUserChannel);
    }

    if (
      isSupportUserEvent(event) &&
      (!channelType || channelType === WsChannelType.SupportUser)
    ) {
      bindAndStore(context.supportUserChannel);
    }

    if (
      isVehicleListingEvent(event) &&
      (!channelType || channelType === WsChannelType.VehicleListing)
    ) {
      bindAndStore(context.dealerReportingChannel);
    }

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, [event, context, channelType]);

  return state;
}
