import { InfoOutlined } from '@mui/icons-material';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useInterval } from 'react-use';
import {
  RemarketingType,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import { SyndicationType } from '../../../../api/util/SyndicationType.js';
import {
  BidStatus,
  UserAuctionRemarketingVehicle,
  UserRemarketingVehicle,
} from '../../../../api/util/UserRemarketingVehicle.js';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import { RemarketingVehicleSummary } from '../../../../core/DynamoDbService/commands/GetRemarketingVehicleSummaryCommand.js';
import { getRelevantMediaFromVehicleMedia } from '../../../../util/vehicleMediaHelper.js';
import { Label } from '../../../common-ui/index.js';
import {
  useApiClient,
  useAsyncState,
  useBrand,
  useDealer,
  useMobile,
  useToggle,
} from '../../../hooks/index.js';
import useCountdown from '../../../hooks/useCountDown.js';
import { useRemarketingConfig } from '../../../hooks/useRemarketingConfig.js';
import { VehicleListingFields } from '../../../util/VehicleListingFields.js';
import { formatDateTime } from '../../../util/dates.js';
import {
  PDFTypes,
  filterFalsey,
  remarketingStatusColours,
  remarketingTypeColours,
  sx,
  vehicleTypeColors,
} from '../../../util/index.js';
import { formatMoney } from '../../../util/money.js';
import { WatchlistButton } from '../RemarketingVehicle/Sections/Details/WatchlistButton.js';
import PrintModal from '../Reports/PrintModal.js';
import Details from './Details.js';

type AuctionVehicleCardProps = {
  vehicle: UserRemarketingVehicle;
  onImageClick?: () => void;
};

const styles = sx({
  card: {
    padding: {
      xs: 0,
      md: 3,
    },
    backgroundColor: {
      xs: 'background.paper',
      md: 'background.default',
    },
    borderRadius: '4px',
    gap: 2,
  },
  body: {
    bgcolor: 'background.default',
    p: {
      xs: 2,
      md: 0,
    },
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    justifyContent: 'flex-end',
    display: {
      xs: 'flex',
      md: 'none',
    },
  },
  priceItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    justifyContent: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
  },
  priceAlign: {
    textAlign: {
      xs: 'left',
      lg: 'right',
    },
  },
  dropDownButton: {
    bgcolor: 'background.paper',
  },
  viewsContainer: {
    position: 'absolute',
    margin: '5px; 6px;',
    display: 'flex',
    gap: 1,
    zIndex: 1,
  },
  printButton: {
    borderColor: 'grey.600',
    border: '2px solid #919EAB',
    bgcolor: '#FFF',
    color: 'grey.600',
  },
  boxContainer: {
    bgcolor: 'background.paper',
    border: 2,
    borderColor: '#BDC7D3',
    borderRadius: 0.5,
    flex: 1,
    maxWidth: 800,
    mt: {
      xs: 0,
      md: 1,
    },
    p: 1,
  },
  divider: {
    height: 2,
  },
  verticalDivider: {
    width: 2,
  },
  labels: {
    padding: '2px 6px',
  },
  link: {
    textDecoration: 'underline',
  },
  image: {
    cursor: 'pointer',
  },
});

const Img = styled('img')(({ theme }) => ({
  width: '100%',
  aspectRatio: '4/3',
  borderRadius: '4px',
  objectFit: 'contain',
  [theme.breakpoints.up('md')]: {
    height: 140,
    width: 'auto',
  },
}));

const START_POLING = 30000;
const INTERVAL = 10000;

export default function AuctionVehicleCard({
  vehicle,
  onImageClick,
}: AuctionVehicleCardProps) {
  const { brandConfig } = useBrand();
  const isMobile = useMobile('md');
  const { t, i18n } = useTranslation();
  const api = useApiClient();
  const [summary, setSummary] = useAsyncState<RemarketingVehicleSummary>();
  const [addRemoveWatchList] = useAsyncState<void>();
  const [openPrintModal, toggleOpenPrintModal] = useToggle(false);
  const [remarketingVehicle, setRemarketingVehicle] = useState(vehicle);
  const { currentDealer } = useDealer();
  const [open3minuteRule, setOpen3MinuteRule] = useState(false);
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { brandSyndicationTypes, showVehicleListingField } =
    useRemarketingConfig();

  const closingDate = useMemo(() => {
    return 'closingDate' in remarketingVehicle && remarketingVehicle.closingDate
      ? DateTime.fromMillis(remarketingVehicle.closingDate, {
          locale: i18n.language,
        })
      : undefined;
  }, [remarketingVehicle, i18n]);

  const { timeRemaining, duration, inThePast, timeLeft } =
    useCountdown(closingDate, {
      stopOnZero: true,
      toRelativeOptions: {
        locale: i18n.language,
        unit: ['years', 'months', 'days', 'hours', 'minutes'],
      },
    }) || {};

  const {
    imgSrc,
    detailsItem,
    vehicleTitle,
    leadingBid,
    maxBid,
    startPrice,
    isVisible: showStartDate,
    acceptsOffers,
    syndicationTypePillLabel,
  } = useMemo(() => {
    const vehicleTitle = `${
      remarketingVehicle.vehicle.modelYear ||
      remarketingVehicle.vehicle.registrationYear ||
      ''
    } ${remarketingVehicle.vehicle.model.name}`;

    const media = getRelevantMediaFromVehicleMedia({
      vehicleMedia: remarketingVehicle?.vehicleMedia || [],
      options: {
        mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
        mediaStatus: [VehicleMediaStatus.Approved],
        transformation: 't_vehicle_thumbnail',
      },
    });

    const imgSrc = media?.mediaUrl || brandConfig?.noVehicleImage;

    const leadingBid =
      'leadingBid' in remarketingVehicle && remarketingVehicle.leadingBid
        ? formatMoney(remarketingVehicle.leadingBid)
        : undefined;

    const maxBid =
      'bidStatus' in remarketingVehicle &&
      remarketingVehicle.bidStatus === BidStatus.Leading &&
      !!remarketingVehicle.currentBid
        ? formatMoney(remarketingVehicle.currentBid.maxBid)
        : undefined;

    const mobileItems = isMobile
      ? filterFalsey([
          leadingBid
            ? {
                label: t('pages.remarketingAdmin.leadingBid'),
                value: (
                  <Typography color="grey.700" variant="h6">
                    {
                      (remarketingVehicle as UserAuctionRemarketingVehicle)
                        .currency
                    }{' '}
                    {leadingBid}
                  </Typography>
                ),
              }
            : undefined,
          maxBid
            ? {
                label: t('pages.remarketingAdmin.maxBid'),
                value: (
                  <Typography color="grey.700" variant="h6">
                    {
                      (remarketingVehicle as UserAuctionRemarketingVehicle)
                        .currency
                    }{' '}
                    {maxBid}
                  </Typography>
                ),
              }
            : undefined,
          remarketingVehicle.type !== RemarketingType.UNASSIGNED
            ? {
                label: t('start'),
                value: formatDateTime(
                  DateTime.fromMillis(
                    remarketingVehicle.startingDate,
                  ).toJSDate(),
                ),
              }
            : undefined,
        ])
      : [];

    const detailsItem = filterFalsey([
      ...mobileItems,
      {
        label: t('vin'),
        value: remarketingVehicle.vehicle.vin.toUpperCase(),
      },
      'closingDate' in remarketingVehicle && remarketingVehicle.closingDate
        ? {
            label: t('endDate'),
            value:
              formatDateTime(
                DateTime.fromMillis(remarketingVehicle.closingDate).toJSDate(),
              ) || '-',
          }
        : undefined,
    ]);

    const startPrice =
      remarketingVehicle.type === RemarketingType.AUCTION
        ? formatMoney(remarketingVehicle.startPrice)
        : remarketingVehicle.type === RemarketingType.FIXED_PRICE
        ? formatMoney(remarketingVehicle.price)
        : undefined;

    const isVisible =
      remarketingVehicle.status === RemarketingVehicleStatus.VISIBLE;

    const acceptsOffers =
      remarketingVehicle.type === RemarketingType.FIXED_PRICE &&
      remarketingVehicle.acceptsOffers;

    const syndicationTypePillLabel = brandSyndicationTypes.find(
      (x) => x.value === vehicle.syndicationType,
    )?.text;

    return {
      imgSrc,
      detailsItem,
      vehicleTitle,
      leadingBid,
      maxBid,
      startPrice,
      isVisible,
      acceptsOffers,
      syndicationTypePillLabel,
    };
  }, [
    remarketingVehicle,
    brandConfig?.noVehicleImage,
    isMobile,
    t,
    brandSyndicationTypes,
    vehicle.syndicationType,
  ]);

  const handleWatchList = useCallback(
    (inWatchList: boolean) => {
      if (addRemoveWatchList.loading || !currentDealer?.id) {
        return;
      }

      setRemarketingVehicle((state) => ({
        ...state,
        inWatchList: inWatchList,
      }));
    },
    [addRemoveWatchList.loading, currentDealer],
  );

  useInterval(
    () => {
      setSummary(async () => {
        const response = await api.remarketing.getAuctionVehicleSummary({
          brand: vehicle.vehicle.brand,
          id: vehicle.id,
        });

        return response;
      });
    },
    timeRemaining && timeRemaining <= START_POLING ? INTERVAL : null,
  );

  useEffect(() => {
    setRemarketingVehicle((state) => {
      return {
        ...state,
        ...vehicle,
        ...summary.value,
      } as UserAuctionRemarketingVehicle;
    });
  }, [vehicle, summary.value]);

  useEffect(() => {
    if (location.state?.remarketingId === vehicle.id) {
      containerRef.current?.scrollIntoView({ block: 'center' });
    }
  }, [location.state?.remarketingId, vehicle.id]);

  return (
    <>
      <Grid container ref={containerRef} sx={styles.card}>
        <Grid container direction="column" item md="auto" position="relative">
          <Img
            alt="vehicle img"
            onClick={onImageClick}
            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.src = brandConfig?.noVehicleImage || '';
            }}
            src={imgSrc}
            sx={styles.image}
          />
        </Grid>
        <Grid
          container
          flexDirection="column"
          item
          justifyContent="space-between"
          xs
        >
          <Grid container direction="column" item rowGap={{ xs: 2, md: 0 }}>
            <Grid
              columnGap={3}
              container
              flexDirection={{
                xs: 'column',
                md: 'row',
              }}
              item
              rowGap={1}
            >
              <Grid item>
                <Typography
                  color="primary"
                  component={Link}
                  sx={styles.link}
                  to={remarketingVehicle.id}
                  variant="h4"
                >
                  {vehicleTitle}
                </Typography>
              </Grid>
              <Grid
                alignContent="center"
                columnGap={1}
                container
                item
                xs="auto"
              >
                {syndicationTypePillLabel &&
                  showVehicleListingField(
                    VehicleListingFields.SyndicationType,
                  ) && (
                    <Label
                      bgcolor={
                        vehicleTypeColors[
                          vehicle.syndicationType as SyndicationType
                        ]
                      }
                    >
                      <Typography color="common.white" variant="body2">
                        {syndicationTypePillLabel}
                      </Typography>
                    </Label>
                  )}
                <Label
                  bgcolor={
                    remarketingStatusColours[remarketingVehicle.status]
                      .background
                  }
                  sx={styles.labels}
                >
                  <Typography
                    color={
                      remarketingStatusColours[remarketingVehicle.status].color
                    }
                    fontWeight="fontWeightBold"
                    textTransform="uppercase"
                    variant="caption"
                  >
                    {t(`remarketingVehicleStatus.${remarketingVehicle.status}`)}
                  </Typography>
                </Label>
                {remarketingVehicle.type !== RemarketingType.UNASSIGNED && (
                  <Label
                    bgcolor={
                      remarketingTypeColours[remarketingVehicle.type].background
                    }
                    sx={styles.labels}
                  >
                    <Typography
                      color={
                        remarketingTypeColours[remarketingVehicle.type].color
                      }
                      fontWeight="fontWeightBold"
                      textTransform="uppercase"
                      variant="caption"
                    >
                      {t(`remarketingTypes.${remarketingVehicle.type}`)}
                    </Typography>
                  </Label>
                )}
              </Grid>
            </Grid>
            {leadingBid && !isMobile && (
              <Grid sx={styles.priceContainer}>
                <Typography
                  color="grey.600"
                  fontWeight="fontWeightMedium"
                  gutterBottom
                  m={0}
                  variant="body2"
                >
                  {t('pages.remarketingAdmin.leadingBid')}
                </Typography>
                <Typography variant="h6">
                  {
                    (remarketingVehicle as UserAuctionRemarketingVehicle)
                      .currency
                  }{' '}
                  {leadingBid}
                </Typography>
              </Grid>
            )}
            <Grid container flexDirection="column" item sx={styles.body}>
              <Details
                items={filterFalsey([
                  ...detailsItem,
                  isMobile && duration
                    ? {
                        label: t('timeLeft'),
                        value: (
                          <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                          >
                            <Typography
                              color="error.dark"
                              fontWeight="fontWeightMedium"
                              variant="body2"
                            >
                              {duration.as('milliseconds') <= 0
                                ? '-'
                                : timeLeft}
                            </Typography>
                            <ClickAwayListener
                              onClickAway={() => setOpen3MinuteRule(false)}
                            >
                              <Tooltip
                                arrow
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                onClick={() =>
                                  setOpen3MinuteRule((state) => !state)
                                }
                                open={open3minuteRule}
                                title={t('3minRule', {
                                  count:
                                    brandConfig?.remarketingBidMinutesWindow ??
                                    3,
                                })}
                              >
                                <InfoOutlined
                                  sx={{
                                    fontSize: 16,
                                  }}
                                />
                              </Tooltip>
                            </ClickAwayListener>
                          </Stack>
                        ),
                      }
                    : undefined,
                ])}
              />
            </Grid>
          </Grid>
          {isMobile && (
            <Grid
              bgcolor="background.default"
              container
              direction="row"
              px={2}
              spacing={1}
            >
              {remarketingVehicle.status !==
                RemarketingVehicleStatus.CLOSED && (
                <Grid item xs>
                  <WatchlistButton
                    color="inherit"
                    fullWidth
                    inWatchList={remarketingVehicle.inWatchList}
                    onChange={handleWatchList}
                    remarketingVehicleId={remarketingVehicle.id}
                    sx={styles.printButton}
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs>
                {brandConfig?.availablePdfReports && (
                  <Button
                    color="inherit"
                    fullWidth
                    onClick={toggleOpenPrintModal}
                    sx={styles.printButton}
                    variant="outlined"
                  >
                    {t('print')}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          <Grid container item sx={styles.body} xs>
            <Stack
              divider={<Divider color="#F7F8F9" sx={styles.divider} />}
              justifyContent="space-between"
              spacing={1}
              sx={styles.boxContainer}
            >
              {showStartDate &&
                'startingDate' in remarketingVehicle &&
                remarketingVehicle.startingDate && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      color="grey.600"
                      fontWeight="fontWeightMedium"
                      variant="body2"
                    >
                      {t('start')}
                    </Typography>
                    <Typography
                      color="grey.800"
                      fontWeight="fontWeightMedium"
                      variant="body2"
                    >
                      {formatDateTime(remarketingVehicle.startingDate)}
                    </Typography>
                  </Stack>
                )}
              {showStartDate &&
                (!('startingDate' in remarketingVehicle) ||
                  !remarketingVehicle.startingDate) && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      color="grey.600"
                      fontWeight="fontWeightMedium"
                      variant="body2"
                    >
                      {t('comingSoon')}
                    </Typography>
                  </Stack>
                )}
              {remarketingVehicle.type === RemarketingType.AUCTION && (
                <>
                  {!showStartDate && (
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        color={
                          remarketingVehicle.bidStatus === BidStatus.Leading
                            ? 'success.main'
                            : remarketingVehicle.bidStatus === BidStatus.OutBid
                            ? 'error.main'
                            : 'grey.700'
                        }
                        variant="subtitle2"
                      >
                        {t(`currentBidStatus.${remarketingVehicle.bidStatus}`)}
                      </Typography>
                      {remarketingVehicle.status ===
                        RemarketingVehicleStatus.ACTIVE && (
                        <Typography
                          color="grey.700"
                          component={Link}
                          sx={styles.link}
                          to={remarketingVehicle.id}
                          variant="subtitle2"
                        >
                          {t(
                            `currentBidStatus.link.${remarketingVehicle.bidStatus}`,
                          )}
                        </Typography>
                      )}
                    </Stack>
                  )}
                  {!showStartDate && (
                    <Stack direction="row" justifyContent="space-between">
                      <Typography color="grey.700" variant="subtitle2">
                        {t(
                          `reservePriceStatus.${remarketingVehicle.reservePriceStatus}`,
                        )}
                      </Typography>
                    </Stack>
                  )}
                </>
              )}
              {remarketingVehicle.type === RemarketingType.FIXED_PRICE &&
                remarketingVehicle.status ===
                  RemarketingVehicleStatus.ACTIVE && (
                  <>
                    {acceptsOffers && (
                      <Stack
                        direction="row-reverse"
                        justifyContent="space-between"
                      >
                        <Typography
                          color="grey.700"
                          component={Link}
                          sx={styles.link}
                          to={remarketingVehicle.id}
                          variant="subtitle2"
                        >
                          {t(
                            'pages.remarketingVehiclePage.fixedPrice.submitOffer',
                          )}
                        </Typography>
                      </Stack>
                    )}
                    <Stack
                      direction="row-reverse"
                      justifyContent="space-between"
                    >
                      <Typography
                        color="grey.700"
                        component={Link}
                        sx={styles.link}
                        to={remarketingVehicle.id}
                        variant="subtitle2"
                      >
                        {t('buyNow')}
                      </Typography>
                    </Stack>
                  </>
                )}
            </Stack>
          </Grid>
        </Grid>
        {remarketingVehicle.type !== RemarketingType.UNASSIGNED && (
          <Grid container item justifyContent="flex-end" lg={3} md={4} xs={12}>
            <Grid
              container
              flexDirection="column"
              item
              justifyContent="space-between"
            >
              <Grid
                container
                direction="column"
                display={{
                  xs: 'none',
                  md: 'flex',
                }}
                flex="auto"
                item
                justifyContent="space-between"
              >
                <div>
                  {leadingBid ? (
                    <Stack sx={styles.priceItem}>
                      <Typography
                        color="grey.600"
                        fontWeight="fontWeightMedium"
                        gutterBottom
                        m={0}
                        sx={styles.priceAlign}
                        variant="body2"
                      >
                        {t('pages.remarketingAdmin.leadingBid')}
                      </Typography>
                      <Typography sx={styles.priceAlign} variant="h6">
                        {remarketingVehicle.currency} {leadingBid}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack sx={styles.priceItem}>
                      <Typography
                        color="grey.600"
                        fontWeight="fontWeightMedium"
                        gutterBottom
                        m={0}
                        sx={styles.priceAlign}
                        variant="body2"
                      >
                        {remarketingVehicle.type === RemarketingType.AUCTION
                          ? t('pages.remarketingAdmin.startPrice')
                          : t('price')}
                      </Typography>
                      <Typography sx={styles.priceAlign} variant="h6">
                        {remarketingVehicle.currency} {startPrice}
                      </Typography>
                    </Stack>
                  )}
                  {maxBid && (
                    <Stack sx={styles.priceItem}>
                      <Typography
                        color="grey.600"
                        fontWeight="fontWeightMedium"
                        gutterBottom
                        m={0}
                        sx={styles.priceAlign}
                        variant="body2"
                      >
                        {t('pages.remarketingAdmin.maxBid')}
                      </Typography>
                      <Typography
                        color="grey.700"
                        sx={styles.priceAlign}
                        variant="h6"
                      >
                        {remarketingVehicle.currency} {maxBid}
                      </Typography>
                    </Stack>
                  )}
                  <Stack py={1} sx={styles.priceItem}>
                    {remarketingVehicle.status !==
                      RemarketingVehicleStatus.CLOSED && (
                      <WatchlistButton
                        color="inherit"
                        inWatchList={remarketingVehicle.inWatchList}
                        onChange={handleWatchList}
                        remarketingVehicleId={remarketingVehicle.id}
                        size="small"
                        sx={styles.printButton}
                        variant="outlined"
                      />
                    )}
                    {brandConfig?.availablePdfReports && (
                      <Button
                        color="inherit"
                        onClick={toggleOpenPrintModal}
                        size="small"
                        sx={styles.printButton}
                        variant="outlined"
                      >
                        {t('print')}
                      </Button>
                    )}
                  </Stack>
                </div>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  {!!timeLeft && !!closingDate && (
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        color="grey.600"
                        fontWeight="fontWeightMedium"
                        variant="caption"
                      >
                        {inThePast ? t('endDate') : t('timeLeft')}
                      </Typography>
                      {inThePast ? (
                        <Typography
                          color="grey.800"
                          fontWeight="fontWeightMedium"
                          variant="caption"
                        >
                          {formatDateTime(closingDate.toJSDate())}
                        </Typography>
                      ) : (
                        <Stack
                          direction="row"
                          divider={
                            <Divider
                              color="#BDC7D3"
                              orientation="vertical"
                              sx={styles.verticalDivider}
                            />
                          }
                          spacing={1}
                        >
                          <Typography
                            color="error.dark"
                            fontWeight="fontWeightMedium"
                            variant="caption"
                          >
                            {timeLeft}
                          </Typography>
                          <Typography
                            fontWeight="fontWeightMedium"
                            variant="caption"
                          >
                            {formatDateTime(closingDate.toJSDate())}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  )}
                  {remarketingVehicle.type === RemarketingType.AUCTION && (
                    <Tooltip
                      arrow
                      title={t('3minRule', {
                        count: brandConfig?.remarketingBidMinutesWindow ?? 3,
                      })}
                    >
                      <InfoOutlined
                        sx={{
                          fontSize: 16,
                        }}
                      />
                    </Tooltip>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {openPrintModal && currentDealer && (
        <PrintModal
          onClose={toggleOpenPrintModal}
          open={openPrintModal}
          excludeReports={[PDFTypes.QRCodePOS]}
          vehicleListing={{
            ...remarketingVehicle,
            dealer: {
              ...currentDealer,
              id: Number(currentDealer.id),
            },
            price: {
              priceOnApplication: true,
            },
            currency: remarketingVehicle.currency
              ? {
                  code: remarketingVehicle.currency,
                }
              : undefined,
          }}
        />
      )}
    </>
  );
}
