import { Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { filterFalsey } from '../../util/filterFalsey.js';
import { useForm } from '../context/FormContext.js';
import { FieldError, FieldErrorDefaultTranslationBase } from './FieldError.js';

interface FormErrorsSummaryProps {
  className?: string;
}

const FormErrorsSummary: FunctionComponent<FormErrorsSummaryProps> = ({
  className,
}) => {
  const [state, , { translations }] = useForm();
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      {Object.keys(state.errors).map(
        (name) =>
          !!state.errors[name]?.length && (
            <Stack alignItems="center" direction="row" key={name} spacing={0.5}>
              <Typography
                alignSelf="flex-start"
                color="error"
                fontWeight="700"
                variant="caption"
                whiteSpace="nowrap"
              >
                {`${t(
                  filterFalsey([
                    translations && `${translations}.labels.${name}`,
                    `${FieldErrorDefaultTranslationBase}.${name}`,
                    name,
                  ]),
                  {
                    defaultValue: name,
                  },
                )}:`}
              </Typography>
              <FieldError
                className={className}
                error={state.errors[name]}
                translationBase={translations && `${translations}.errors`}
              />
            </Stack>
          ),
      )}
    </Stack>
  );
};

export default FormErrorsSummary;
