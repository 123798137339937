import { array, jsonDate, maybe } from '@fmtk/decoders';
import { decodeArchiveVehicleListingProcess } from '../util/ArchiveVehicleListingProcess.js';
import { method, service } from '../util/ServiceDef.js';
import { UserPermissions } from '../util/UserPermissions.js';
import { decodeVehicleListing } from '../util/index.js';
import { none } from '../util/none.js';
import { decodeArchiveVehicleListingRequest } from './archiveVehicleListing/ArchiveVehicleListingRequest.js';
import { decodeBatchUploadListingRequest } from './batchUploadListing/BatchUploadListingRequest.js';
import { decodeBatchUploadListingResponse } from './batchUploadListing/BatchUploadListingResponse.js';
import { decodeCreateVehicleListingRequest } from './createVehicleListing/CreateVehicleListingRequest.js';
import { decodeCreateVehicleListingResponse } from './createVehicleListing/CreateVehicleListingResponse.js';
import { decodeDeleteVehicleListingRequest } from './deleteVehicleListing/DeleteVehicleListingRequest.js';
import { decodeFeedSyncStatusRequest } from './feedSyncStatus/FeedSyncStatusRequest.js';
import { decodeFeedSyncStatusResponse } from './feedSyncStatus/FeedSyncStatusResponse.js';
import { decodeGetArchiveVehicleListingProcessRequest } from './getArchiveVehicleListingProcess/GetArchiveVehicleListingProcessRequest.js';
import { decodeGetArchiveVehicleListingProcessResponse } from './getArchiveVehicleListingProcess/GetArchiveVehicleListingProcessResponse.js';
import { decodeGetKioskVehicleRequest } from './getKioskVehicle/GetKioskVehicleRequest.js';
import { decodeGetKioskVehicleResponse } from './getKioskVehicle/GetKioskVehicleResponse.js';
import { decodeGetVehicleByVinRequest } from './getVehicleByVin/GetVehicleByVinRequestRequest.js';
import { decodeGetVehicleByVinResponse } from './getVehicleByVin/GetVehicleByVinResponse.js';
import { decodeGetVehicleListingByIdRequest } from './getVehicleListingById/GetVehicleListingByIdRequest.js';
import { decodeGetVehicleListingByIdResponse } from './getVehicleListingById/GetVehicleListingByIdResponse.js';
import { decodeGetVehicleListingByVinRequest } from './getVehicleListingByVin/GetVehicleByVinRequest.js';
import { decodeGetVehicleListingByVinResponse } from './getVehicleListingByVin/GetVehicleListingByVinResponse.js';
import { decodeGetCountVehicleListingRequest } from './getVehicleListingCounts/GetCountVehicleListingRequest.js';
import { decodeCountGetVehicleListingResponse } from './getVehicleListingCounts/GetCountVehicleListingResponse.js';
import { decodeListCurrenciesResponse } from './listCurrencies/ListCurrenciesResponse.js';
import { decodeListImageQcListingsRequest } from './listImageQcListings/listImageQcListingsRequest.js';
import { decodeListVehicleListingsByVinsRequest } from './listVehicleLIstingsByVins/ListVehicleLIstingsByVinsRequest.js';
import { decodeListVehicleListingLogsRequest } from './listVehicleListingLogs/ListVehicleListingLogsRequest.js';
import { decodeListVehicleListingLogsResponse } from './listVehicleListingLogs/ListVehicleListingLogsResponse.js';
import { decodeListVehicleListingRequest } from './listVehicleListings/ListVehicleListingRequest.js';
import { decodeListVehicleListingResponse } from './listVehicleListings/ListVehicleListingResponse.js';
import { decodeRelistVehicleListingRequest } from './relistVehicleListing/RelistVehicleListingRequest.js';
import { decodeSearchVehicleListingRequest } from './searchVehicleListings/SearchVehicleListingRequest.js';
import { decodeSearchVehicleListingResponse } from './searchVehicleListings/SearchVehicleListingResponse.js';
import { decodeUpdateVehicleListingRequest } from './updateVehicleListing/UpdateVehicleListingRequest.js';
import { decodeUpdateVehicleListingResponse } from './updateVehicleListing/UpdateVehicleListingResponse.js';

export const VehicleListingServiceDef = service({
  batchUploadListing: method(
    decodeBatchUploadListingRequest(jsonDate),
    decodeBatchUploadListingResponse,
    [UserPermissions.Basic],
  ),
  createVehicleListing: method(
    decodeCreateVehicleListingRequest,
    decodeCreateVehicleListingResponse(jsonDate),
    [UserPermissions.Basic],
  ),
  deleteVehicleListing: method(decodeDeleteVehicleListingRequest, none, [
    UserPermissions.Basic,
  ]),
  feedSyncStatus: method(
    decodeFeedSyncStatusRequest,
    decodeFeedSyncStatusResponse,
    [UserPermissions.Basic],
  ),
  getVehicleListingById: method(
    decodeGetVehicleListingByIdRequest,
    maybe(decodeGetVehicleListingByIdResponse(jsonDate)),
    [UserPermissions.Basic],
  ),
  getVehicleListingByVin: method(
    decodeGetVehicleListingByVinRequest,
    maybe(decodeGetVehicleListingByVinResponse(jsonDate)),
    [UserPermissions.Basic],
  ),
  getKioskVehicle: method(
    decodeGetKioskVehicleRequest,
    decodeGetKioskVehicleResponse,
    false,
  ),
  getVehicleByVin: method(
    decodeGetVehicleByVinRequest,
    maybe(decodeGetVehicleByVinResponse),
    [UserPermissions.Basic],
  ),
  listVehicleListings: method(
    decodeListVehicleListingRequest,
    decodeListVehicleListingResponse,
    [UserPermissions.Basic],
  ),
  listCurrencies: method(none, decodeListCurrenciesResponse, [
    UserPermissions.Basic,
  ]),
  updateVehicleListing: method(
    decodeUpdateVehicleListingRequest,
    decodeUpdateVehicleListingResponse,
    [UserPermissions.Basic],
  ),
  searchVehicleListings: method(
    decodeSearchVehicleListingRequest,
    decodeSearchVehicleListingResponse,
    [UserPermissions.Basic],
  ),
  listVehicleListingsByVins: method(
    decodeListVehicleListingsByVinsRequest,
    decodeListVehicleListingResponse,
    [UserPermissions.Basic],
  ),
  getVehicleListingsCounts: method(
    decodeGetCountVehicleListingRequest,
    decodeCountGetVehicleListingResponse,
    [UserPermissions.Basic],
  ),
  archiveVehicleListing: method(
    decodeArchiveVehicleListingRequest,
    decodeArchiveVehicleListingProcess,
    [UserPermissions.Basic],
  ),
  getArchiveVehicleListingProcess: method(
    decodeGetArchiveVehicleListingProcessRequest,
    decodeGetArchiveVehicleListingProcessResponse,
    [UserPermissions.Basic],
  ),
  relistVehicleListing: method(decodeRelistVehicleListingRequest, none, [
    UserPermissions.Basic,
  ]),
  listImageQcListings: method(
    decodeListImageQcListingsRequest,
    array(decodeVehicleListing(jsonDate)),
    [UserPermissions.Support],
  ),
  listVehicleListingLogs: method(
    decodeListVehicleListingLogsRequest,
    decodeListVehicleListingLogsResponse,
    [UserPermissions.Support],
  ),
});

export type VehicleListingServiceDef = typeof VehicleListingServiceDef;
