import React, { PropsWithChildren, useState } from 'react';
import { Dealer } from '../../api/util/Dealer.js';
import { DealerTypes } from '../../api/util/DealerTypes.js';
import { useContextWithError } from './useContextWithError.js';

export type DealerSelectionType = Dealer;
export interface DealerContext {
  currentDealer: DealerSelectionType | null;
  isDisabled?: boolean;
  updateCurrentDealer: (dealer: DealerSelectionType | null) => void;
  isLoading?: boolean;
  error?: unknown;
}

const dealerContext = React.createContext<DealerContext | undefined>(undefined);

export const DealerProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [currentDealer, setCurrentDealer] =
    useState<DealerSelectionType | null>(null);

  return React.createElement(dealerContext.Provider, {
    children,
    value: {
      currentDealer: currentDealer,
      isDisabled: currentDealer?.type === DealerTypes.Disabled,
      updateCurrentDealer: setCurrentDealer,
    },
  });
};

export const useDealer = (): DealerContext => {
  return useContextWithError(dealerContext, 'dealer');
};
