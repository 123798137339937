export enum RejectionReason {
  MinimumImageStandards = 'Image does not meet the minimum image standards',
  IncorrectOrder = 'Image uploaded in incorrect order',
  TamperedImage = 'This picture has been tampered with (e.g. frames, montage, text added)',
  PortraitFormat = 'Images must be in landscape, not portrait format',
  UnfavourableReflections = 'Images show unfavourable reflections or shadows',
  ClutteredBackground = 'Image background must be free of clutter and distractions',
  UncleanVehicle = 'Vehicle is not clean or dry',
  DamageShown = 'Image shows damage',
  ObstructionInWay = 'Image has something in the way',
  PriceShown = 'Image should not show a price',
  IncompleteVehicle = "Image doesn't show full vehicle",
  NotOfVehicle = 'Image is not of vehicle',
  ImageBgReplaced = 'Image already background replaced',
  NotPermittedPlaceholder = 'Placeholder images not permitted',
  AlreadyReordered = 'Image already re-ordered',
  IncorrectPlates = 'Incorrect show plates fitted',
  DarkOrBlurry = 'Image too dark or blurry',
  UnacceptableBranding = 'Unacceptable branding visible',
}

export const getRejectionReasonByValue = (
  value: string,
): RejectionReason | undefined => {
  return Object.keys(RejectionReason).find(
    (reason) =>
      (RejectionReason as { [key: string]: RejectionReason })[reason] === value,
  ) as RejectionReason | undefined;
};
