import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { SendToVideoGenerationRequest } from '../../../../api/MediaService/sendToVideoGeneration/SendToVideoGenerationRequest.js';
import { ClientErrorCode } from '../../../../api/util/ClientError.js';
import { HttpServiceTransportError } from '../../../../api/util/HttpServiceTransportError.js';
import { useMessageStore } from '../../../common-ui/context/MessageContext.js';
import { useApiClient } from '../../../hooks/useApiClient.js';

export const useVideoGeneration = (): UseMutationResult<
  void,
  unknown,
  SendToVideoGenerationRequest
> => {
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (values) => {
      await api.media.sendToVideoGeneration(values);
    },
    onSuccess: () => {
      showMessage({
        text: t('successMessage'),
        severity: 'success',
        dismissible: true,
      });
    },
    onError: (error) => {
      console.error(error);

      if (error instanceof HttpServiceTransportError) {
        if (error.code === ClientErrorCode.NotImplemented) {
          showMessage({
            severity: 'error',
            text: t('notImplementedMessage'),
            dismissible: true,
          });
          return;
        }
      }

      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    },
  });
};
