/**
 * Check if the current environment is a browser.
 * @returns {boolean} True if the current environment is a browser.
 * @example
 * ```ts
 * if (isBrowser) {
 *  console.log(navigator.language);
 * }
 */
export const isBrowser =
  typeof window === 'object' && typeof document === 'object';
