import { Box, CircularProgress } from '@mui/material';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AvailableBrands } from '../../../api/util/AvailableBrands.js';
import { Dealer } from '../../../api/util/Dealer.js';
import { DealerTypes } from '../../../api/util/DealerTypes.js';
import {
  getAvailableBrandFromId,
  getBrandIdFromAvailableBrand,
} from '../../../api/util/getAvailableBrandFromId.js';
import { EmptyState } from '../../common-ui/index.js';
import { useApiClient } from '../../hooks/useApiClient.js';
import { useAsyncState } from '../../hooks/useAsyncState.js';
import { useCurrentUser } from '../../hooks/useCurrentUser.js';

const Home: FunctionComponent = (): JSX.Element => {
  const { brand, dealer } = useParams();
  const [noAccessError, setNoAccessError] = useState(false);
  const user = useCurrentUser();
  const navigate = useNavigate();
  const api = useApiClient();
  const [brandDealers, setDealers] = useAsyncState<Dealer[]>();

  const [availableBrand, hasGlobalReporting, hasDealerReporting] =
    useMemo(() => {
      // Get the current available brand or the first available brand if none is provided
      const availableBrand =
        getAvailableBrandFromId(Number(brand)) ||
        (user?.value?.policy.Brand as AvailableBrands[])[0];
      // Check if the user has global reporting and dealer reporting permissions for the current available brand
      const hasGlobalReporting = user.hasPermission(
        'GlobalReporting',
        availableBrand,
      );
      const hasDealerReporting = user.hasPermission(
        'DealerReporting',
        availableBrand,
      );
      return [availableBrand, hasGlobalReporting, hasDealerReporting];
    }, [user, brand]);

  const loadBrandDealers = useCallback(async () => {
    // If the user has the GlobalReporting policy, it is not necessary to load dealers because we only need to set the default dealer if the user has access to the dealer dashboard.
    if (!user || hasGlobalReporting) {
      return [];
    }

    const dealers = (user.value?.policy.Dealer as string[]) || [];
    const regions = user.value?.policy.Region || [];

    const { data } = await api.dealer.listDealers({
      brand: availableBrand,
      dealerIds: dealers.map((str) => Number(str)),
      regionIds: regions as string[],
      dealerTypes: [DealerTypes.Franchised],
    });

    return data;
  }, [api.dealer, availableBrand, hasGlobalReporting, user]);

  useEffect(() => {
    setDealers(loadBrandDealers);
  }, [brand, setDealers, loadBrandDealers]);

  useEffect(() => {
    if (!user.value) {
      return;
    }
    // Get the default brand (or the current available brand if none is provided)
    const defaultBrand = brand || getBrandIdFromAvailableBrand(availableBrand);

    if (hasGlobalReporting) {
      // Redirect to the global reporting page if the user has global reporting permissions
      navigate(`/${defaultBrand}/global/dashboard`, {
        replace: true,
      });
      return;
    } else if (hasDealerReporting && brandDealers.value) {
      const [firstDealer] = brandDealers.value; // Get the first dealer from the brandDealers array
      const defaultDealer = dealer || firstDealer?.id; // Set the default dealer as the provided dealer parameter or the ID of the first dealer
      if (!defaultDealer) {
        setNoAccessError(true);
      }
      navigate(`/${defaultBrand}/dealers/${defaultDealer}/dashboard`, {
        replace: true, // Navigate to the dashboard page of the default dealer for the specified brand
      });
      return;
    } else {
      setNoAccessError(true);
    }
  }, [
    availableBrand,
    brand,
    brandDealers.value,
    dealer,
    hasDealerReporting,
    hasGlobalReporting,
    navigate,
    user,
  ]);

  if (noAccessError && !brandDealers.loading && !brandDealers.value) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <EmptyState severity="info">
          You don't have any access assigned. Please contact us at
          support@connect.auto
        </EmptyState>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={60} />
    </Box>
  );
};

export default Home;
