import {
  MutationOptions,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { VehicleListing } from '../../../../api/util/Vehicle.js';
import { UpdateVehicleListingRequest } from '../../../../api/VehicleListingService/updateVehicleListing/UpdateVehicleListingRequest.js';
import { useMessageStore } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  makeSearchVehicleListingsQueryKey,
  SearchVehicleListingsData,
} from '../queries/useSearchVehicleListings.js';
import { makeVehicleListingQueryKey } from '../queries/useVehicleListing.js';

export const useUpdateVehicleListing = (
  props?: MutationOptions<
    VehicleListing | undefined,
    unknown,
    UpdateVehicleListingRequest
  >,
): UseMutationResult<
  VehicleListing | undefined,
  unknown,
  UpdateVehicleListingRequest
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  return useMutation<
    VehicleListing | undefined,
    unknown,
    UpdateVehicleListingRequest
  >({
    mutationFn: async (req) => {
      return await api.vehicleListing.updateVehicleListing(req);
    },
    onSuccess: (updated, request) => {
      queryClient.setQueryData<VehicleListing>(
        makeVehicleListingQueryKey({ id: request.id, brand: request.brand }),
        (prev) => {
          if (prev && updated && prev.id === updated.id) {
            return {
              ...prev,
              ...updated,
            };
          }
        },
      );

      queryClient.setQueriesData<SearchVehicleListingsData>(
        {
          queryKey: makeSearchVehicleListingsQueryKey(request.brand),
        },
        (prev) => {
          if (prev && updated) {
            return {
              ...prev,
              pages: prev.pages.map((p) => {
                return {
                  ...p,
                  items: p.items.map((i) => {
                    if (i.id === updated.id) {
                      return {
                        ...i,
                        ...updated,
                      };
                    }
                    return i;
                  }),
                };
              }),
            };
          }
        },
      );

      showMessage({
        text: t('successMessage'),
        severity: 'success',
        dismissible: true,
        duration: 3000,
      });
    },

    onError: () => {
      showMessage({
        text: t('errorOccurredMessage'),
        severity: 'error',
        dismissible: true,
      });
    },
    ...props,
  });
};
