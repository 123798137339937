import { AvailableBrands } from '../../api/util/AvailableBrands.js';

export enum WsChannelType {
  Presence = 'presence',
  RemarketingAdmin = 'private-remarketing-admin',
  RemarketingUser = 'private-remarketing-user',
  SupportUser = 'private-support-user',
  ImageQC = 'presence-image-qc',
  VehicleListing = 'private-vehicle-listing',
}

export enum WsVehicleListingEvent {
  liveStatusChange = 'live-status-change',
}

export enum WsUserEvent {
  NewNotification = 'new-notification',
}

export const isWsUserEvent = (event: unknown): event is WsUserEvent => {
  return Object.values(WsUserEvent).includes(event as WsUserEvent);
};

export enum WsRemarketingEvent {
  newOffer = 'remarketing-new-offer',
  buyNow = 'remarketing-buy-now',
  offerRejected = 'remarketing-offer-rejected',
  offerAccepted = 'remarketing-offer-accepted',
  remarketingChange = 'remarketing-change',
}

export enum WsSupportUserEvent {
  bgImageReplacementChange = 'bg-image-replacement-change',
  presenceSubscriptionSucceeded = 'pusher:subscription_succeeded',
  presenceMemberAdded = 'pusher:member_added',
  presenceMemberRemoved = 'pusher:member_removed',
}

export const isRemarketingAdminEvent = (
  event: unknown,
): event is WsRemarketingEvent => {
  return Object.values(WsRemarketingEvent).includes(
    event as WsRemarketingEvent,
  );
};

export const isRemarketingUserEvent = (
  event: unknown,
): event is WsRemarketingEvent => {
  return Object.values(WsRemarketingEvent).includes(
    event as WsRemarketingEvent,
  );
};

export const isSupportUserEvent = (
  event: unknown,
): event is WsSupportUserEvent => {
  return Object.values(WsSupportUserEvent).includes(
    event as WsSupportUserEvent,
  );
};

export const isVehicleListingEvent = (
  event: unknown,
): event is WsVehicleListingEvent => {
  return Object.values(WsVehicleListingEvent).includes(
    event as WsVehicleListingEvent,
  );
};

export type WsChannelEvent =
  | WsUserEvent
  | WsRemarketingEvent
  | WsSupportUserEvent
  | WsVehicleListingEvent;

export const getWsChannelName = (
  channelType: WsChannelType,
  brand: AvailableBrands,
): string => `${channelType}-${brand}`;

export const isWsChannelNameMatch = (
  channelName: string,
  channelType: WsChannelType,
  brand: AvailableBrands,
): boolean => {
  return channelName === getWsChannelName(channelType, brand);
};
