import { Box, Paper, Stack } from '@mui/material';
import { t } from 'i18next';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DealerUser } from '../../../api/UserService/listUsersByDealer/ListDealerUsersResponse.js';
import SearchToolBar from '../../common-ui/components/SearchToolBar.js';
import { ErrorState, PageContainer, UserTable } from '../../common-ui/index.js';
import {
  useApiClient,
  useAsyncState,
  useBrand,
  useDealer,
  useDebounce,
} from '../../hooks/index.js';
import { sx } from '../../util/sx.js';
import DealerSelector from '../components/DealerSelector.js';
import DealerUserForm from '../components/DealerUserForm/DealerUserForm.js';
import { UserDealerFromSkeleton } from '../components/DealerUserForm/DealerUserFormSkeleton.js';
import { renderColumns } from '../components/DealerUserTable/renderColumns.js';
import MainDrawer from '../components/MainDrawer.js';

const styles = sx({
  drawer: {
    width: {
      sm: '60%',
      md: '40%',
    },
  },
});

const UserDealer: FunctionComponent = () => {
  const { currentDealer, isDisabled } = useDealer();
  const { currentBrand } = useBrand();
  const api = useApiClient();
  const [users, setUsers] = useAsyncState<DealerUser[]>();
  const [user, setUser] = useAsyncState<DealerUser>();
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(searchValue);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>();

  const filtered = useMemo(() => {
    if (!users.value) {
      return;
    }
    return users.value
      .filter((u) =>
        debouncedValue
          ? (u.fullName &&
              u.fullName
                .toLocaleLowerCase()
                .includes(debouncedValue.toLocaleLowerCase())) ||
            u.email
              .toLocaleLowerCase()
              .includes(debouncedValue.toLocaleLowerCase())
          : u,
      )
      .sort((a, b) =>
        a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
      );
  }, [users.value, debouncedValue]);

  const loadUsers = useCallback(
    async (dealerId: number) => {
      return await api.user.listUsersByDealer({
        dealerId,
        brand: currentBrand,
      });
    },
    [api.user, currentBrand],
  );

  const refreshUsers = () => {
    currentDealer && setUsers(loadUsers(currentDealer.id));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleToggleDrawer = () => {
    setOpenDrawer((state) => !state);
  };

  const loadUser = useCallback(
    async (principalId: string, dealerId: number) => {
      return await api.user.getDealerUserById({ principalId, dealerId });
    },
    [api.user],
  );

  const handleSave = () => {
    setSelectedUser(undefined);
    handleToggleDrawer();
    refreshUsers();
  };

  const handleOpenAddDrawer = () => {
    setSelectedUser(undefined);
    handleToggleDrawer();
  };

  const handleEditUser = (principalId: string) => {
    setSelectedUser(principalId);
    handleToggleDrawer();
  };

  useEffect(() => {
    if (!currentDealer) {
      return;
    }

    setUsers(loadUsers(currentDealer.id));
  }, [api.user, currentDealer, loadUsers, setUsers]);

  useEffect(() => {
    if (!currentDealer || !selectedUser) {
      return;
    }
    setUser(loadUser(selectedUser, currentDealer.id));
  }, [currentDealer, loadUser, selectedUser, setUser]);

  const columns = renderColumns({
    onDelete: refreshUsers,
    onEdit: handleEditUser,
  });

  return (
    <PageContainer>
      <Paper
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        <Stack spacing={2}>
          <Box p={2} pb={0}>
            <DealerSelector />
          </Box>
          {isDisabled ? (
            <Stack p={2}>
              <ErrorState
                alertTitle={t('dealerDisabledTitle')}
                error={t('dealerDisabledMessage')}
              />
            </Stack>
          ) : (
            <>
              <SearchToolBar
                inputValue={searchValue}
                onButtonClick={handleOpenAddDrawer}
                onInputChange={handleInputChange}
              />
              <Box>
                <UserTable
                  columns={columns}
                  loading={users.loading}
                  rows={filtered || []}
                />
              </Box>
            </>
          )}
        </Stack>
      </Paper>
      <MainDrawer
        PaperProps={{ sx: styles.drawer }}
        onClose={handleToggleDrawer}
        open={openDrawer}
        title={t('pages.users.user')}
      >
        {user.loading || !currentDealer || !currentBrand ? (
          <UserDealerFromSkeleton />
        ) : (
          <DealerUserForm
            brand={currentBrand}
            dealerId={currentDealer.id}
            onSave={handleSave}
            user={selectedUser ? user.value : undefined}
          />
        )}
      </MainDrawer>
    </PageContainer>
  );
};

export default UserDealer;
