import { maybe } from '@fmtk/decoders';
import { method, service } from '../util/ServiceDef.js';
import { UserPermissions } from '../util/UserPermissions.js';
import { none } from '../util/none.js';
import { decodeGetBrandConfigRequest } from './getBrandConfig/GetBrandConfigRequest.js';
import { decodeGetBrandConfigResponse } from './getBrandConfig/GetBrandConfigResponse.js';
import { decodeListCountryResponse } from './listCountries/ListCountryResponse.js';
import { decodeListDealerGroupResponse } from './listDealerGroup/listDealerGroupResponse.js';
import { decodeListRegionCountryRequest } from './listRegionCountry/ListRegionCountryRequest.js';
import { decodeListRegionCountryResponse } from './listRegionCountry/ListRegionCountryResponse.js';
import { decodeListRegionsRequest } from './listRegions/ListRegionsRequest.js';
import { decodeListRegionsResponse } from './listRegions/ListRegionsResponse.js';
import { decodeUpdateBrandConfigRequest } from './updateBrandConfig/UpdateBrandConfigRequest.js';
import { decodeUpdateBrandConfigResponse } from './updateBrandConfig/UpdateBrandConfigResponse.js';

export const BrandServiceDef = service({
  getBrandConfig: method(
    decodeGetBrandConfigRequest,
    maybe(decodeGetBrandConfigResponse),
    [UserPermissions.Basic],
  ),
  listCountries: method(none, decodeListCountryResponse, [
    UserPermissions.Support,
  ]),
  listRegionCountry: method(
    decodeListRegionCountryRequest,
    decodeListRegionCountryResponse,
    [UserPermissions.Basic],
  ),
  listRegions: method(decodeListRegionsRequest, decodeListRegionsResponse, [
    UserPermissions.Basic,
  ]),
  listDealerGroup: method(none, decodeListDealerGroupResponse, [
    UserPermissions.Support,
  ]),
  updateBrandConfig: method(
    decodeUpdateBrandConfigRequest,
    decodeUpdateBrandConfigResponse,
    [UserPermissions.Support],
  ),
});

export type BrandServiceDef = typeof BrandServiceDef;
