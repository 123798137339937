import { AutoAwesome, Delete, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import md5 from 'md5';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleImage } from '../../../../api/MediaService/reorderMedia/ReorderMediaRequest.js';
import { UpdateUndefinedFields } from '../../../../api/VehicleListingService/updateVehicleListing/UpdateVehicleListingRequest.js';
import {
  VehicleListing,
  VehicleListingForm,
} from '../../../../api/util/Vehicle.js';
import {
  VehicleMedia,
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import { ShotstackVideoResolution } from '../../../../core/ShotstackService/ShotstackRequest.js';
import { MediaScope } from '../../../../util/MediaScope.js';
import { hasEnoughImages } from '../../../../util/vehicleMediaHelper.js';
import AccordionExpandIcon from '../../../common-ui/components/AccordionExpandIcon.js';
import LightTooltip from '../../../common-ui/components/LightTooltip.js';
import SectionItem from '../../../common-ui/components/SectionItem.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import {
  ErrorState,
  TextInput,
  useMessageStore,
} from '../../../common-ui/index.js';
import { isValidUrl } from '../../../common-ui/validation/validUrl.js';
import { useDeleteMedia } from '../../../hooks/mutations/useDeleteMedia.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import { sx } from '../../../util/sx.js';
import { useUpdateVehicleListing } from '../../hooks/mutations/useUpdateVehicleListing.js';
import { useVideoGeneration } from '../../hooks/mutations/useVideoGeneration.js';
import { makeVehicleListingQueryKey } from '../../hooks/queries/useVehicleListing.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import AccordionHeader from '../VehicleMedia/AccordionHeader.js';
import ImageAccordion from '../VehicleMedia/ImageAccordion.js';
import VinImageAccordion from '../VehicleMedia/VinImageAccordion.js';

export const MAX_FILE_SIZE_MB = 40;

const styles = sx({
  accordionSummary: {
    padding: 0,
  },
  accordionContent: {
    px: 0,
  },
  accordionContainer: {
    border: 1,
    borderWidth: '1px 0',
    borderColor: '#E7EAEE',
    my: 3,
  },
  vehicleImageContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 1,
    justifyContent: 'flex-start',
  },
});

export interface OnChangeFileProps {
  file: File | null;
  position?: number;
}

interface VehicleMediaProps {
  vehicleListing: VehicleListingForm;
  readonly?: boolean;
  onSave?: () => void;
  onChangeYouTubeUrl?: (youTubeUrl?: string, hasError?: boolean) => void;
  onChangeImages?: (simpleImages?: SimpleImage[]) => void;
  onChangeVinImage?: (vehicleMedia?: VehicleMedia) => void;
  actions?: ReactNode;
  options?: { expandedGroup?: VehicleMediaType[] };
  hideSaveButton?: boolean;
}

const VehicleMediaSection: FunctionComponent<VehicleMediaProps> = ({
  vehicleListing,
  onSave,
  onChangeYouTubeUrl,
  onChangeImages,
  onChangeVinImage,
  options,
  actions,
  readonly,
  hideSaveButton,
}) => {
  const { t } = useTranslation();
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const [mediaToDisplay, setMediaToDisplay] = useState<SimpleImage[]>([]);
  const [expanded, setExpanded] = useState<VehicleMediaType[]>([]);
  const [youTubeVideoUrl, setYouTubeVideoUrl] = useState<string>();
  const [youTubeUrlError, setYouTubeUrlError] = useState<boolean>(false);
  const [mediaUpdateResult, setMediaUpdateResult] = useAsyncState();
  const user = useCurrentUser();
  const { brandConfig } = useBrand();
  const [media, setMedia] = useState<VehicleMedia[]>();
  const queryClient = useQueryClient();
  const { mutate: updateVehicleListing, isPending } = useUpdateVehicleListing({
    onSettled: (data, error) => {
      if (!data || !!error) {
        return;
      }
      setMedia(data.vehicleMedia);

      queryClient.setQueryData<VehicleListing>(
        makeVehicleListingQueryKey({ id: data.id, brand: data.vehicle.brand }),
        (prev) => {
          if (!prev || prev.id !== data.id) {
            return;
          }
          return {
            ...prev,
            vehicleMedia: data.vehicleMedia,
            youTubeLink: data.youTubeLink,
          };
        },
      );

      onSave && onSave();
    },
  });
  const { mutateAsync: deleteMedia, isPending: deleteMediaLoading } =
    useDeleteMedia();

  const {
    mutateAsync: sendToVideoGeneration,
    isPending: videoGenerationLoading,
  } = useVideoGeneration();

  const contentItems: ContentItem[] = [
    {
      text: t('photos'),
      hash: 'photos',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.media.video'),
      hash: 'video',
      sectionElementRef: useRef(null),
    },
  ];

  const handleVinImageSave = useCallback(
    (vehicleMedia: VehicleMedia[]) => {
      setMediaUpdateResult(async () => {
        if (!vehicleMedia || !vehicleListing.id) {
          return;
        }
        try {
          showMessage({
            severity: 'success',
            text: t('successMessage'),
            dismissible: true,
          });
          onSave && onSave();
          setMedia(vehicleMedia);
        } catch (error) {
          console.error(error);
          showMessage({
            severity: 'error',
            text: t('errorOccurredMessage'),
            dismissible: true,
          });
        }
      });
    },
    [onSave, setMediaUpdateResult, showMessage, t, vehicleListing.id],
  );

  const handleMediaSave = useCallback(() => {
    setMediaUpdateResult(async () => {
      if (!mediaToDisplay || !vehicleListing.id) {
        return;
      }

      try {
        const newVehicleListing = await api.media.reorderMedia({
          vehicleId: vehicleListing.id,
          reorderedImages: mediaToDisplay,
        });
        showMessage({
          severity: 'success',
          text: t('successMessage'),
          dismissible: true,
        });
        onSave && onSave();
        setMedia(newVehicleListing.vehicleMedia);
      } catch (error) {
        console.error(error);
        showMessage({
          severity: 'error',
          text: t('errorOccurredMessage'),
          dismissible: true,
        });
      }
    });
  }, [
    api.media,
    mediaToDisplay,
    onSave,
    setMediaUpdateResult,
    showMessage,
    t,
    vehicleListing.id,
  ]);

  const handleYoutubeLinkChange = useCallback(
    (value: string) => {
      setYouTubeVideoUrl(value);
      // we check if youTubeVideo url is a valid youtube url
      const youTubeUrlRegex =
        /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
      const youTubeUrlError =
        value !== '' && (!youTubeUrlRegex.test(value) || !isValidUrl(value).ok);

      setYouTubeUrlError(youTubeUrlError);
      onChangeYouTubeUrl && onChangeYouTubeUrl(value, youTubeUrlError);
    },
    [onChangeYouTubeUrl],
  );

  const handleSave = useCallback(() => {
    if (youTubeUrlError) {
      showMessage({
        severity: 'error',
        text: t('pages.vehiclePage.media.invalidYouTubeUrl'),
        dismissible: true,
      });
      return;
    }

    if (vehicleListing.id) {
      updateVehicleListing({
        youTubeLink: youTubeVideoUrl,
        id: vehicleListing.id,
        brand: vehicleListing.vehicle.brand,
        options: {
          updateUndefinedFields: [UpdateUndefinedFields.youTubeLink],
        },
      });
    }
  }, [
    showMessage,
    t,
    updateVehicleListing,
    vehicleListing.id,
    vehicleListing.vehicle.brand,
    youTubeUrlError,
    youTubeVideoUrl,
  ]);

  const handleRemoveAiGeneratedVideo = useCallback(
    (vehicleMedia: VehicleMedia) => {
      if (vehicleListing.id) {
        deleteMedia({
          type: VehicleMediaType.Video,
          vinMd5Hash:
            vehicleListing.vinMd5Hash || md5(vehicleListing.vehicle.vin),
          position: 1,
        })
          .then(() => {
            setMedia(media?.filter((x) => x !== vehicleMedia));
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    [
      deleteMedia,
      media,
      vehicleListing.id,
      vehicleListing.vehicle.vin,
      vehicleListing.vinMd5Hash,
    ],
  );

  const handleSendToVideoGeneration = useCallback(() => {
    if (vehicleListing.id) {
      sendToVideoGeneration({
        reference: vehicleListing.id,
        mediaScope: MediaScope.VehicleListing,
        brand: vehicleListing.vehicle.brand,
        resolution: ShotstackVideoResolution.FHD,
      })
        .then(() => {
          onSave && onSave();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [
    onSave,
    sendToVideoGeneration,
    vehicleListing.id,
    vehicleListing.vehicle.brand,
  ]);

  const videoMedia = useMemo(() => {
    return media?.find(
      (x) =>
        x.type === VehicleMediaType.Video &&
        x.position === 1 &&
        x.status !== VehicleMediaStatus.Archived,
    );
  }, [media]);

  const hasValidMedia = useMemo(() => {
    return hasEnoughImages(media || [], brandConfig);
  }, [brandConfig, media]);

  const disableGenerateVideoMessage = useMemo(() => {
    if (!hasValidMedia) {
      return t('pages.vehiclePage.media.needValidMedia');
    }

    if (!brandConfig?.videoGenerationEnabled) {
      return t('notImplementedMessage');
    }

    if (videoGenerationLoading) {
      return t('pages.vehiclePage.media.videoProcessing');
    }

    return;
  }, [
    brandConfig?.videoGenerationEnabled,
    hasValidMedia,
    t,
    videoGenerationLoading,
  ]);

  useEffect(() => {
    setYouTubeVideoUrl(vehicleListing.youTubeLink);
    setExpanded(options?.expandedGroup || []);
    setMedia(vehicleListing.vehicleMedia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleListing]);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section title={t('media')}>
          <Stack sx={styles.accordionContainer}>
            <div
              id={contentItems[0].hash}
              ref={
                contentItems[0]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <ImageAccordion
                defaultExpanded={expanded.includes(VehicleMediaType.Image)}
                hideSaveButton={hideSaveButton}
                loading={mediaUpdateResult.loading}
                onChange={(simpleImages) => {
                  setMediaToDisplay(simpleImages || []);
                  onChangeImages && onChangeImages(simpleImages || []);
                }}
                onDeleteMedia={(newMedia?: VehicleMedia[]) =>
                  setMedia(newMedia)
                }
                onMediaSave={handleMediaSave}
                readonly={readonly}
                required
                vehicle={vehicleListing.vehicle}
                vehicleMedia={media}
                vinMd5Hash={vehicleListing.vinMd5Hash}
              />
              <VinImageAccordion
                defaultExpanded={expanded.includes(VehicleMediaType.Vin)}
                hideSaveButton={hideSaveButton}
                onChange={(vinMedia) => {
                  onChangeVinImage && onChangeVinImage(vinMedia);
                }}
                onMediaSave={handleVinImageSave}
                readonly={readonly}
                vehicle={vehicleListing.vehicle}
                vehicleMedia={media}
                vinMd5Hash={vehicleListing.vinMd5Hash}
              />
              <Accordion
                defaultExpanded={expanded.includes(VehicleMediaType.Video)}
              >
                <AccordionSummary
                  expandIcon={<AccordionExpandIcon />}
                  sx={styles.accordionSummary}
                >
                  <AccordionHeader
                    hasError={youTubeUrlError}
                    isComplete={!!youTubeVideoUrl || Boolean(videoMedia)}
                    title={t('pages.vehiclePage.media.video')}
                  />
                </AccordionSummary>
                <AccordionDetails sx={styles.accordionContent}>
                  <Stack spacing={3}>
                    <SectionItem
                      title={t('pages.vehiclePage.media.youTubeVideoLinkTitle')}
                      titleVariant="subtitle1"
                    >
                      <Stack direction="row">
                        <TextInput
                          disabled={readonly}
                          error={youTubeUrlError}
                          fullWidth
                          id="youtube-video"
                          onValueChange={handleYoutubeLinkChange}
                          value={youTubeVideoUrl}
                        />
                        {!readonly && !hideSaveButton && (
                          <LoadingButton
                            endIcon={<Save />}
                            loading={isPending}
                            loadingPosition="end"
                            onClick={handleSave}
                            sx={{
                              marginLeft: 2,
                            }}
                            variant="contained"
                          >
                            {t('save')}
                          </LoadingButton>
                        )}
                      </Stack>
                      {youTubeUrlError && (
                        <ErrorState
                          error={t('pages.vehiclePage.media.invalidYouTubeUrl')}
                          hideTitle
                        />
                      )}
                    </SectionItem>
                    {(videoMedia ||
                      (user.isSupport &&
                        brandConfig &&
                        brandConfig.videoGenerationEnabled)) && (
                      <SectionItem
                        title={t('pages.vehiclePage.media.AutoGeneratedVideo')}
                        titleVariant="subtitle1"
                      >
                        <Stack flex={1}>
                          <Box>
                            {videoMedia &&
                              videoMedia.status ===
                                VehicleMediaStatus.Approved && (
                                <Box marginY={2}>
                                  <video
                                    controls
                                    src={videoMedia.legacyUrl}
                                    style={{ width: '100%' }}
                                  />
                                </Box>
                              )}
                            {videoMedia?.status ===
                              VehicleMediaStatus.Error && (
                              <Box marginY={2}>
                                <ErrorState error={t('videoGenerationError')} />
                              </Box>
                            )}
                            {videoMedia?.status ===
                              VehicleMediaStatus.Processing && (
                              <Box
                                marginY={2}
                                sx={{
                                  position: 'relative',
                                  display: 'inline-block',
                                  width: '100%',
                                }}
                              >
                                {/* Image */}
                                <img
                                  alt="video processing"
                                  src={videoMedia.legacyUrl || ''}
                                  style={{
                                    display: 'block',
                                    borderRadius: '4px',
                                    justifySelf: 'center',
                                    maxWidth: '500px',
                                    width: '100%',
                                  }}
                                />
                                <Typography
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    color: 'white',
                                    padding: '8px 16px',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                  }}
                                  variant="h6"
                                >
                                  {t('pages.vehiclePage.media.videoProcessing')}
                                </Typography>
                              </Box>
                            )}
                            {user.isSupport && (
                              <Stack
                                direction="row"
                                justifyContent="end"
                                spacing={2}
                                width="100%"
                              >
                                {videoMedia && (
                                  <LoadingButton
                                    disabled={deleteMediaLoading}
                                    endIcon={<Delete />}
                                    loading={deleteMediaLoading}
                                    loadingPosition="end"
                                    onClick={() =>
                                      handleRemoveAiGeneratedVideo(videoMedia)
                                    }
                                    variant="contained"
                                  >
                                    {t('pages.vehiclePage.media.deleteVideo')}
                                  </LoadingButton>
                                )}
                                <LightTooltip
                                  arrow
                                  sx={{
                                    display: !disableGenerateVideoMessage
                                      ? 'none'
                                      : 'inherit',
                                  }}
                                  title={
                                    <Typography color="red" variant="body2">
                                      {disableGenerateVideoMessage}
                                    </Typography>
                                  }
                                >
                                  <span>
                                    <LoadingButton
                                      disabled={Boolean(
                                        disableGenerateVideoMessage,
                                      )}
                                      endIcon={<AutoAwesome />}
                                      loading={videoGenerationLoading}
                                      loadingPosition="end"
                                      onClick={handleSendToVideoGeneration}
                                      variant="contained"
                                    >
                                      {t(
                                        'pages.vehiclePage.media.generateVideo',
                                      )}
                                    </LoadingButton>
                                  </span>
                                </LightTooltip>
                              </Stack>
                            )}
                          </Box>
                        </Stack>
                      </SectionItem>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </div>
          </Stack>
          {actions && actions}
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
    </Stack>
  );
};

export default VehicleMediaSection;
