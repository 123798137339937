import { enumValue, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetEventCountRequest {
  vehicleId: string;
  brand: AvailableBrands;
}

export const decodeGetEventCountRequest = object<GetEventCountRequest>({
  vehicleId: string,
  brand: enumValue(AvailableBrands),
});
