import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { ListingLiveStatus } from '../../../../api/util/ListingLiveStatus.js';
import { VehicleListing } from '../../../../api/util/Vehicle.js';
import { VehicleListingLiveStatusEvent } from '../../../../api/util/VehicleListingLiveStatusEvent.js';
import { GetVehicleListingByIdRequest } from '../../../../api/VehicleListingService/getVehicleListingById/GetVehicleListingByIdRequest.js';
import {
  WsChannelType,
  WsVehicleListingEvent,
} from '../../../../core/PusherService/WsChannel.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { QueryKeys } from './QueryKeys.js';

export const makeVehicleListingQueryKey = (
  req?: GetVehicleListingByIdRequest,
): QueryKey => (!req ? [] : [QueryKeys.VehicleListing, req.id]);

export function useVehicleListing(
  req?: GetVehicleListingByIdRequest,
): UseQueryResult<VehicleListing<Date>> {
  const api = useApiClient();
  const queryClient = useQueryClient();

  const newEventIncoming = useWsChannelEvent<VehicleListingLiveStatusEvent>(
    WsVehicleListingEvent.liveStatusChange,
    WsChannelType.VehicleListing,
  );

  useEffect(() => {
    if (!newEventIncoming || !req) {
      return;
    }

    queryClient.setQueryData<VehicleListing>(
      makeVehicleListingQueryKey(req),
      (prev) => {
        if (!prev) {
          return;
        }
        if (prev.id === newEventIncoming.id) {
          return {
            ...prev,
            liveStatus: newEventIncoming.liveStatus.status,
            isLive: newEventIncoming.liveStatus.isLive,
          };
        }
      },
    );
  }, [newEventIncoming]);

  return useQuery({
    queryKey: makeVehicleListingQueryKey(req),
    queryFn: async () => {
      if (!req) {
        return;
      }

      return await api.vehicleListing.getVehicleListingById(req);
    },
    enabled: !!req,
    refetchOnWindowFocus: false,
    // if we have any image processing or rules processing we keep on fetching every 10 seconds
    refetchInterval: (data) =>
      data.state.data?.vehicleMedia?.some(
        (media) => media.status === 'PROCESSING',
      ) ||
      data.state.data?.liveStatus.some(
        (status) => status === ListingLiveStatus.PROCESSING,
      )
        ? 10 * 1000
        : false,
  });
}
