import {
  AvailableBrands,
  getBrandDisplayName,
} from '../../api/util/AvailableBrands.js';
import { Vehicle } from '../../api/util/Vehicle.js';
import { dashIfUndefined } from './dashIfUndefined.js';
import { isBrowser } from './isBrowser.js';
import { formatMoney } from './money.js';
import { formatNumber } from './numbers.js';

export function formatTorque(
  torqueNm: string | undefined,
  torqueLbft: string | undefined,
): string {
  return `${dashIfUndefined(torqueNm)} Nm (${dashIfUndefined(
    torqueLbft,
  )} lb ft)`;
}

export function formatAcceleration(
  acceleration060Mph: string | undefined,
  acceleration0100Kph: string | undefined,
): string {
  return `${dashIfUndefined(acceleration060Mph)} (0-60) MPH / ${dashIfUndefined(
    acceleration0100Kph,
  )} (0-100 KM/H)`;
}

export function format0100Acceleration(
  acceleration0100Kph: string | undefined,
): string {
  return `0-62 mph (100 km/h) in ${dashIfUndefined(acceleration0100Kph)} secs`;
}

export function formatMaxSpeed(
  maxSpeedMph: string | undefined,
  maxSpeedKph: string | undefined,
  options?: {
    style: 'MPH/KMH' | 'MPH(KPH)';
  },
): string {
  return !options?.style || options.style === 'MPH/KMH'
    ? `${dashIfUndefined(maxSpeedMph)} MPH / ${dashIfUndefined(
        maxSpeedKph,
      )} KM/H`
    : `${dashIfUndefined(maxSpeedMph)}mph (${dashIfUndefined(
        maxSpeedKph,
      )}km/h)`;
}

export function formatPrice(
  price: number | undefined,
  currency?: string,
  options?: Intl.NumberFormatOptions,
  replaceIfUndefined?: string,
  locale?: string,
): string {
  let selectedLocale = locale;

  if (!selectedLocale) {
    selectedLocale = isBrowser ? navigator.language : 'en-GB';
  }

  return dashIfUndefined(
    price
      ? formatMoney(price, selectedLocale, {
          ...options,
          style: currency ? 'currency' : undefined,
          currency: currency,
          maximumFractionDigits: options?.maximumFractionDigits || 0,
        })
      : undefined,
    replaceIfUndefined,
  );
}

export function formatConsumptionText(
  vehicle: Vehicle,
  brand: AvailableBrands,
): string {
  return `Official government fuel consumption figures in litres/100km (mpg) for the ${getBrandDisplayName(
    brand,
  )} ${dashIfUndefined(vehicle.model.name)}: WLTP Low ${dashIfUndefined(
    vehicle.wltpTest?.consumptionLow,
  )}; WLTP Medium ${dashIfUndefined(
    vehicle.wltpTest?.consumptionMid,
  )}; WLTP High ${dashIfUndefined(
    vehicle.wltpTest?.consumptionHigh,
  )}; WLTP Extra High ${dashIfUndefined(
    vehicle.wltpTest?.consumptionExtraHigh,
  )}; WLTP Combined ${
    vehicle.wltpTest?.consumptionCombined
  }. WLTP CO2 Emissions ${dashIfUndefined(
    vehicle.wltpTest?.co2Emissions,
  )} g/km (NEDC Combined CO2 - for information only ${dashIfUndefined(
    vehicle.nedcTest?.nedcCombinedFuelEconomy,
  )} g/km).`;
}

export const formatEngineCapacity = (
  value?: number,
  locale?: string,
): string => {
  return value
    ? `${formatNumber(value, locale, {
        style: 'unit',
        unit: 'liter',
        unitDisplay: 'long',
      })}`
    : '';
};

export const formatOdometer = (
  value?: number,
  unit?: string,
  locale?: string,
): string => {
  return value ? `${formatNumber(value, locale)} ${unit}` : '';
};

/** Converts MPG (Miles per Gallon) to Litres Per 100 Kilometers.
 * @param mpgValue Miles por gallon value.
 * @returns Litres per 100 kilometers values.
 */
export const fromMpgToLitresPer100 = (mpgValue: number): number => {
  const LitresPerGallon = 4.54609188;
  const KmPerMiles = 1.609344;
  const ratio = (LitresPerGallon / KmPerMiles) * 100;
  return Math.round((ratio / mpgValue) * 10) / 10;
};

export const kilometersToMiles = (kilometers: number): number => {
  const miles = kilometers / 1.60934;
  return miles;
};

export const milesToKilometers = (miles: number): number => {
  const kilometers = miles * 1.60934;
  return kilometers;
};
