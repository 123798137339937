import {
  array,
  maybe,
  number,
  object,
  record,
  string,
  text,
} from '@fmtk/decoders';

export type BentleyVinLookupFeature = {
  code: BentleyVinLookupFeatureCode;
  description: string;
};

export type BentleyVinLookupFeatureCode = {
  model_year: number;
  pr_fam: string;
  pr_no: string;
};

export type BentleyVinLookupFeatures = Record<
  string,
  BentleyVinLookupFeature[] | undefined
>;

export type BentleyVinLookupResponse = {
  vin: string;
  modelYear?: number;
  model: string;
  modelCode?: string;
  bodyStyle?: string;
  primaryColour?: string;
  freeTextColour?: string;
  interiorColour?: string;
  fuelType?: string;
  transmission?: string;
  engineOutput?: string;
  engineCapacity?: number;
  features?: BentleyVinLookupFeatures;
  efficiencyClass?: string;
  co2Class?: string;
  co2ClassDischargedBattery?: string;
  co2ClassWeightedCombined?: string;
  nedcConsumptionUrban?: string;
  nedcConsumptionExtraUrban?: string;
  nedcConsumptionCombined?: string;
  nedcCo2Emission?: string;
  wltpConsumptionLow?: string;
  wltpConsumptionMid?: string;
  wltpConsumptionCombined?: string;
  wltpEmissionsCombined?: string;
};

export const decodeBentleyVinLookupFeatureCode =
  object<BentleyVinLookupFeatureCode>({
    model_year: number,
    pr_fam: text,
    pr_no: text,
  });

export const decodeBentleyVinLookupFeature = object<BentleyVinLookupFeature>({
  code: decodeBentleyVinLookupFeatureCode,
  description: text,
});

export const decodeBentleyVinLookupResponse = object<BentleyVinLookupResponse>({
  vin: text,
  modelYear: maybe(number),
  model: text,
  modelCode: maybe(text),
  bodyStyle: maybe(text),
  primaryColour: maybe(text),
  freeTextColour: maybe(text),
  interiorColour: maybe(text),
  fuelType: maybe(text),
  transmission: maybe(text),
  engineOutput: maybe(text),
  engineCapacity: maybe(number),
  features: maybe(record(string, maybe(array(decodeBentleyVinLookupFeature)))),
  efficiencyClass: maybe(text),
  co2Class: maybe(text),
  co2ClassDischargedBattery: maybe(text),
  co2ClassWeightedCombined: maybe(text),
  nedcConsumptionUrban: maybe(text),
  nedcConsumptionExtraUrban: maybe(text),
  nedcConsumptionCombined: maybe(text),
  nedcCo2Emission: maybe(text),
  wltpConsumptionLow: maybe(text),
  wltpConsumptionMid: maybe(text),
  wltpConsumptionCombined: maybe(text),
  wltpEmissionsCombined: maybe(text),
});
