export class ClientError extends Error {
  public readonly httpStatus: number;

  constructor(
    public readonly service: string,
    public readonly error: string,
    message: string,
    public readonly details?: unknown,
    httpStatus = 400,
  ) {
    super(message);
    this.name = `${service}:${error}`;
    this.httpStatus = httpStatus;
  }
}

export enum ClientErrorCode {
  EmailNotVerified = 'EmailNotVerified',
  Unauthorized = 'Unauthorized',
  Network = 'Network',
  BadRequest = 'BadRequest',
  NotImplemented = 'NotImplemented',
}
