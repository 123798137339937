import { array, jsonDate, string } from '@fmtk/decoders';
import { decodeVehicleMedia } from '../util/VehicleMedia.js';
import { UserPermissions, method, none, service } from '../util/index.js';
import { decodeAddVehicleMediaRequest } from './addVehicleMedia/AddVehicleMediaRequest.js';
import { decodeApproveMediaRequest } from './approveMedia/ApproveMediaRequest.js';
import { decodeCountImageQcResponse } from './countImageQcListings/CountImageQcListingsResponse.js';
import { decodeDeleteMediaRequest } from './deleteMedia/DeleteMediaRequest.js';
import { decodeDownloadZipUrl } from './downloadZipUrl/DownloadZipUrlRequest.js';
import { decodeGetMediaRequest } from './getMedia/GetMediaRequest.js';
import { decodeListBgReplacementConfigsResponse } from './listBgReplacementConfigs/ListBgReplacementConfigsResponse.js';
import { decodeListListingMediaRequest } from './listListingMedia/ListListingMediaRequest.js';
import { decodeRejectMediaRequest } from './rejectMedia/RejectMediaRequest.js';
import { decodeReorderMediaRequest } from './reorderMedia/ReorderMediaRequest.js';
import { decodeReorderMediaResponse } from './reorderMedia/ReorderMediaResponse.js';
import { decodeSendToBgReplacementRequest } from './sendToBgReplacement/SendToBgReplacementRequest.js';
import { decodeSendToVideoGenerationRequest } from './sendToVideoGeneration/SendToVideoGenerationRequest.js';
import { decodeUpdateTransformationsRequest } from './updateTransformations/UpdateTransformationsRequest.js';
import { decodeUpdateVehicleMediaRequest } from './updateVehicleMedia/UpdateVehicleMediaRequest.js';
import { decodeUpdateVehicleMediaResponse } from './updateVehicleMedia/UpdateVehicleMediaResponse.js';
import { decodeUploadMediaRequest } from './uploadMedia/UploadMediaRequest.js';

export const MediaServiceDef = service({
  uploadMedia: method(decodeUploadMediaRequest, none, [UserPermissions.Basic]),
  getMedia: method(decodeGetMediaRequest, array(decodeVehicleMedia(jsonDate)), [
    UserPermissions.Basic,
  ]),
  deleteMedia: method(decodeDeleteMediaRequest, none, [UserPermissions.Basic]),
  approveMedia: method(decodeApproveMediaRequest, none, [
    UserPermissions.Basic,
  ]),
  rejectMedia: method(decodeRejectMediaRequest, none, [UserPermissions.Basic]),
  addVehicleMedia: method(decodeAddVehicleMediaRequest, none, [
    UserPermissions.Basic,
  ]),
  updateVehicleMedia: method(
    decodeUpdateVehicleMediaRequest,
    decodeUpdateVehicleMediaResponse,
    [UserPermissions.Basic],
  ),
  reorderMedia: method(
    decodeReorderMediaRequest,
    decodeReorderMediaResponse(jsonDate),
    [UserPermissions.Basic],
  ),
  updateTransformations: method(decodeUpdateTransformationsRequest, none, [
    UserPermissions.Basic,
  ]),
  downloadZipUrl: method(decodeDownloadZipUrl, string, [UserPermissions.Basic]),
  sendToBgReplacement: method(decodeSendToBgReplacementRequest, none, [
    UserPermissions.Support,
  ]),
  sendToVideoGeneration: method(decodeSendToVideoGenerationRequest, none, [
    UserPermissions.Support,
  ]),
  listBgReplacementConfigs: method(
    none,
    decodeListBgReplacementConfigsResponse,
    [UserPermissions.Support],
  ),
  countImageQcListings: method(none, decodeCountImageQcResponse, [
    UserPermissions.Support,
  ]),
  listListingMedia: method(
    decodeListListingMediaRequest,
    array(decodeVehicleMedia(jsonDate)),
    [UserPermissions.Basic],
  ),
});

export type MediaServiceDef = typeof MediaServiceDef;
