/* eslint-disable react/jsx-sort-props */
import {
  DecoderError,
  boolean,
  enumValue,
  error,
  invalid,
  jsonDate,
  maybe,
  normaliseNumber,
  ok,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateUndefinedFields } from '../../../../api/VehicleListingService/updateVehicleListing/UpdateVehicleListingRequest.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { HandDrive } from '../../../../api/util/HandDrive.js';
import { OdometerUnit } from '../../../../api/util/OdometerUnit.js';
import { SyndicationType } from '../../../../api/util/SyndicationType.js';
import {
  ListingStatus,
  ListingVisibility,
  VehicleListing,
  decodeVehicleListing,
} from '../../../../api/util/Vehicle.js';
import { normaliseDate } from '../../../../util/decoders/normaliseDate.js';
import { normaliseMoney } from '../../../../util/decoders/normaliseMoney.js';
import FormErrorsSummary from '../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../common-ui/components/LightTooltip.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import {
  ConfirmModal,
  Form,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import { useSyndicationTypeOptions } from '../../../hooks/useSyndicationTypeOptions.js';
import { useUpdateVehicleListing } from '../../hooks/mutations/useUpdateVehicleListing.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import { validateSaveVehicleRule } from './SaveVehicleRules/validateSaveVehicleRule.js';
import Colour from './Sections/Colour.js';
import Engine from './Sections/Engine.js';
import KeyInformation from './Sections/KeyInformation.js';
import Listing from './Sections/Listing.js';
import Price from './Sections/Price.js';
import TechSpec from './Sections/TechSpec.js';
import { YesNo } from './Sections/types.js';
interface VehicleDetailsProps {
  archivedMode?: boolean;
  vehicleListing: VehicleListing;
  readonly?: boolean;
  onSave?: (vehicleListing: VehicleListing) => void;
}

export type EditVehicleFormState = {
  basePrice: number | undefined;
  bhp: string | undefined;
  bodyStyle: string | undefined;
  capacity: number;
  carpetColour: string | undefined;
  collection: string | undefined;
  currency: string;
  cylinders: string | undefined;
  damageStatus: string | undefined;
  doors: number | undefined;
  drive: HandDrive | undefined;
  engineType: string | undefined;
  entryDate: Date | undefined;
  exteriorColour: string | undefined;
  fuelType: string | undefined;
  german25aTaxSalesDisclaimer: YesNo | undefined;
  headliningColour: string | undefined;
  interiorColour: string | undefined;
  lfsModelCode: string | undefined;
  listingStatus: ListingStatus;
  model: string;
  groupName: string;
  modelYear: number | undefined;
  motLastDate: Date | undefined;
  motDueDate: Date | undefined;
  netPrice: number | undefined;
  odometer: number | undefined;
  odometerOnApplication: boolean | undefined;
  odometerUnit: OdometerUnit | undefined;
  powerKw: string | undefined;
  previousOwners: number | undefined;
  price: number | undefined;
  priceExcludesVat: YesNo | undefined;
  priceLessTaxes: number | undefined;
  priceOnApplication: boolean | undefined;
  productionDate: Date | undefined;
  registrationDate: Date | undefined;
  registrationMonth: string | undefined;
  registrationYear: number | undefined;
  regPlate: string | undefined;
  retailPricePlusTax: number | undefined;
  seatColour: string | undefined;
  secondaryExteriorColour: string | undefined;
  secondaryInteriorColour: string | undefined;
  standInValue: number | undefined;
  steeringWheelColour: string | undefined;
  syndicationType: SyndicationType;
  torqueLbft: string | undefined;
  torqueNm: string | undefined;
  trade: number | undefined;
  transmission: string;
  variant: string | undefined;
  vatQualifying: YesNo | undefined;
  vehicleListing: VehicleListing | undefined;
  veneer: string | undefined;
  vin: string;
  visibility: ListingVisibility;
  warrantyExpiryDate: Date | undefined;
};

const VehicleDetails: FunctionComponent<VehicleDetailsProps> = ({
  archivedMode,
  vehicleListing,
  readonly,
  onSave,
}): JSX.Element => {
  const { t } = useTranslation();
  const { isSupport } = useCurrentUser();
  const { brandConfig } = useBrand();
  const navigate = useNavigate();
  const { showMessage } = useMessageStore();
  const [resetConfirm, setResetConfirm] = useState(false);
  const brandSyndicationTypes = useSyndicationTypeOptions();
  const { brand, dealer } = useParams();
  const [isTechSpecVisible, setIsTechSpecVisible] = useState(false);
  const { mutateAsync: updateVehicleListing } = useUpdateVehicleListing({
    onSettled: (data, error) => {
      if (!data || !!error) {
        return;
      }
      onSave && onSave(data);
    },
  });

  const contentItems: ContentItem[] = [
    {
      text: t('pages.vehiclePage.details.listing'),
      hash: 'listing',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.price'),
      hash: 'price',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.colour'),
      hash: 'colour',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.keyInformation'),
      hash: 'keyInformation',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.engine'),
      hash: 'engine',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.techSpec'),
      hash: 'techSpec',
      sectionElementRef: useRef(null),
      isHidden: !isTechSpecVisible,
    },
  ];

  const { showCollection } = useMemo(() => {
    if (!brandConfig) {
      return {};
    }

    return {
      showCollection:
        vehicleListing.vehicle.brand === AvailableBrands.RollsRoyce,
    };
  }, [brandConfig, vehicleListing.vehicle.brand]);

  const form = useCreateForm<EditVehicleFormState, void>(
    {
      basePrice: maybe(strToNum),
      bhp: maybe(text),
      bodyStyle: maybe(text),
      capacity: normaliseNumber,
      carpetColour: maybe(text),
      collection: maybe(text),
      currency: text,
      cylinders: maybe(text),
      doors: maybe(normaliseNumber),
      drive: maybe(enumValue(HandDrive)),
      engineType: maybe(text),
      entryDate: maybe(normaliseDate()),
      exteriorColour: maybe(text),
      fuelType: text,
      german25aTaxSalesDisclaimer: maybe(enumValue(YesNo)),
      headliningColour: maybe(text),
      interiorColour: maybe(text),
      lfsModelCode: maybe(text),
      listingStatus: enumValue(ListingStatus),
      model: text,
      groupName: text,
      modelYear: maybe(normaliseNumber),
      motLastDate: maybe(normaliseDate()),
      motDueDate: maybe(normaliseDate()),
      netPrice: maybe(strToNum),
      odometer: maybe(normaliseNumber),
      odometerOnApplication: maybe(boolean),
      odometerUnit: maybe(enumValue(OdometerUnit)),
      powerKw: maybe(text),
      previousOwners: maybe(normaliseNumber),
      price: maybe(normaliseMoney()),
      priceExcludesVat: maybe(enumValue(YesNo)),
      priceLessTaxes: maybe(strToNum),
      priceOnApplication: maybe(boolean),
      productionDate: maybe(normaliseDate()),
      registrationDate: maybe(normaliseDate()),
      registrationMonth: maybe(text),
      registrationYear: maybe(normaliseNumber),
      regPlate: maybe(
        text.options({
          maxLength: 10,
        }),
      ),
      retailPricePlusTax: maybe(strToNum),
      seatColour: maybe(text),
      secondaryExteriorColour: maybe(text),
      secondaryInteriorColour: maybe(text),
      standInValue: maybe(strToNum),
      steeringWheelColour: maybe(text),
      syndicationType: enumValue(SyndicationType),
      torqueLbft: maybe(text),
      torqueNm: maybe(text),
      trade: maybe(strToNum),
      transmission: text,
      variant: maybe(text),
      vatQualifying: maybe(enumValue(YesNo)),
      veneer: maybe(text),
      vin: text,
      visibility: enumValue(ListingVisibility),
      warrantyExpiryDate: maybe(normaliseDate()),
      damageStatus: maybe(text),

      vehicleListing: maybe(decodeVehicleListing(jsonDate)),
    },
    async (values) => {
      if (!vehicleListing.id) {
        return;
      }

      await updateVehicleListing({
        id: vehicleListing.id,
        brand: vehicleListing.vehicle.brand,
        appearanceOptions: {
          carpetColour: values.carpetColour,
          exteriorColour: values.exteriorColour,
          headliningColour: values.headliningColour,
          interiorColour: values.interiorColour,
          seatColour: values.seatColour,
          secondaryExteriorColour: values.secondaryExteriorColour,
          secondaryInteriorColour: values.secondaryInteriorColour,
          steeringWheelColour: values.steeringWheelColour,
          veneer: values.veneer,
        },
        capacity: values.capacity,
        currency: {
          code: values.currency,
        },
        engine: {
          bhp: values.bhp,
          capacity: values.capacity,
        },
        listingStatus: values.listingStatus as ListingStatus,
        odometer: {
          units: values.odometerUnit as OdometerUnit,
          value: values.odometer,
          odometerOnApplication: !!values.odometerOnApplication,
        },
        price: {
          basePrice: values.basePrice,
          lessTaxes: values.priceLessTaxes,
          retail: values.price,
          retailPlusTax: values.retailPricePlusTax,
          standInValue: values.standInValue,
          trade: values.trade,
          netPrice: values.netPrice,
          priceOnApplication: values.priceOnApplication,
          german25aTaxSalesDisclaimer:
            values.german25aTaxSalesDisclaimer === YesNo.Yes,
          vatQualifying: values.vatQualifying === YesNo.Yes,
          priceExcludesVat: values.priceExcludesVat === YesNo.Yes,
        },
        warrantyExpiryDate: values.warrantyExpiryDate,
        motLastDate: values.motLastDate,
        motDueDate: values.motDueDate,
        registrationPlate: values.regPlate,
        syndicationType: values.syndicationType as SyndicationType,
        techSpecs: {
          doors: values.doors,
        },
        visibility: values.visibility as ListingVisibility,
        registrationDate: values.registrationDate,
        registrationMonth: values.registrationMonth,
        previousOwners: values.previousOwners,
        damageStatus: values.damageStatus,
        options: {
          updateUndefinedFields: [
            UpdateUndefinedFields.basePrice,
            UpdateUndefinedFields.exteriorColour,
            UpdateUndefinedFields.exteriorMetaColour,
            UpdateUndefinedFields.interiorColour,
            UpdateUndefinedFields.netPrice,
            UpdateUndefinedFields.priceLessTaxes,
            UpdateUndefinedFields.regPlate,
            UpdateUndefinedFields.retailPricePlusTaxes,
            UpdateUndefinedFields.tradePrice,
            UpdateUndefinedFields.carpetColour,
            UpdateUndefinedFields.headliningColour,
            UpdateUndefinedFields.interiorMetaColour,
            UpdateUndefinedFields.seatColour,
            UpdateUndefinedFields.secondaryExteriorColour,
            UpdateUndefinedFields.steeringWheelColour,
            UpdateUndefinedFields.veneer,
            UpdateUndefinedFields.secondaryInteriorColour,
            UpdateUndefinedFields.bhp,
            UpdateUndefinedFields.doors,
            UpdateUndefinedFields.warrantyExpiryDate,
            UpdateUndefinedFields.motLastDate,
            UpdateUndefinedFields.motDueDate,
            UpdateUndefinedFields.previousOwners,
          ],
        },
      });
    },
    (values) => {
      const {
        listingStatus: status,
        price,
        priceOnApplication,
        syndicationType,
        odometerOnApplication,
        odometer,
        odometerUnit,
      } = values;
      if (status === ListingStatus.ForSale && !priceOnApplication && !price) {
        return invalid('INVALID_PRICE', 'The price is mandatory', 'price');
      }

      if (
        [
          SyndicationType.Certified,
          SyndicationType.Demo,
          SyndicationType.Used,
        ].includes(syndicationType) &&
        !values.registrationDate
      ) {
        return invalid(
          'REG_DATE_MISSING',
          'Registration date is mandatory',
          'registrationDate',
        );
      }

      let odometerError: DecoderError | undefined = undefined;
      let odometerUnitError: DecoderError | undefined = undefined;

      if (!odometerOnApplication) {
        if (!odometer) {
          odometerError = {
            id: 'INVALID_ODOMETER',
            text: 'The odometer is mandatory',
            field: 'odometer',
          };
        }

        if (!odometerUnit) {
          odometerUnitError = {
            id: 'INVALID_ODOMETER_UNIT',
            text: 'The odometer unit is mandatory',
            field: 'odometerUnit',
          };
        }
      }
      const errors = [odometerError, odometerUnitError].filter(Boolean);

      if (!!brandConfig?.saveVehicleRule) {
        //validate brand rules
        const saveVehicleRuleError = validateSaveVehicleRule(
          values,
          brandConfig.saveVehicleRule,
        );
        if (saveVehicleRuleError) {
          errors.push(saveVehicleRuleError);
        }
      }

      if (errors.length > 0) {
        return error(errors as DecoderError[]);
      }

      return ok(values);
    },

    [vehicleListing, brandConfig],
  );

  const [formState, formBind] = form;

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [
      isDirty && Object.values(formState.errors).some((x) => !!x.length),
      isDirty,
    ];
  }, [formState]);

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const resetForm = useCallback(() => {
    formBind.reset({
      //Key information
      vin: vehicleListing.vehicle.vin,
      model: vehicleListing.vehicle.model.name,
      groupName: vehicleListing.vehicle.model.groupName,
      variant: vehicleListing.vehicle.variant || '',
      bodyStyle: vehicleListing.vehicle.bodyStyle?.name || '',
      drive: vehicleListing.vehicle.handDrive || '',
      registrationDate: vehicleListing.vehicle.registrationDate
        ? DateTime.fromJSDate(vehicleListing.vehicle.registrationDate, {
            zone: 'utc',
          }).toISODate()
        : '',
      productionDate: vehicleListing.vehicle.productionDate
        ? DateTime.fromJSDate(vehicleListing.vehicle.productionDate, {
            zone: 'utc',
          }).toISODate()
        : '',
      modelYear: vehicleListing.vehicle.modelYear || '',
      registrationMonth: vehicleListing.vehicle.registrationMonth || '',
      registrationYear: vehicleListing.vehicle.registrationYear,
      motLastDate: vehicleListing.motLastDate
        ? DateTime.fromJSDate(vehicleListing.motLastDate, {
            zone: 'utc',
          }).toISODate()
        : '',
      motDueDate: vehicleListing.motDueDate
        ? DateTime.fromJSDate(vehicleListing.motDueDate, {
            zone: 'utc',
          }).toISODate()
        : '',
      warrantyExpiryDate: vehicleListing.vehicle.warrantyExpiryDate
        ? DateTime.fromJSDate(vehicleListing.vehicle.warrantyExpiryDate, {
            zone: 'utc',
          }).toISODate()
        : '',
      previousOwners: vehicleListing.previousOwners || '',

      //Engine
      bhp: vehicleListing.vehicle?.engine?.bhp || '',
      capacity: vehicleListing.vehicle?.engine?.capacity ?? '',
      cylinders: vehicleListing.vehicle?.engine?.cylinders || '',
      engineType: vehicleListing.vehicle?.engine?.description || '',
      powerKw: vehicleListing.vehicle?.engine?.powerKw || '',
      torqueLbft: vehicleListing.vehicle?.engine?.torqueLbft || '',
      torqueNm: vehicleListing.vehicle?.engine?.torqueNm || '',
      transmission: vehicleListing.vehicle.transmission?.name || '',
      fuelType: vehicleListing.vehicle.engine?.fuelType || '',

      //Listing
      entryDate: vehicleListing.stockDate
        ? DateTime.fromJSDate(vehicleListing.stockDate, {
            zone: 'utc',
          }).toISODate()
        : '',
      odometer: vehicleListing.odometer?.value || '',
      odometerUnit: vehicleListing.odometer?.units || '',
      odometerOnApplication:
        vehicleListing.odometer?.odometerOnApplication || false,
      listingStatus: vehicleListing.listingStatus || '',
      visibility: vehicleListing.visibility || ListingVisibility.Visible,
      regPlate: vehicleListing.registrationPlate || '',
      syndicationType: vehicleListing.syndicationType || '',
      damageStatus: vehicleListing.damageStatus || '',

      //Price
      currency: vehicleListing.currency?.code || '',
      price: String(vehicleListing.price?.retail || ''),
      trade: String(vehicleListing.price?.trade || ''),
      standInValue: String(vehicleListing.price?.standInValue || ''),
      priceLessTaxes: String(vehicleListing.price?.lessTaxes || ''),
      basePrice: String(vehicleListing.price?.basePrice || ''),
      retailPricePlusTax: String(vehicleListing.price?.retailPlusTax || ''),
      netPrice: String(vehicleListing.price?.netPrice || ''),
      priceOnApplication: vehicleListing.price?.priceOnApplication || false,
      german25aTaxSalesDisclaimer: Boolean(
        vehicleListing.price?.german25aTaxSalesDisclaimer,
      )
        ? YesNo.Yes
        : YesNo.No,
      vatQualifying: Boolean(vehicleListing.price?.vatQualifying)
        ? YesNo.Yes
        : YesNo.No,
      priceExcludesVat: Boolean(vehicleListing.price?.priceExcludesVat)
        ? YesNo.Yes
        : YesNo.No,

      //Colour
      exteriorColour:
        vehicleListing.vehicle.appearanceOptions?.exteriorColour || '',
      interiorColour:
        vehicleListing.vehicle.appearanceOptions?.interiorColour || '',
      carpetColour:
        vehicleListing.vehicle.appearanceOptions?.carpetColour || '',
      headliningColour:
        vehicleListing.vehicle.appearanceOptions?.headliningColour || '',
      seatColour: vehicleListing.vehicle.appearanceOptions?.seatColour || '',
      secondaryExteriorColour:
        vehicleListing.vehicle.appearanceOptions?.secondaryExteriorColour || '',
      secondaryInteriorColour:
        vehicleListing.vehicle.appearanceOptions?.secondaryInteriorColour || '',
      steeringWheelColour:
        vehicleListing.vehicle.appearanceOptions?.steeringWheelColour || '',
      veneer: vehicleListing.vehicle.appearanceOptions?.veneer || '',

      // Brand-specific
      collection:
        vehicleListing.vehicle.brandSpecific?.[AvailableBrands.RollsRoyce]
          ?.collection || '',
      lfsModelCode:
        vehicleListing.vehicle.brandSpecific?.[AvailableBrands.Lamborghini]
          ?.lfsModelCode || '',

      //TechSpecs
      doors: vehicleListing.vehicle?.techSpecs?.doors || '',

      vehicleListing: vehicleListing,
    });
  }, [formBind, vehicleListing]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (formState.submitError) {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [formState.submitError, showMessage, t]);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section title={t('details')}>
          <Stack spacing={4}>
            <Form form={form} translations="pages.vehiclePage.details.form">
              {/* listing */}
              <div
                id={contentItems[0].hash}
                ref={
                  contentItems[0]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Listing
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.listing')}
                  price={vehicleListing.price}
                  syndicationTypeOptions={brandSyndicationTypes}
                  options={{
                    disableRegistrationDate: true,
                  }}
                />
              </div>
              {/* Price */}
              <div
                id={contentItems[1].hash}
                ref={
                  contentItems[1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Price
                  title={t('pages.vehiclePage.details.price')}
                  currency={vehicleListing.currency}
                  readonly={readonly}
                />
              </div>

              {/* Colour */}
              <div
                id={contentItems[2].hash}
                ref={
                  contentItems[2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Colour
                  title={t('pages.vehiclePage.details.colour')}
                  readonly={readonly}
                />
              </div>
              {/* Key Information */}
              <div
                id={contentItems[3].hash}
                ref={
                  contentItems[3]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <KeyInformation
                  title={t('pages.vehiclePage.details.keyInformation')}
                  showCollection={showCollection}
                  // Sometimes registration date is required, we should let them add one
                  // even if they are not explicitly allowed by the brand config
                  // This is specially important when coming from syndication type New which uses the registration date field
                  allowChanges={
                    !archivedMode &&
                    ((!vehicleListing.vehicle.registrationDate &&
                      formState.values.syndicationType !==
                        SyndicationType.New) ||
                      isSupport)
                  }
                  readonly={archivedMode}
                />
              </div>
              {/* Engine and comments */}
              <div
                id={contentItems[4].hash}
                ref={
                  contentItems[4]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Engine title={t('pages.vehiclePage.details.engine')} />
              </div>
              {/* TechSpec */}
              <div
                id={contentItems[5].hash}
                ref={
                  contentItems[5]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <TechSpec
                  readonly={readonly}
                  isVisible={setIsTechSpecVisible}
                  title={t('pages.vehiclePage.details.techSpec')}
                />
              </div>
              {!readonly && (
                <Box m={1} marginLeft="auto">
                  <Stack direction="row" justifyContent="end" spacing={1}>
                    <Button
                      tabIndex={1}
                      disabled={!isDirty || formState.busy}
                      onClick={() => {
                        if (!isDirty) {
                          return;
                        }
                        toggleResetConfirm();
                      }}
                      variant="outlined"
                    >
                      {t('cancel')}
                    </Button>
                    <LightTooltip
                      title={hasErrors ? <FormErrorsSummary /> : ''}
                    >
                      <span>
                        <LoadingButton
                          disabled={hasErrors}
                          endIcon={<Save />}
                          loading={formState.busy}
                          loadingPosition="end"
                          type="submit"
                          variant="contained"
                          tabIndex={1}
                        >
                          {t('save')}
                        </LoadingButton>
                      </span>
                    </LightTooltip>
                  </Stack>
                </Box>
              )}
            </Form>
          </Stack>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={() => {
          resetForm();
          toggleResetConfirm();
          navigate(`/${brand}/dealers/${dealer}/dashboard`);
        }}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
};

export default VehicleDetails;
